// Extra Map Functions in Sass: http://www.sitepoint.com/extra-map-functions-sass/

/// An equivalent of `zip` function but for maps.
/// Takes two lists, the first for keys, second for values.
/// @param {List} $keys - Keys for map
/// @param {List} $values - Values for map
/// @return {Map} Freshly created map
/// @see http://sass-lang.com/documentation/Sass/Script/Functions.html#zip-instance_method
@function map-zip($keys, $values) {
	$l-keys: length($keys);
	$l-values: length($values);
	$min: min($l-keys, $l-values);
	$map: ();
 
	@if $l-keys != $l-values {
		@warn "There are #{$l-keys} key(s) for #{$l-values} value(s) in the map for `map-zip`. "
				+ "Resulting map will only have #{$min} pairs.";
	}
 
	@if $min == 0 {
		@return $map;
	}
 
	@for $i from 1 through $min {
		$map: map-merge($map, (nth($keys, $i): nth($values, $i)));
	}
 
	@return $map;
}

// // Example:
// $layout-names: 'small', 'medium', 'large', 'huge';
// $layout-values: 600px, 900px, 1200px, 1500px;
 
// $breakpoints: map-zip($layout-names, $layout-values);
// // -> ('small': 600px, 'medium': 900px, 'large': 1200px, 'huge': 1500px)