@mixin listify($border-color, $padding, $caps: true) {
	@extend .no-bullet;

	> * {
		border-bottom: 1px solid $border-color;
		margin: 0;
		padding: $padding;
	}

	@if $caps == true {
		border-top: 1px solid $border-color;
	}
	@else {
		> * {

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				border: 0;
				padding-bottom: 0;
			}
		}
	}
}