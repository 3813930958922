.status--error {
	color: $color-error;
}
.status--error--disabled {
	color: transparentize($color-error, .6);
}
.status--warning {
	color: $color-warning;
}
.status--success {
	color: $color-success;
}
.status--info {
	color: $color-info;
}
.status--disabled {
	color: $black80;
}
.status--read {
	color: $default-text-color;
}


//License
.licenseStatus--notPurchased {
	background: $color-notPurchased;
}
.licenseStatus--available {
	background: $color-available;
}
.licenseStatus--availableWithRestrictions {
	background: $color-calendarHoliday;
}
.licenseStatus--default{
    background: $black;
}
.licenseStatus--error{
    background: $color-error;
}