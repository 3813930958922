/**
 * Tests whether some items from `$list` pass the test implemented by `$function`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-some
 *
 * @param {List}    $list     - list to run test against
 * @param {String}  $function - function to run against every item from list
 * @param {ArgList} $args     - extra arguments to pass to the function
 *
 * @example
 * sl-some(1 2 3, unitless)
 * // true
 *
 * @example
 * sl-some(1 2 3px, unitless)
 * // true
 *
 * @example
 * sl-some(1px 2px 3px, unitless)
 * // false
 *
 * @return {Bool}
 */
 
@function sl-some($list, $function, $args...) {
  @each $item in $list {
    @if call($function, $item, $args...) {
      @return true;
    } 
  }
  
  @return false;
}
