
#busy-indicator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .6) url("../img/SpinnerStatic.png") center center no-repeat;
    z-index: 10000;
	.spinner {
	    background: url("../img/SpinnerToRotate.png") center center no-repeat;
	    width: 100%;
	    height: 100%;
	    -webkit-animation: spin 1s linear infinite;
	    animation: spin 1s linear infinite;
	}
    button {
        left: -1000px;
        top: -1000px;
        position: absolute;
    }
}
