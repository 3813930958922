.items-to-edit {
    background-color: $black95;
    border-radius: 3px;
    padding: 8px;
    margin-bottom: 16px;
    height: 53px;
    border: 2px solid $black90;
    border-top-width: 1px;
    position: relative;
    z-index: 1;
    
    button {
        float: right;
    }
    
    p {
        margin: 9px 0;
        font-weight: 400;
    }
}

button.discard {
    padding: 0;
    margin: 0;
    font-family: inherit;
    background: none;
    border: none;
    display: inline;

    &:focus {
        outline: none;

        a {
            color: $active !important;
            
        }
    }

    a {
        color: $keycolor;        
    }
}

label.flex-ellipsis {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include justify-content(flex-start);
    @include align-content(stretch);
    @include align-items(flex-start);

    & > span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include order(0);
        @include flex(1 1 auto);
        @include align-self(auto);
    }

    button {
        padding-left: 5px;
    }

    .hide-if-ellipsis {
        display: none;
    }
}

.details.multiple-edit {
    .full-height {
        height: 100%;
    }

    .no-permissions-warning {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        height: 100%;
        color: $black75;
        font-size: 20px;
        text-align: center;

        p {
            margin: 0.5em 0;
        }

        div {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        .icon-info {
            font-size: 24px;
        }
    }
}

.tab-discard-link {
    position: absolute;
    bottom: 2px;
    margin-left: 4px;
    font-size: 0;

    button {
        font-size: 15px;
    }
}

.visibility-hidden discard-link a {
    visibility: hidden !important;
}