/** Removes all false and null values from `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#purge
 *
 * @requires sl-is-true
 * @requires sl-to-list
 *
 * @param {List} $list - list to purge
 *
 * @example
 * sl-purge(null a false b)
 * // a b
 *
 * @return {List}
 */

@function sl-purge($list) {
  @if sl-missing-dependencies(sl-is-true, sl-to-list) == true { @return null; }
  
  $result: ();

  @each $item in $list {
    @if sl-is-true($item) {
      $result: append($result, $item, list-separator($list));
    }
  }

  @return sl-to-list($result);
}

/**
 * @requires sl-purge
 * @alias sl-purge
 */
 
@function sl-clean($list) {
  @return sl-purge($list);
}
