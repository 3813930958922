nav
{
    font-weight: 400;
    min-width: 980px;
    position: relative;
    background: #0069a5;
    background: linear-gradient(to bottom, #0069a5 0%,#0069a5 50%,#004f7d 100%);
    border-top: 1px solid #004f7c;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    &:empty {
        display: none;
    }
    a {
        color: white;
        text-decoration: none;
    }
    .container>ul {
        margin: 0 16px 0 35px;
        border-left: 1px solid #1076ab;
        &>li {
            min-height: 19px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            padding: 11px 10px 0 16px;
            height: 40px;
            border-right: 1px solid #1076ab;
            border-left: 1px solid #00598a;
            font-size: 17px;
            span {
                font-size: 7px;
                line-height: 7px;
                vertical-align: middle;
                text-shadow: none;
                margin-left: 3px;
            }
        }
    }
    ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            background: #0069a5;
            background: linear-gradient(to bottom, #0069a5 0%,#0069a5 50%,#004f7d 100%);
            color: white;
            display: inline-block;
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
                background: #0069a5;
                background: linear-gradient(to bottom, #3a8eba 0%,#2180b2 23%,#0073aa 45%,#0069a5 100%);
                color: #fff;
                ul {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                }
            }
            ul {
                margin-left: -17px;
                padding: 0;
                position: absolute;
                top: 40px;
                box-shadow: none;
                display: none;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.2s;
                z-index: $menu-dropdown-z-index;
                li {
                    background: #006aa6;
                    display: block; 
                    color: #fff;
                    &:hover {
                        background: #3388b8;
                    }
                    a {
                        display: block;
                        padding: 6px 25px 5px 16px;
                    }
                }
            }
            .nav__separator {
                border-left: none;
                border-right: none;
                border-top: 1px solid #005585;
                border-bottom: 1px solid #3388b8; 
            }
            &.force-close {
                ul{
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }
}
