$warning-color-inner-border-color: #f9d0a8;

$warning-color-inner-border-background-1: #F5B16E;
$warning-color-inner-border-background-2: #F3B06D;
$warning-color-inner-border-background-3: #E5A567;

$advice-message-label-text-color: #713800;
$warning-cross-color: #b07336;

.warning-label-border{
    border-color: $warning-color-inner-border-color;
    border-style: solid;
    // outline: 1px solid $color-warning;
    box-shadow: 0 0 0 1px $color-warning;
    border-width: 1px; 
    border-radius: 2px; 
    background: linear-gradient(to bottom, $warning-color-inner-border-background-1 0%, $warning-color-inner-border-background-2 66%,darken($warning-color-inner-border-background-3,14%) 100%); /* W3C */ 
    color: $advice-message-label-text-color;
    font-size: 16px;
    padding: 5px;
    font-weight: 400;
    &:not(.not-justify){
        text-align: center;
    }
    &.listed{
        margin: 16px 0px 16px 0px;
    }
    a{
        text-decoration: none;
        color: $advice-message-label-text-color;
        font-weight: 600;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    button{
        cursor: pointer;
        color: $advice-message-label-text-color;
        font-weight: 600 !important;
        background: none !important;
        border: none !important;
        font: inherit;
        padding: 0px;
        outline: none;
        &:hover{
            text-decoration: underline;
        }
    }
    position: relative;
    .warning-label__close {
        border: none;
        background: none;
        position: absolute;
        right: 8px;
        top: 8px;
        font-size: 16px;
        color: $warning-cross-color;
        &:hover{
            text-decoration: none;
            opacity: 0.7;
        }
    }
    &.warning-label__small-font {
        font-size: 14px;
    }
    &.warning-label__padded {
        padding: 8px 10px;
    }
    &.warning-label__inline {
        display: inline-block;
        padding-right: 36px;
        max-width: 100%;
        span {
            display: block;
            overflow: hidden;
            height: 18px;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}