// Reference URL: http://hugogiraudel.com/2014/04/28/casting-map-into-list/
@function to-list($value, $keep: 'both') {
  $keep: if(index('keys' 'values', $keep), $keep, 'both');

  @if type-of($value) == map {
    $keys: ();
    $values: ();
    @each $key, $val in $value {
      $keys: append($keys, $key);
      $values: append($values, $val);
    }

    @if $keep == 'keys' {
      @return $keys;
    }
    @else if $keep == 'values' {
      @return $values;
    }
    @else {
      @return zip($keys, $values);
    }
  }

  @return if(type-of($value) != list, ($value,), $value);

}


// // If you don’t like conditional return statements
// // or if you simply want to look like a badass with
// // an unreadable ternary mess, you could return something like this:

// @return if($keep == 'keys', $keys, if($keep == 'values', $values, zip($keys, $values)));

// // Literally:
// // 1) If $keep is 'keys', return $keys
// // 2) Else if $keep is 'values', return $values
// // 3) Else return zip($keys, $values)




// // Example
// $breakpoints: (
//   small: 600px,
//   medium: 900px,
//   large: 1200px
// );

// $breakpoints-list: to-list($breakpoints, 'both');
// // (small 600px, medium 900px, large 1200px)

// $breakpoints-keys: to-list($breakpoints, 'keys');
// // (small medium large)

// $breakpoints-values: to-list($breakpoints, 'values');
// // (600px 900px 1200px)