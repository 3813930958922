.select2-dropdown {
  background-color: white;

  border: 1px solid #aaa;
  border-radius: 4px;

  box-sizing: border-box;

  display: block;

  position: absolute;
  left: -100000px;

  width: 100%;
  
  .select2-dropdown__filter-icon {
      display: inline-block;
      width: 16px;
      vertical-align: middle;
  }
  .select2-dropdown__filter-warning {
      width: 10px; 
      display: inline-block;
      padding-left: 3px;
  }
}

/*.select2-results {
  display: block;
  overflow: auto;
  max-height: 200px;
}*/

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
  /*display: table;*/
}

.select2-results__option {
  padding: 6px;
  
  min-width: 100%;
  display: block;

  user-select: none;
  -webkit-user-select: none;

  &[aria-selected] {
    cursor: pointer;
  }

  white-space: nowrap;
  .select2-selection__text {
    display: inline-block
  }
  .select2-selection__img {
    display: inline-block;
    margin-right: 5px;
  }
  .select2-selection__category{
    display: block;
    width: 100%;
    font-size: 15px;
    color: #CCCCCC;
  }
  
  .select2-selection__placeholder {
			font-style: italic;
			font-size: 15px;
			font-weight: 200;
		}
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}

.select2-selection {
  .select2-dropdown__filter-icon {
      vertical-align: middle;
  }
  .select2-dropdown__filter-warning--selected {
      width: 10px; 
      display: inline-block;
      padding-left: 3px;
  }
}