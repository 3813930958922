#license-activation-component{
 
    margin: 16px;
   .licensing-tab-container{
        position: relative;
        text-align: left;
        width: 570px;
        box-sizing: border-box;
        
        .tabs__content{
            background: white;
            width: 100%;
            box-sizing: border-box;
            padding: 0;
            
           .licensing-content-container{
                position: relative;
                padding: 24px;
                text-align: center;
                box-sizing: border-box;
                width: 100%;
                
                .form-container{
                    position: relative;
                    height: 200px;
                    width: 100%;
                    .warning-label-border {
                        margin-bottom: 16px;
                    }
                    &.manual-licensing{
                        top: 16px;
                    }
                    input {
                        width: 100%;
                    }
                    
                    .spa {
                        position: relative;
                        letter-spacing: -.31em;
                        span,input {
                            letter-spacing: normal;
                            display: inline-block;
                        }    
                        span{
                            width: 40px;
                        }
                        input {
                            width: calc(100% - 40px);
                        }
                        &::after {
                            letter-spacing: normal;
                            position: absolute;
                            display: block;
                            right: -15px;
                            content: '_';
                            bottom: 13px;
                        }
                    }
                    
                    .column{
                        position: relative;
                        width: 50%;   
                        text-align: left;
                        margin: 16px 0 0 0; 
                            .field{                       
                                min-height: 60px;
                                display: block;
                                input{
                                    width: 100%;
                                
                                }
                            }
                            input[type=text]{ 
                                margin-bottom: 0;
                            }
                        }
                    
                                       
                    .left--column{
                            @extend .column;
                            float: left;
                            padding: 0 8px 0 0;
                        }
                
                    .right--column{
                        @extend .column;
                        float: right;
                        padding: 0 0 0 8px;
                    } 
                    
                    .visit-webpage-msg{
                            // margin: 16px 0 0 0;
                            margin:0;
                            text-align: center;
                            .simple-msg{               
                                color: $black60;
                            }     
                            a{
                            color: $black30;
                                text-decoration: none; 
                            }
                            a:hover{
                                text-decoration: underline;
                            }
                        }
                        
                        .installation-id-line {
                            margin: 16px auto;
                            .installation-id{
                                font-family: "Courier New", Courier, monospace;
                                font-size: 12px;
                                vertical-align: top;
                                color: $black60;
                                height: 35px;
                                display: inline-block;
                                background-color: $black95;
                                border-radius: 5px;
                                border-style: none;
                                border-width: 1px;
                                padding: 11px;
                                margin-right: 4px;
                            }
                        }

                .upload-file-input{
                            position:relative;    
                            overflow: hidden;
                            box-sizing: border-box;
                            border-style: none;
                            border: 1px solid $black85;
                            border-radius: 4px;
                            height: 34px;
                            cursor: pointer;
                            
                        &:not(.ng-pristine).ng-invalid{
                            border: 1px solid #dc000c !important;
                        }
                        
                        &:focus{
                            outline:0;
                            border-color: $active !important;
                        }
                    
                        &.has-hover{
                            border-color: $keycolor;   
                            .file-input-overlay span{
                                color: $black40;
                            }
                        }
                      .file-input-overlay {
                          position: absolute;
                          top: 0px;
                          width: 100%;
                          height: 100%;
                            span{
                                position: absolute;
                                padding: 8px;
                                top: 0;
                                right: 0;
                                color: $black60;
                                cursor: pointer;
                            }
                      }
                        
                        input{
                            //Se pone important para que width o margin no sobreescriba estos valores.
                            //Ya que es importante que ambos inputs esten centrados igual.        
                                margin: 0 !important;   
                                height: 100%;
                                // width: 100% !important;
                        }
                        
                        input[type=file]{
                                width: 100% !important;  
                                position: absolute;

                                top: 0;
                                right: 0;
                                // margin: 0;
                                padding: 0;
                                filter:alpha(opacity= 0);
                                opacity: 0;
                                color: transparent; //no funciona en IE11
                                cursor: pointer;
                                z-index:-1;
                            }
                            
                            input[type=text]{ 
                                
                                width: 100% !important; 
                                // margin: 0;     
                                border-style: none;
                                cursor: pointer;
                                &[disabled]{
                                    @include gradient-vertical-with-inner(rgba(0,0,0,.1), rgba(255,255,255,0.05), rgba(255,255,255,0.05), 0%, 40%, 100%);
                                    background-color: $white;
                                    border-style: none;
                                    opacity: 1;
                                }
                            }
                        }

                       
                                   
                        
                         
                    }               
            }  
             .button-container{
                    position: relative;
                    width: 100%;
                    bottom:12px;
                    text-align: center;
                }
             
        }            
    }
}
   
#copy-to-clipboard-fallback {
    text-align: center;
    font-size: 16px;
    .ngdialog__icon {
        color: #1fb0ed;
    }
    h2 {
        color: #1fb0ed;
    }
    hr {
        border-width: 1px;
        border-style: solid;
        width: 42px;
    }
    .press-keys {
        font-size: 18px;
        font-weight: 800;
    }
    input {
        text-align: center;
    }
    p {
        margin: .75em 0;
    }
}

