@import "login",
		"online-monitoring",
		"lockdown-monitoring",
		"limited-occupancy-monitoring",
		"roll-call-monitoring", 
        "license-activation-component",
		"details",
        "about",
        "features",
        "add-delete",
        "progress-bar",
        "home",
        "home1Element",
        "home2Elements",
        "home3Elements",
        "home4Elements",
        "home5Elements",
        "home6Elements",
        "home7Elements",
        "home8Elements",
        "home9Elements",
        "settings",
        "attendance-registration",
        "same-as",
        "users",
        "general-options",
        "operators",
        "opening-mode-schedule",
        "multiple-edit",
        "multiple-add",
        "key-and-confirm-key",
        "calendars",
        "multiple-edit",
        "salto-network",
        "broker-test-key",
        "calendars",
        "lockers",
        "audit-trail",
        "sam-and-issuing",
        "ng-img-crop",
        "system-resources",
        "scheduled-jobs",
        "ppd",
        "keys",
        "user-defined-events",
        "report-viewer",
        "partitions",
        "hotel",
        "rooms",
        "just-in",
        "group-checkin",
        "roll-call",
        "pms",
        "visitor-check-in",
        "visitor-check-out",
        "webcam-image",
        "time-zones";
