// HELPER CLASSES
// Para eliminar rápidamente estilo de lista por defecto
.no-bullet {
	padding-left: 0;

	> li {
		list-style: none;
		list-style-type: none;
	}
}

.pointer {
	&,* {
		cursor: pointer !important;
	}
}

//Clase que angular elimina cuando procesa una plantilla
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak, .translate-cloak {
  display: none !important;
}

// Floats and Clears
// .float-left		{float:left; _margin-right:-3px}
// .float-right	{float:right; _margin-left:-3px}
// .clear-right	{clear:right}
// .clear-left		{clear:left}
// .clear-both		{clear:both}

// Text alignment and case
.text-center	{text-align: center}
.text-left		{text-align: left}
.text-right		{text-align: right}

.bold {font-weight: $bold-weight}


//Margins and paddings
.margin-left {margin-left:  20px }
.margin-left-16 {margin-left:  16px }
.margin-left-8 {margin-left:  8px }

.margin-left-5 {margin-left:  5px }

.margin-right-8 {margin-right:  8px }

.margin-top-6 { margin-top: 6px };
.margin-top-8 { margin-top: 8px };
.margin-top-12 { margin-top: 12px };

.margin-bottom-8 { margin-bottom: 8px; }
.margin-bottom-16 { margin-bottom: 16px; }

.no-margin{ margin: 0px; }
.no-margin-left {margin-left: 0px}

.no-padding { padding: 0px !important; }
.padding-left-4 { padding-left: 4px;}
.padding-left-8 { padding-left: 8px;}
.padding-left-16 { padding-left: 16px !important;}


// Text uppercase
.uppercase		{text-transform: uppercase}

// Text lowercase
.lowercase		{text-transform: lowercase}

.no-text-transform { text-transform: none !important; }

// Hide for both screenreaders and browsers: css-discuss.incutio.com/wiki/Screenreader_Visibility
.hidden {
	display: none !important;
	visibility: hidden !important;
} 
.shown {
	display: block !important;
	visibility: visible !important;
}

// Hide only visually, but have it available for screenreaders: www.webaim.org/techniques/css/invisiblecontent/  Solution from: j.mp/visuallyhidden
.visuallyhidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	
	// Añadido de Goio porque a veces ocupa un espacio cuando al elemento que lleva esta clase lo contiene un padre posicionado en absoluto
	left: -9999999px;
	top: -9999999px;

	// Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: drupal.org/node/897638
	&.focusable:active,
	&.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}

.visuallyvisible {
	position: relative!important;
	clip: auto;
	left: auto;
	top: auto;
}

// Long strings truncation:
// · break: it prevents the text to break out of their container (such as an URL) by forcing the break. (IE 8+, Firefox, Safari and Chrome. It doesn’t work in Opera.)
// · ellipsis: to make an ellipsis in case the string is too long for its container.
.break {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

.ellipsis {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	-ms-text-overflow: ellipsis;// Required for IE8
	-o-text-overflow: ellipsis;// Required for Opera
	text-overflow: ellipsis;
}

// Disabled elements
// It will disable all pointer events on it (hover, click, etc.)
// A live example would be to give this class to the submit button of a form as a default.
// When every required field is properly filled, remove this class with JavaScript.
.disabled {
	pointer-events: none;
	@include opacity(.5);
}

// Disables pointer events
.no-events {
	pointer-events: none;
}

// Color para el placeholder (http://mths.be/placeholder)
.placeholder {
	color: $black70;
}

// Para elementos que solamente deseamos que se muestren en impresión. En pantalla NO se muestran. En "print.css" indicaremos que se muestren.
.print-only {
	display: none;
}

.visibility-hidden {
	visibility: hidden;
}
.right {
	float: right;
}

.left {
	float: left;
}

.cursor--default{
	cursor: default;
}

.cursor--default--important, .cursor--default--important * {
	cursor: default !important;
}

.underline {
	text-decoration: underline;
}

.display-block{
	display: block;
}

.display-inline-block{
	display: inline-block;
}

.vertical-align-bottom{
	vertical-align: bottom;
}

.vertical-align-middle{
	vertical-align: middle;
}

.vertical-align-top{
	vertical-align: top;
}

.width-auto{
	width: auto;
}

.overflow-hidden {
	overflow: hidden;
}

.centered-content {
	@include flexbox;
	@include align-items(center);
	@include justify-content(center);
}

.flex-direction-column {
	@include flex-direction(column);
}
