.hotel__check-out--dialog{
    width: 405px;
}

.hotel__cancel-key--dialog {
    .ngdialog-content {
        width: 455px;
    }
}

.hotel__check-out--dialog, .hotel__cancel-key--dialog {
    ul.select2-selection__rendered {
        max-height: 130px;
        overflow: auto !important;
    }

    field {
        margin-bottom: 4px !important;
    }
}

.hotel__re-rooming--dialog{
    width: 420px;
    height: 145px;
    &.in-progress{
        display: table;
        p{
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }
    }
}

.hotel__room-status--dialog{
    width: 680px;
    padding: 8px 0 16px;
    height: 408px;
    .rooms-statu-list__form{
        .table-legend-container{
            position: relative;
            bottom: 0;
        } 
    }
}

.row-legend__img{
    margin-left: 0px;
    margin-right: 5px;
}

.hotel__rooms-status{
    display: block;
    height: 100%;
    .table-container{
        outline: none;
    }
}

.hotel__rooms-status--free{
    padding-left: 20px;
}

.hotel__rooms-status--free-extra{
    padding-left: 36px !important;
}

.select2-selection__rendered{
    .hotel__rooms-status__option{
        display: block;
        img{
            vertical-align: top;
            margin-top:9px;
        }
        span{
            display: inline-block;
            padding-right: 8px;
        }
    }
}

.hotel__rooms-status__option--empty{
    span {
        padding-left: 16px;
    }
    img {
        display: none;
    }
}

.phone-check-in-container {
    display: inline-block;
    position: relative;
    top: 1px;

    field:first-child {
        margin-bottom: 5px !important;
    }
}

.autocomplete-force-l {
    overflow: visible !important;

    .autocomplete {
        width: 225px;
    }

    .field__info-focus {
        white-space: nowrap;
    }
}

.no-items-warning {
    opacity: 0.7;
    font-size: 16px;
    text-align: center;
    position: relative;
    top: 18px;
}

.optional-permissions-detail-box {
    .optional-permissions-tree-wrapper {
        outline: none !important;
    }

    .detail-box__content {
        max-height: 300px;
        min-height: 50px;
        overflow: auto;
        margin: 0;
        padding: 4px;
    }

    .applied-filter__value {
        padding-right: 1px; // Avoid the content being cut in IE
    }

    .fields .field {
        padding: 0 0 0 8px;
        width: 100% !important;

        & > * {
            padding: 8px 0;
        }
    }
    
    .confirmed-checkins {
        padding: 8px 8px 8px 0;
        .icon-ok {
            color: $active;
        }
    }
}

.number-of-nights-wrapper {
    height: 78px;
    display: inline-block;
    vertical-align: top;

    .height-0 {
        height: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
}

select[select2][readonly] + .select2-container .select2-selection--multiple {
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  .select2-selection__choice {
    background: none;
    border: none;
    font-weight: 200;
    padding: 4px 0 0 0;
    margin: 0;
    font-size: 100%;
    color: #999;

    &:not(:first-child)::before {
      content: ", ";
    }
  }

  .select2-search {
    display: none;
  }

  .select2-multiple-item-tooltip-wrapper {
      max-width: 100%;
  }
}

.selected-encoder {
    .selected-encoder-field {
        height: 34px;
    }

    .check-in-selected-encoder-label {
        &.encoder-name {
            color: $black60;
        }
        
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .check-in-selected-encoder-label, .encoder-not-needed {
        display: inline-block !important;
        padding-top: 8px;
    }

    .icon {
        margin-top: 8px;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        vertical-align: top;
    }

    label {
        margin-left: 2px;
    }

    button {
        margin-left: 5px;
        vertical-align: top;

        span.square {
            margin: 0;
        }
    }
}

.hotel__one-shot-key--dialog {
    width: 380px;
    min-height: 120px;
    max-height: 190px;
    .select2-selection.select2-selection--multiple {
        max-height: 90px;
        overflow: auto;
    }
    .one-half label {
        margin-bottom: 0;
    }
}

.check-in-notification-message {
    width: 266px;
    height: 100px;
}

.encoder-select {
    .fields-table {
        border-spacing: 0;
    }

    .field {
        white-space: nowrap;
        height: 34px;
        vertical-align: top;

        padding-top: 8px;
        &.no-padding-top {
            padding-top: 0;
        }

        label {
            margin: 0;
            padding-right: 6px;
        }

        .ctrl {
            padding-top: 2px;
        }

        &.discovery-failed {
            height: auto;
            white-space: normal;
        }
    }
}