// Main colors
$black: #000;
$black10: lighten($black, 10%);// #1a1a1a
$black20: lighten($black, 20%);// #333
$black30: lighten($black, 30%);// #4d4d4d
$black40: lighten($black, 40%);// #666
$black50: lighten($black, 50%);// #808080
$black60: lighten($black, 60%);// #999
$black70: lighten($black, 70%);// #b3b3b3
$black75: lighten($black, 75%);// #bfbfbf
$black80: lighten($black, 80%);// #ccc
$black85: lighten($black, 85%);// #d9d9d9
$black90: lighten($black, 90%);// #e6e6e6
$black95: lighten($black, 95%);// #f2f2f2
$black97: lighten($black, 97%);
$black98: lighten($black, 98%);// #fafafa

$white: #fff;

$keycolor: #1fb0ed;
$active: #00cfa4;

$default-text-color: $black40;

// Status colors
$color-error: #cc0000;
$color-error-bright: #ff0000;
$color-error-border: #dc000c;
$color-warning: #cc6600;
$color-success: #90cc00;
$color-info: #1fb0ed;

// Header
$header-background: #0092cf;

//Side-menu-panels
$side-menu-option: #40b0f0;
$side-menu-option-dark: #1a8ccd;
$side-menu-content: #006aa6;
$side-menu-header: #005f95;
$side-menu-close: #1f3b53;
$side-menu-close-hover: #627687;
$side-menu-header-icon: #004c77;


//License status
$color-notPurchased: #9d9d9d;
$color-available: #417105;
//unavailable --> $color-error

//Holiday colors
$color-calendarHoliday: #cfc000;
$color-calendarSpecial1: #CF0066;
$color-calendarSpecial2: #0092CF;
$color-calendarNormal: $white;

$table-header-button-border-color: #e5e5e5 !default;
