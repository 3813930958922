[class^='ng-invalid']>label, [class*=' ng-invalid']>label, label[class^='ng-invalid'], label[class*=' ng-invalid'] {
    color: $color-error-bright;
    &.label--hide-error{
        color:#666666;
        &.disabled {
            color: $black60;
        }
    }
}

.error-message {
    position: absolute;
    /*height: 25px;*/
    background: $color-error;
    font-size: 14px;
    color: white;
    border-radius: 2px;
    padding: 4px 8px;
    box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.3);
    max-width: 300px;
    z-index: $error-z-index;
    display: table;
    .icon-error{
        display: table-cell;
        // Aligning the button to the top prevents it from moving whenever the
        // error tooltip changes its height.
        vertical-align: top;
    }
    .message {
        display: table-cell;
        padding-left: 10px;
        font-family: Verdana, Geneva, sans-serif;
        font-size: 11px;
        vertical-align: middle;
        max-width: 260px;
        word-wrap: break-word;
    }
}