// Reference URL: http://www.sitepoint.com/using-sass-maps/

// $configuration: (
// 	padding: 1em,
// 	margin: 0 1em,
// 	color: grey
// );
 
// .element {
// 	@include print($configuration);
 
// 	&::before {
// 		background-color: map-get($configuration, color);
// 	}
// }
 
// // Obtienes:
// .element {
// 	padding: 1em;
// 	margin: 0 1em;
// 	color: grey;
// }
 
// .element::before {
// 	background-color: grey;
// }

@mixin print($declarations) {
	@each $property, $value in $declarations {
		#{$property}: $value
	}
}