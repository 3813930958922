$field-with-spinner-right-padding:19px;

select[select2], select[select-2] {
    visibility: hidden;
}
input[type=text],
input[type=search],
input[type=number],
input[type=email],
input[type=password],
textarea,
.ip-address-container{
	font-family: $main-font-family;
	font-weight: 200;
	color: $black40;
	border: 1px solid $black85;
	box-sizing: border-box;
	height: 34px;
	line-height: 34px;
	border-radius: 4px;
	outline: none;
	padding: 6px 8px;
	background: $white; /* Old browsers */
    @include gradient-vertical-with-inner(rgba(0,0,0,.1), rgba(255,255,255,0.05), rgba(255,255,255,0.05), 0%, 40%, 100%);
	background-color: $white;
	font-size: $main-font-size;
	line-height: $main-font-size;
    position: relative;

    &[readonly] {
        border: 0;
        background: transparent;
		padding-left: 0;
        color: $black60;
		text-overflow: ellipsis;
		overflow: hidden;
		&:not(textarea){
			white-space: nowrap;
		}
		
		&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			opacity: 0;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
			opacity: 0;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			opacity: 0;
		}
    }
	&[disabled]:not(textarea).no-disabled-background{
		background: transparent;
		&:hover{
			border-color: $black85;
		}
	}
    &[disabled]:not(.no-disabled-background) {
        @include gradient-vertical-with-inner(#ccc, #cecece, #ddd, 0%, 40%, 100%);
        opacity: .7;
		&:not(.force-error) {
        	border-color: $black85 !important;
		}
    }
	
	&:hover, &.hovered.ng-pristine:not(.focused, :focus), &.hovered:not(.focused, :focus, .ng-invalid) {
		border-color: $keycolor;
		
		&[spinner-keyboard-disabled]:not(.no-disabled-background)
		{
			border-color: $black85 !important;
		}
	}
	
	&:focus, &.focused {
		border-color: $active;
	}
	&::-ms-clear {
		display: none;
		width: 0;
		height: 0;
	}
	&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		font-style: italic;
		color: $black70;
		font-weight: 200;
	}
	&::-moz-placeholder { /* Mozilla Firefox 19+ */
		font-style: italic;
		color: $black70;
		font-weight: 200;
		opacity: 1;
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		font-style: italic;
		color: $black70;
		font-weight: 200;
	}

	&[disabled] {
		&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			font-style: italic;
			color: $black50;
			font-weight: 200;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
			font-style: italic;
			color: $black50;
			font-weight: 200;
			opacity: 1;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			font-style: italic;
			color: $black50;
			font-weight: 200;
		}
	}
    &.ng-invalid:not(.ng-pristine), .ng-invalid:not(form) &, &.ng-invalid.force-error {
        //border: 1px solid #dc000c;
        //border-width: 1.8px 1.5px 1.5px 1.5px;
		border-color: $color-error-border;
		//box-shadow: 0px 0px 1.8px 1.8px rgba(#dc000c, 1);
    }
    
    &.flat{
        height: 20px;
        padding: 1px 8px;
    }
	
    /*
    Tipos para inputs tipo text
    */
    &.field--inline{
        vertical-align: top;
        height: 24px;
        width: 55px;
        font-size:15px;
        padding: 0px 16px;
        margin-top: -3px;
    }
    &.field--has-button {
        margin-right: 5px;
    }
    &.field--has-icon {
        width: auto;
    }
	
	// Prevent Chrome setting a yellow background (just changing background-color
	// doesn't override the default behavior)
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 1000px #FFF inset !important;
	}

	&.field-with-spinner {
		padding-right: $field-with-spinner-right-padding;
		&.spinner--hidden{
			padding-right: 8px;
		}
	}
}

//Repeat textarea readonly styles
textarea[readonly]{
	background: transparent;
	color: $black60;
	border:none;
	overflow-y: auto;
}

.ctrl.invalid-checkbox {
	position: relative;
}
.ctrl.invalid-checkbox::after {
	content: "";
    width: 7px;
    height: 7px;
    display: inline-block;
    position: absolute;
    left: 6px;
	top: 1px;
	background: -moz-linear-gradient(45deg, transparent 0%, transparent 49%, #dc000c 50%, #dc000c 100%);
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, transparent), color-stop(49%, transparent), color-stop(50%, #dc000c), color-stop(100%, #dc000c));
	background: -webkit-linear-gradient(45deg, transparent 0%, transparent 49%, #dc000c 50%, #dc000c 100%);
	background: -o-linear-gradient(45deg, transparent 0%, transparent 49%, #dc000c 50%, #dc000c 100%);
	background: -ms-linear-gradient(45deg, transparent 0%, transparent 49%, #dc000c 50%, #dc000c 100%);
	background: linear-gradient(45deg, transparent 0%, transparent 49%, #dc000c 50%, #dc000c 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000FF', endColorstr='#dc000c',GradientType=1 );
	background-size: 7px 7px;
}
ip-address {
	display: block;
	.ip-address-container {
		display: inline-block;
		>input {
			border: none;
			background: none;
			text-align: center;
			margin: -6px -5px;
		}
		>span {
			font-weight: $bold-weight;
			position: absolute;
			bottom: 8px;
			margin-left: -2px;
		}
	}
}

// Tamaños para inputs tipo text
.field--xxs {
	width: $field-xxs-size;
}

.field--xs {
	width: $field-xs-size;
}

.field--s {
	width: $field-s-size;
}

.field--m {
	width: $field-m-size;
}

.field--l {
	width: $field-l-size;
}

.field--xl{
	width: $field-xl-size;
}

.field--xxl{
	width: $field-xxl-size;
}

.field--xxxl{
	width: $field-xxxl-size;
}

.field--full-width{
	width: 100%;
}

input[type=number], .numeric-input {
	text-align: right;
	-moz-appearance: textfield; /* hide default spinners in Firefox */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button { /* hide default spinners in Chrome */
		-webkit-appearance: none;
		margin: 0;
	}
	&.align-left-when-readonly[readonly] {
		text-align: left;
	}

	&.field--xs {
		width: 34px;
		&.field-with-spinner {
			width: 45px;
		}
	}
	&.field--s {
		width: 49px;
		&.field-with-spinner {
			width: 60px;
		}
	}
	&.field--m {
		width: 64px;
		&.field-with-spinner {
			width: 75px;
		}
	}
	&.field--l {
		width: 80px;
		&.field-with-spinner {
			width: 91px;
		}
	}
	&.field--xl {
		width: 96px;
		&.field-with-spinner {
			width: 107px;
		}
	}
	&.field-with-spinner {
		padding-right: $field-with-spinner-right-padding;
	}
	&.text-left {
		text-align: left;
	}
}
select.ng-invalid:not(.ng-pristine) ~ .select2-container,
select.ng-invalid.force-error ~ .select2-container {
	.select2-selection {
		border-color: #dc000c;
	}
}

select.select-auto-width ~ .select2-container {
	min-width: 180px;
	max-width: 100%;
	.select2-selection--single .select2-selection__rendered {
		max-width: none;
	}
}
select.select-auto-width--l ~ .select2-container {
	min-width: 235px;
	max-width: 100%;
	.select2-selection--single .select2-selection__rendered {
		max-width: none;
	}
}
select.select-full-width ~ .select2-container {
	width: 100%;
}
.input-button-ctrl {
	display: table;
	width: 100%;
    .input-button-ctrl__button, .input-button-ctrl__input {
		display: table-cell;
		padding: 0;
		margin: 0;
		line-height: 34px;
		height: 34px;
		vertical-align: top;
	}
	.input-button-ctrl__button {
		height: 34px;
		vertical-align: top;
        width: 1px;
    }
	.input-button-ctrl__input {
		input[type=text]:not(.fullpicker-time) {
			border-radius: 4px 0 0 4px;
			width: 100%;
		}
	}
	button, input[type=submit] {
		height: 34px;
		width: 34px;
		border-radius: 0 4px 4px 0;
		box-sizing: border-box;
		background: $black60;
		border: 1px solid $black85;
		color: white;
		span {
			padding: 0;
			margin: 0;
		}
	}
}

.spinner-wrapper {
	display: inline-block;
	position: relative;

	&:focus {
		outline: none;
	}

	&:empty {
		display: none;
	}
}

.numeric-spinners {
	margin-left: -17px;
	width: 16px;
	display: inline-block;
	position: absolute;
	right: 1px;
	top: 1px;

	&.flat button.down {
		top: 9px;
	}

	button {
		width: 16px;
		height: 16px;
		display: block;
		border: none;
		outline: none;
		padding: 0;
		background: none;
		font-size: 80%;
		&:hover:not([disabled]) {
			background-color: #e6e6e6;
		}
		&:active:not([disabled]) {
			background-color: #c0c0c0;
		}
		&.up {
			border-top-right-radius: 4px;
		}
		&.down {
			position: absolute;
			top: 16px;
			border-bottom-right-radius: 4px;
		}
		span {
			position: relative;
			top: 1px;
		}		
	}
	&.numeric-spinners--inline {
		margin-top:-3px;
		margin-left:-14px;
		button{
			width: 12px;
			height: 12px;
			color: $black70;
		}

	}
	&.numeric-spinners--disabled {
		pointer-events: none;
	}
    
    &.flat{
		margin-bottom: 4px;
        button {
            height: 9px;
			span {
				position: absolute;
				top: -6px;
				left: 2px;
			}
        }       
    }
}

// Format disabled telephone inputs
.intl-tel-input {

	&.allow-dropdown.readonly {
		opacity: 1;

		.flag-container {
			display: none;
		}

		input {
			padding-left: 0;
		}
	}

	&.disabled {
		opacity: 1;
	}
}

.select2-results__option {
  .select2-selection__placeholder {
	  color: #999;
  }

  .select2__hidden-option {
	  display: none;
  }
}

.select2-spinner, .select2-error {
	height: 80px;
	text-align: center;
 
	img {
		margin-top: 21px;
	}
}

.select2-results__options--with-horizontal-scrollbar {
	.select2-error:not(.ng-hide) {
		display: table-row !important;
		
		p {
			padding: 6px 6px 10px 6px;
			white-space: normal;
			margin: 0;
		}
		
		button {
			padding: 0 10px 0 6px;
			margin: 0 6px;
		}
	}
}

.select2-error {
	background-color: #F1DEDE;
	color: #900;
	font-weight: $bold-weight;
	padding: 6px;
	
	p {
		margin: 0 0 1em 0;
	}
	
	button {
		font-size: 13px;
		padding: 0 10px 0 6px;
	}
}

.select2-results__option {
	padding: 0;

	&.select2-results__message, &.select2-results__option--load-more {
		padding: 6px;
	}

	&:empty {
		display: none;
	}

	& > * {
		display: block;
		padding: 6px;
	}

	& > .title {
		display: block;
		padding-bottom: 0px;
		padding-right: 4px;
		&.simple{
			padding-bottom: 6px;
		}
	}

	& > .partition {
		display: block;
		padding-top: 0px;
	}
}

.select2-results__option:empty {
	display: none;
}

.select2-search--dropdown {
	padding: 4px 6px;
	
}

input[type=checkbox] {
	&.ng-invalid{
	    outline: 1px solid #F00;		
	}
}
textarea {
	resize: none;
}
select[disabled] ~ .select2-container {
	.select2-selection--single {
		background-color: $black90;
		cursor: default;
		color: $black60;

		.select2-selection__arrow b {
			color: $black60;
		}
		.select2-selection__clear {
			display: none;
		}
		&:hover, &:focus {
			border-color: #cccccc;
		}
	}
}
select[disabled][readonly] ~ .select2-container {
	.select2-selection--single {
		background: transparent;
		border: 0;
		.select2-selection__arrow {
			display: none;
		}
		.select2-selection__rendered {
			padding-left: 0;
			color: $black60;
		}
	}
}