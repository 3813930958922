/***
Estilo que tendrán los select cuando estén dentro de una tabla
***/
td.transparent--select{
    .select2-container--salto {
        padding: 0;
        min-width: 90px;
        &.select2-container {
            width: calc(100% - 8px);
            .select2-selection {
                &:before{
                    content: '';
                    margin: 4px;
                    width: calc(100% - 17px);
                    height: calc(100% - 12px);
                    border: 1px dotted $active;
                    position: absolute;
                    visibility: hidden;
                    .selected &{
                        border-color: white;
                    }
                }
                border-color:transparent;
                background: none;
                padding-left: 8px;
                &:focus{
                    &:before{
                      visibility: visible;
                    }
                }
            }
            &.select2-container--open {
                .select2-selection {
                    border-color: $active;
                    &:before {
                        visibility: hidden;
                    }
                }
            }
        }
    }
}