/**
 * Removes value from `$list` at index `$index`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-remove-nth
 *
 * @requires sl-replace-nth
 *
 * @param {List}   $list  - list to remove value from
 * @param {Number} $index - index to remove
 *
 * @example
 * sl-remove-nth(a b c, 2)
 * // a c
 *
 * @example
 * sl-remove-nth(a b c, 42)
 * // null
 *
 * @return {List | Null}
 */

@function sl-remove-nth($list, $index) {
  @if sl-missing-dependencies(sl-replace-nth) == true { @return null; }

  @return sl-replace-nth($list, $index, "");
}

/**
 * @requires sl-remove-nth
 * @alias sl-remove-nth
 */
 
@function sl-without-nth($list, $index) {
  @return sl-remove-nth($list, $index);
}