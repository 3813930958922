@import "../vendor/compass-mixins/compass/css3/_flexbox";

$photo-wrapper-size: 150;

.general-options-details {
    .user-photo-wrapper {
        width: #{$photo-wrapper-size}px;
        height: #{$photo-wrapper-size}px;
        .user-photo {
            width: #{$photo-wrapper-size - 10}px;
            height: #{$photo-wrapper-size - 10}px;
            .user-photo--img {
                width: #{$photo-wrapper-size - 10}px;
                height: #{$photo-wrapper-size - 10}px;
            }
        }
        .user-photo-cover {
            width: #{$photo-wrapper-size - 10}px;
            height: #{$photo-wrapper-size - 10}px;
        }
    }   
    /*******************************
    GENERAL CONFIGURATION
    *******************************/
    .general-configuration--information-box {
        .cols--noresize {
            >* {
                overflow: visible;
            }
        }
    }
    .general-configuration--settings-box {
        min-width: 525px;
    }
    .general-configuration--time-zones-box {
        min-width: 350px;
        
        button{
            margin: 8px 0px 16px 0px;
        }
    }
    
    /*******************************
    DEVICES CONFIGURATION
    *******************************/
    .devices-configuration--subnet-mask-gateway {
        padding-left: 36px;
    }
    .devices-configuration--rf-channel {
        display: inline-block;
        width: 140px;
        &.fields {
            .field.field--inline label.field__label--radiocheck {
                max-width: 100%;
            }
        }
    }
    .ppd-configuration-password {
        display: block;
        margin: 8px 16px;
        .key-inputs {
            max-width: 120px;
        }
    }
    .fields {
        .field.devices-configuration--password {
            padding-left: 36px;
            vertical-align: middle;
            .numeric-input {
                text-align: left;
            }
        }
    }
    // .devices-configuration--data-duration {
    //     vertical-align: middle;
    //     .ctrl {
    //         padding-left: 5px;
    //     }
    // }
    .devices-configuration--cusvn {
        .text-span {
            padding-left: 5px;
        }
        .ctrl, span {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .devices-configuration--more-than-64k {
        .devices-configuration--update-period-container {
            width: 140px;
            display: inline-block;
            label {
                font-weight: 200;
                font-size: 15px;
            }
        }
    }
    
    /*******************************
    ACCESS POINT CONFIGURATION
    *******************************/
    .exit-leaves-door-open-counter{
        margin-top: 2px;
        * {
            vertical-align: middle;
        }
        .unlimited-check{
            display: inline-block;
            height: 40px;
            padding-top: 8px;
        }
    }
}

/*******************************
USER CONFIGURATION
*******************************/
.notification-text-input{
    width: 80%;
    height: 100px;
}

.tracks-user-keys{
    height: 258px;
}

.mifare-key-type-top-margin{
    margin-top: 5px;
}

.hidi-memory-data-fields{
    margin-top: 16px;
}

.user-id-button{
    margin-top: 2px;
}

.ngdialog--user-id-configuration-dialog {
    .ngdialog-content {
        width: 430px;
        .table-container {
            max-width: 396px;
        }
        .macro-list-iterator__input-container {
            width: 430px;
            margin: 20px -16px 0 -16px;
        }
    }
}

.user-configuration-keys {
    h2 {
        margin: 0;
        font-size: 20px;
        font-weight: 400;

        &.margin-above {
            margin-top: 24px;
        }
    }

    hr {
        margin-top: 10px;
        margin-bottom: 24px;
        height: 1px;
        padding: 0;
        border: 0;
        border-top: 1px solid $black85;
    }

    fieldset {
        height: 100%;
    }
}

/*******************************
WIEGAND CONFIGURATION
*******************************/
.wiegand-configuration-dialog {
    .ngdialog__content {
        max-height: 550px;
    }
}
.wiegand-configuration {
    max-width: 700px;
    .wiegand-configuration--embedded & {
        max-width: 100%;
    }
    .wiegand-configuration--readonly {
        padding-bottom: 15px;
    }
    .table-container {
        min-height: 150px;
        height: 150px;
        .tbody-wrapper {
            min-height: 103px;
        }
    }
    .wiegand-subcodes-table {
        height: 120px;
    }
    .wiegand-configuration--fields {
        label {
            width: 145px;
            vertical-align: middle;
        }
        .ctrl {
            width: calc(100% - 160px);
            vertical-align: middle;
        }
        .wiegand-configuration--bit-composition {
            display: inline-block;
            width: calc(100% - 160px);
            //padding-left: 5px;
            .wiegand-configuration--bit-composition__lsb {
                float: right; 
                width: auto;
            }
        }
        .wiegand-configuration--offset {
            label {
                width: auto;   
                margin-right: 15px;
            }
            .ctrl {
                width: 80px;   
            }
            input{
                width: 64px !important;
            }
        }
    }
    .wiegand-configuration--monospace {
        input {
            font-family: "Courier New", Courier, monospace;
        }
    }
    .wiegand-configuration--table-buttons {
        margin: 16px 0 8px 0;
    }

}
.wiegand-code-definition {
    .ngdialog__content {
        max-width: 500px;
        .wiegand-code-definition--variable-number-digits {
            margin-left: 10px;
        }
        input[type=radio] {
            padding: 6px 8px;
            height: 34px;
        }
        .wiegand-code-definition--description {
            width: calc(100% - 20px);
        }
        .number-of-digits{
            width: 96px;
        }
    }
}

/*******************************
HOTEL CONFIGURATION
*******************************/
#hotel-configuration--expiration-time-field, 
#hotel-configuration--zone-expiration-time-field, 
#hotel-configuration--start-time-field {
    &.field {
        display: inline-block;
        .ctrl {
            display: inline;
            vertical-align: middle;
            .fullpicker .fullpicker__input {
                text-align: center;
            }
            // span {
            //     font-weight: 400;
            // }
            &.hour-text{
                display: inline-block;
                margin-top: 1px;
            }
        }
    }
}
.hotel-configuration--guest-track-info-container {
    @include flexbox;
    >.field {
        @include flex(0 1 auto);
    }
    >.content-field {
        @include flex(1 1 auto);
        input[type=text] {
            width: 100%;
        }
    }
}
.hotel-configuration--associated-devices-table__checkbox {
    width: 20px;
}
#default-display-text-for-mobile-checkin {
    height: 80px;
}
#mobile-checkin-container {
    @include flexbox;
    @include align-items(flex-end);
    >textarea {
        @include flex(1 1 auto);
    }
    >button {
        margin-left: 8px;
        @include flex(0 1 auto);
    }
}
.ngdialog--door-associated-dialog {
    .ngdialog__content {
        .door-associated-device-container {
            @include flexbox;
            field {
                display: block;
            }
            >div {
                @include flex(1 1 auto);
            }
        }
    }
}
.ngdialog--energy-saving-associated-dialog {
    .ngdialog-content {
        width: auto;
        min-width: 360px;
        .ngdialog__content {
            padding: 16px 26px;
            .energy-saving--container {
                @include flexbox;
                @include flex-direction(column);
                .energy-saving--name-and-prefix {
                    @include flexbox;
                    > * {
                       @include flex(0 1 0);
                    }
                    .energy-saving--name-field {
                        @include flex(1 1 auto);
                        field {
                            width: 100%;
                            .energy-saving--device-name {
                                width: 100%;
                            }
                        }
                    }
                    .energy-saving--device-prefix {
                        padding-left: 16px;
                    }
                }
                .energy-saving--esd-activation {
                    display: inline; 
                    white-space: nowrap;
                    & + div {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
#disconnection-timeout-label {
    display: block;
    margin-bottom: 6px;
}
#disconnection-timeout-container {
    padding-left: 0px;
}
#disconnection-timeout-unit-container {
    input, label {
        vertical-align: middle;
    }
}

/*******************************
PMS CONFIGURATION
*******************************/
$pms-select-width: 200px;
$pms-enabled-width: 15px;
$pms-name-width: 250px;
#pms-configuration--protocol-table {
    .table-container {
        min-height: 130px; 
        .tbody-wrapper {
            min-height: 93px;
        }
        table tbody td {
            &.pms-configuration--channel-row {
                padding: 0;
                width: $pms-select-width;
                select.pms-configuration--channel-select ~ .select2-container {
                    width: 100%;
                    min-width: 90px !important; 
                    .select2-selection {
                        border: none;
                        background: none;
                        padding-left: 8px;
                    }
                    &.select2-container--open {
                        .select2-selection {
                            border-bottom: 1px solid $active;
                        }
                    }
                }
            }
            &.pms-configuration--enabled-row {
                width: $pms-enabled-width;
            }
            &.pms-configuration--protocol-name-row,
            &.pms-configuration--advanced-row {
                width: $pms-name-width;
            }
        }
    }
}
#pms-configuration--settings-box {
    width: 300px;
}
.pms-parameters-dialog {
    .ngdialog__content {
        width: 450px;
    }
}

/*******************************
SHIP CONFIGURATION
*******************************/
.ship-configuration--left-column {
    width: 370px;
}
.ship-configuration--right-column {
    @include flex(1 0 auto);
    padding-left: 16px;
    
    .col--noresize {
        width: 320px;
        #host-server {
            width: 100%;
        }
    }
    // .field {
    //     display: block;
    // }
}

/*******************************
BAS CONFIGURATION
*******************************/
.bas-configuration--lock-data {
    margin-left: 16px;
}
.bas-configuration--hex-field {
    padding-bottom: 10px;
    .ctrl::before {
        content: "0x";
        width: 20px;
        font-size: 15px;
        display: inline-block;
        color: $black60;
    }
    >* {
        display: inline-block;
    }
    label {
        width: 220px;
        vertical-align: middle;
    }
    input[type=text].field--m {
        width: 100px;
    }
}
#bas-configuration--settings-box {
    width: $field-xxxl-size;
}
#bas-configuration--integration-box {
    .peripheral-type-button {
        vertical-align: middle;
        margin-left: 5px;
    }
}

/*******************************
ADVANCED CONFIGURATION
*******************************/
#advanced-configuration--parameters-table {
    th, td {
        width: 50%;
    }
}
.ngdialog.ngdialog--advanced-parameters {
    .ngdialog-content {
        width: 430px;
        #add-advanced-parameter{
            table {
                width: 390px;
            }
        }
    }
}

/*******************************
VISITOR
*******************************/
.fields .visitor--expired-x-days-ago-container.field {
    span {
        padding-left: 5px;
    }
    .ctrl, span {
        display: inline-block;
        vertical-align: middle;
    }
}

/*******************************
LOCATION/FUNCTIONS
*******************************/
.add-edit-group{
    min-width: 330px;
}
location-function-groups {
    display: block;
    .detail-box {
        margin-bottom: 0px;
        .table-container {
            min-height: 248px;
            .tbody-wrapper {
                min-height: 211px;
            }
        }
    }
}

/*******************************
NOTIFICATION
*******************************/
.notification-configuration{

    .server-configuration-container{
        width:700px;
    }

    // key-and-confirm-key{
    //     $psw-right-margin: 16px;
    //     .cols--noresize{
    //         display:block;
    //         .col--noresize{
    //             label{
    //                 //Para usar variables Sass en calc() hay que interpolarlas con #{}
    //                 width: calc( #{$field-xl-size} + #{$psw-right-margin});
    //                 margin-top: 0px;
    //                 margin-bottom: 6px;
    //                 display: inline-block;
    //                 text-align: left;
    //             }                
    //         }
    //         .key-inputs{
    //             margin-left: 0;
    //             .password-input{
    //                 margin-right: $psw-right-margin;
    //             }
    //             .confirmation-input{
    //                 margin-top: 0px;
    //             }
    //             .edition-input{
    //                 display: inline-block;
    //                 width: $field-xl-size;    
    //             }
    //             .key-defined-input{
    //                 display: inline-block;
    //                 width: 242px;
    //             }
    //         }
    //     }
    // }
}

/*******************************
SECURITY CONFIGURATION
*******************************/
.security-configuration{
    .security-configuration-container{
        width:600px;
        .margin-bottom-6px {
            margin-bottom: 6px;
        }
        .security-configuration--account-lock-out {
            .ctrl {
                vertical-align: baseline;
            }
            #security-configuration--account-lock-out__label {
                margin-bottom: 8px;
            }
        }
    }
}

/*******************************
ALARM EVENTS CONFIGURATION
*******************************/

.alarm-events-configuration {
    hr {
        height: 1px;
        padding: 0;
        border: 0;
        border-top: 1px solid $black85;
        margin-left: 16px;
        position: relative;
        bottom: 8px;
    }

    field.padding-top {
        padding-top: 12px;
    }
}
