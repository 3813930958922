#visitor-check-out {
    max-width: 500px;
    font-size: 15px;
    text-align: left;
    & > p {
        font-weight: 400;
        text-align: center;
    }
    .detail-box {
        margin-bottom: 0;
        p:last-child {
            margin-bottom: 1em;
        }
    }
}