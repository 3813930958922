.right {
	float: right;
}

.left {
	float: left;
}

.no-select {
	@include user-select(none);
}

.trim-with-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Estilo para las opciones tipo placeholder (same as...)
.placeholder-option {
    font-style: italic;
    font-size: 15px;
    font-weight: 200;
    color: #999;
}

.status--error .placeholder-option {
    color: $color-error;
}

hr {
    overflow: visible;
}
