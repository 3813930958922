// Extra Map Functions in Sass: http://www.sitepoint.com/extra-map-functions-sass/

/// Test if map got all `$keys` nested with each others
/// @author Hugo Giraudel
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Keys to test
/// @return {Bool}
@function map-has-nested-keys($map, $keys...) {
	@each $key in $keys {
		@if not map-has-key($map, $key) {
			@return false;
		}
		$map: map-get($map, $key);
	}

	@return true;
}

// // Example:
// $grid-configuration: (
//   'columns': 12,
//   'layouts': (
//     'small': 800px,
//     'medium': 1000px,
//     'large': 1200px,
//   ),
// );
 
// $depth: map-has-nested-keys($grid-configuration, 'layouts', 'medium');
// // -> true
 
// $depth: map-has-nested-keys($grid-configuration, 'layouts', 'huge');
// // -> false