.salto-network-details {
    .table-footer,
    .table-container {
        border: 1px solid $black90;
    }
    .detail-box {
        .table-footer,
        .table-container {
            border: 1px solid $black80;
        }
    }
    
    .cu4k-nodes-table,
    .energy-saving-devices {
        width: 450px; 
        margin-left: 16px; 
        margin-bottom: 16px;
    }
    .rf-access-points {
        width: 350px; 
        margin-left: 16px; 
        margin-bottom: 16px;
    }
    
    .rf-nodes-table {
        width: 350px; 
        margin-left: 16px; 
        margin-bottom: 16px;
        /*> * {
            display: block;
            margin-bottom: 16px;
        }*/
    }
}

.ngdialog--add-network-device {
    .ngdialog-content {
        width: 450px;
        .ngdialog__content {
            height: 120px;
            .centered-content {
                height: 88px;
            }
        }
    }
}

.ngdialog--edit-dip-switch {
    .ngdialog-content {
        width: 380px;
        .ngdialog__content {
            height: 150px;
            .centered-content {
                height: 118px;
            }
        }
    }
}

.salto-network-table-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
}    
.salto-network {
    .salto-network__any-filter-applied .tab__panel .with-button button {
        padding-right: 0;
    }
    .tabs {
        position: absolute;
        left: 16px;
        right: 16px;
        top: 16px;
        bottom: 16px;
        .tabs__nav {
            .tabs__nav--active {
                color: $black40;
            }
            .tabs__nav--notification-message {
                color: $black60;
                background: none;
                width: auto;
                top: 0;
                border: none;
                height: auto;
                line-height: normal;
                border-radius: 0;
                right: 0;
                font-weight: 400;
                position: relative;
                padding-right: 6px;
            }
            .tab__panel a.with-button {
                padding-right: 0;
            }
        }

        .tabs__content {
            width: 100%;
            tabs-panel {
                display: block;
                > div {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 16px;
                    background-color: white;
                    salto-network-table {
                        display: block;
                        width: 100%;
                        height: 100%;
                        .table-container {
                            height: calc(100% - 21px);
                            min-height: 170px;
                            .tbody-wrapper {
                                min-height: 133px;
                                table tbody tr.flat {
                                    td {
                                        background: white;
                                    }
                                    &.non-removable-item {
                                        td {
                                            background-color: #DAF2FF;
                                        } 
                                    }
                                    &.selected {
                                        td {
                                            background: transparentize($active, 0.25);
                                        }
                                    }
                                    &:hover, &.selected:hover {
                                        td {
                                            background: transparentize($keycolor,0.25);
                                        }
                                    }
                                }
                            }
                        }
                        .icon {
                            @extend .salto-network-table-icon;
                        }
                        tr.selected {
                            color: white;
                        }
                        tr {
                            td.tree-cell {
                                padding-left: 5px;
                            }
                        }
                        .tree-grid-toggle {
                            vertical-align: middle;
                        }
                        .tree-grid-row--level-2 td.tree-cell {
                            padding-left: 39px;
                        }
                        .tree-grid-row--level-3 td.tree-cell {
                            padding-left: 73px;
                        }
                        .tree-grid-first-cell__content {
                            * {
                                vertical-align: middle;
                                line-height: 20px;
                            }
                            input[type='checkbox']{
                                margin-bottom: 0;
                            }
                            .salto-network-table__device-name {
                                display: inline-block;
                                height: 20px;
                            }
                        }
                    }
                }
                &.salto-network__tab-content--filter-applied, &.salto-network__tab-content--unreachable-devices {
                    > div.absolute-tab {
                        salto-network-table {
                            height: 100%;
                        }
                    }
                }
                &.salto-network__tab-content--unreachable-devices {
                    > div.absolute-tab {
                        salto-network-table {
                            .table-container {
                                height: calc(100% + 2px);
                            }
                        }
                    }
                }
                &.salto-network__tab-content--filter-applied {
                    .salto-network__local-filters {
                        display: none;
                    }
                }
            }
            .salto-network__local-filters {
                padding: 4px 0 16px 0;
                &.button-list > li {
                    margin-bottom: 0px;
                }
            }
            @media screen and (max-height: 740px) {
                .salto-network__local-filters {
                    display: none;
                }
                tabs-panel {
                    > div.absolute-tab {
                        salto-network-table {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    .salto-network__filter {
        .applied-filters {
            display: inline-block;
            width: auto;
            margin-left: 3px;
            .applied-filters__label{
                font-weight: 600;
            }
            .applied-filter__group:not(:first-child) {
                margin-left: 4px;
            }
            .applied-filters__label, .applied-filters__filter {
                height: 26px;
                line-height: 26px;
                vertical-align: middle;
                border-radius: 2px;
            }
            .applied-filters__label {
                background: $black40;
            }
            .applied-filter {
                .applied-filter__label {
                    text-transform: none;
                    color: #3b8878;
                }
                .applied-filter__value {
                    color: #3b8878;
                    &.has-ellipsis{
                        max-width: 120px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        vertical-align: bottom;
                        margin-bottom: 0.5px;
                        &.thiner {
                            max-width: 78px;
                        }
                        &.bigger {
                            max-width: 340px;
                        }
                    }
                }
                .applied-filter__remove-filter { 
                    top: 0;
                    vertical-align: baseline;
                    * {
                        vertical-align: top;
                    }
                }
            }
            /* 
            * FIX PARA FIREFOX: En firefox el letter-spacing y 
            * el width auto no se entienden bien, lo que provoca 
            * que los bloques del filtro aparezcan cada uno en una 
            * línea diferente. Para solucionar el problema quitamos
            * el letter-spacing negativo y eliminamos el margen entre
            * bloques con un margen negativo.
            */
            letter-spacing: normal;
            .applied-filters__filter {
                margin-left: -3px;
            }
        }
    }
    .salto-network__button-filter {
        outline: none;
        background: $black95;
        border: 0;
        color: $black;
        height: 34px;
        padding: 0 12px;
        border-radius: 3px;
        font-family: $main-font-family;
        font-size: 14px;
        .button-filter__icon, .button-filter__text, .button-filter__counter {
            display: inline-block;
            vertical-align: middle;
        }
        .button-filter__icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
        .button-filter__counter {
            color: $black60;
        }
        &:hover, &:focus {
            background: $black80;
        }
        &.active {
            background: #17c893;
            color: $white;
            &:hover, &:focus {
                background: #1bac8d;
                .button-filter__counter {
                    color: #00feca;
                }
            }
        }
    }
}

@-moz-document url-prefix() {
	.salto-network {
        .salto-network__filter {
            .applied-filters {
                .applied-filter {
                    .applied-filter__remove-filter { 
                        top: 0;
                        vertical-align: middle;
                        * {
                            vertical-align: top;
                        }
                    }
                }
                /* 
                * FIX PARA FIREFOX: En firefox el letter-spacing y 
                * el width auto no se entienden bien, lo que provoca 
                * que los bloques del filtro aparezcan cada uno en una 
                * línea diferente. Para solucionar el problema quitamos
                * el letter-spacing negativo y eliminamos el margen entre
                * bloques con un margen negativo.
                */
                letter-spacing: normal;
                .applied-filters__filter {
                    margin-left: -3px;
                }
            }
        }
    }
}

.salto-network-filter-popup {
    position: absolute;
    background: #3a3a3a;
    padding: 16px;
    .salto-network-filter-popup__header {
        border: 1px solid #4e4e4e;
        border-width: 0 0 1px 0;
        position: relative;
        height: 36px;
        color: white;
        h2 {
            padding-top: 4px;
            margin: 0;
            font-size: 1.6em;
            font-weight: $bold-weight;
        }
        .close {
            color: $white;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            font-size: 27px;
            &:hover,&:focus {
                color: #a9def4;
            }
            &:active {
                color: #5b6b71;
            }
        }
    }
    .salto-network-filter-popup__content {
        padding: 12px 0 8px 0;
    }
    .salto-network-filter-popup__footer {
        .button-list {
            padding: 0;
            margin: 0;
            > li {
                margin-bottom: 0;
                margin-right: 8px;
            }
            .button-secondary {
                &:not(:active):not(:hover):not(:focus) {
                    [class^="icon-"], [class*=" icon-"] {
                        color: $white;
                    }
                }
                &:disabled{
                    [class^="icon-"], [class*=" icon-"] {
                        color: $white;
                    }
                }
            }
        }
    }
}

.mac-address{
    font-weight: 600;
    vertical-align: top;
    color: $black60;
    height: 35px;
    display: inline-block;
    background-color: $black95;
    border-radius: 5px;
    border-style: none;
    border-width: 1px;
    padding: 11px; 
    margin-top: 6px;                           
}

.salto-network-table-legend{
    padding-top: 8px !important;
}

#cu4k-node-input-table, #cu4k-node-RELAY-table {
    .table-container {
        min-height: 155px;
        .tbody-wrapper {
            min-height: 118px;
        }
    }
}

.salto-network__no-footer-table {
    .detail-box__content {
        margin-bottom: 15px;
    }
}

.ngdialog.ngdialog--cu4k-input-edition {
    .ngdialog-content {
        width: 660px;
    }
}

.salto-network__ip-address {
    background: $black95;
    padding: 4px;
    border-radius: 2px;
    color: $black60;
    display: inline-block;
    min-width: 100px;
    text-align: center;
    font-size: 14px;
    height: 24px;
    margin: 2px 0;
    box-sizing: border-box;

    .selected & {
        background: darken($active, 10%);
        color: white;
    }
    .selected:hover & {
        background: darken($keycolor, 10%);
    }
}

.device-firmware-dialog {
    .ngdialog-content {
        width: 800px;
        .device-firmware-dialog--hidden & {
            visibility: hidden;
        }
        .ngdialog__content{
            width: 800px;
            height: 400px;
            .device-firmware-table {
                $border-size: 3px;
                height: 100%;
                .salto-network__device-firmware-error {
                    color: $color-error-bright;
                }
                .device-firmware-data-box {
                    margin-left: 42px;
                }
                &.table-container { 
                    table {
                        border-collapse: separate;
                        tr {
                            &.salto-network__device-firmware { 
                                td {
                                    border-bottom: $border-size solid transparent;
                                }
                            }
                            td {
                                &:first-child {
                                    border-left: $border-size solid transparent;
                                }
                                &:last-child {
                                    border-right: $border-size solid transparent;
                                }
                                &.salto-network__device-firmware--ip-address-column {
                                    width: 165px;
                                }
                            }
                            th.salto-network__device-firmware--ip-address-column {
                                width: 165px;
                            }
                            &.salto-network__device-parent-selected.salto-network__device-firmware--level-2 {
                                td {
                                    border-color: transparentize($active, 0.25);
                                }
                                &.salto-network__device-firmware--not-last-child td {
                                    border-bottom: none;
                                    padding-bottom: $border-size;
                                }
                            }
                            &.salto-network__device-firmware:hover,
                            &.salto-network__device-firmware.salto-network__device-parent-selected:hover {
                                td {
                                    background-color: transparent;
                                } 
                            }
                            &.salto-network__device-hovering.salto-network__device-firmware--level-2 {
                                td {
                                    border-color: transparentize($keycolor,0.25);
                                    background-color: transparent; 
                                }
                                &.salto-network__device-firmware--not-last-child td {
                                    border-bottom: none;
                                    padding-bottom: $border-size;
                                }
                            }
                            &.salto-network__device-hovering.salto-network__device-firmware--level-1,
                            &:hover {
                                td {
                                    background: transparentize($keycolor,0.25);
                                    .salto-network__ip-address {
                                        background: darken($keycolor, 10%);
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.firmware-update-error {
    p {
        margin: 0;
    }
}

#cu4k-nodes-table, #cu4eb-nodes-table, #cu4k-node-input-table {
    tbody tr td { 
        .icon-warning {
            font-size: 12px;
            color: $color-warning;
        }
        .warning-margin {
            margin-left: 11px;
        }
    }
}

#cu4k-node-input-table tbody tr td .icon-warning.inline-icon {
    margin-right: 0px;
}

.salto-network--gateway__name-and-description {
    &.cols--noresize {
        @include flex-wrap(wrap);
        .salto-network--gateway__name {
            min-width: 420px;
            max-width: 520px;
            @include flex-basis(auto);
            .salto-network-details--cu4k-gateway & {
                min-width: 350px;
                max-width: 450px;
            }
        }
    }
}
.fields .field.salto-network--gateway__use-dhcp-address {
    max-width: 238px;
}
.salto-network--include-reader-id {
    margin-top: 6px;
    input {
        vertical-align: middle;
    }
}
.salto-network__edit-cu4k-node {
    .salto-network__edit-cu4k-node--warning-field {
        height: 58px;
        .warning-label-border {
            &.warning-label__padded {
                padding-top: 7px; 
                padding-bottom: 7px;
            }
        }
    }
}

cu5k-access-point .fake-label {
    padding-top: 8px;
    font-weight: 200;
    opacity: 0.7;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* 
 *  Por alguna razón cuando estamos visualizando una pestaña, 
 *  a veces se está mostrando la barra de scroll de la otra pestaña 
 */
.visibility-hidden salto-network-table { 
    .mCSB_draggerContainer {
        display: none;
    }
    .mCSB_scrollTools {
        background: transparent;
        z-index: -1;
    }
}

.salto-network--energy-saving-devices-table {
    table {
        min-width: 100%;
    }
}

.salto-network--network-name {
    min-width: 200px;
}
.fields .field.salto-network_cer-pass {
    display: none;
    .salto-network__cu4k-gateway & {
        display: inline-block;
    }
}

#cu4k-node-bus485-table {
    .table-container {
        min-height: 77px;
        .tbody-wrapper {
            min-height: 40px;
        }
    }
}