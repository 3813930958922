#home1Element{
    button{
        width: 350px;
        height: 350px;
    }
    .button{
      border-style:solid;
        border-width: 1px;
        border-top-color: white; 
        border-left-color: white;
        border-right-color: #e8e8e8;
        border-bottom-color: #e8e8e8;  
    }
}