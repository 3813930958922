.calendars {
    .details-list__container{
        padding-left: 11px;
        padding-right: 11px;
        overflow:hidden;
    }
}

.calendar-holiday{
    color: $color-calendarHoliday !important;
}

.calendar-special1{
    color: $color-calendarSpecial1 !important;
}

.calendar-special2{
    color: $color-calendarSpecial2 !important;
}

.calendar-normal{
    color: $color-calendarNormal !important;
}

.calendar-dstforward {
    color: $keycolor;
}
.calendar-dstbackward {
    color: $active;
}

.calendar-view--block {
    display: block;
    height: calc(100% - 12px);
    margin:0;
    position: relative;
    max-height: 469px;
    .table-footer{
        height: auto;
        ul.button-list{
            &.no-right-margin{
                margin-right: 0;
                margin-top:0;
                margin-bottom: 7px;
                li {
                    margin-right: 7px;
                    margin-left: 0;
                    margin-top: 7px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            &.no-left-margin {
                margin-left:0;
                margin-top:0;
                margin-bottom: 7px;
                li {
                    margin-top: 7px;
                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.calendar-view {
    width: 100%;
    border: 1px solid #dedede;
    border-top-width: 0px;
    border-bottom-width: 0px;
    overflow-y: auto;
    display: block;
    background: $white;
    .calendar-view__head{
        display: block;
        text-align: center;
        h2 {
            display: inline-block;
            margin: 0 16px;
            font-weight: 700;
            font-size: 24px;
            line-height: 21px;
            height: 24px;
        }
        margin-bottom: 0;
        ul.button-list{
            height: 55px;
            padding: 10px;
            li {
                margin:0;
                height: 100%;
                vertical-align: middle;
                display: inline-block;
                &.has-padding-top-7px {
                    padding-top:7px;
                }
            }
        }
    }
    .calendar-view__body{
        display: block;
        padding: 0 12px 12px;
        .calendar-view__month{
            width: 100%;
            display: block;
            height: 1.8em;
            border-right: 1px solid #dedede;
            &.has-top-border {
                border-top: 1px solid #dedede;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                height: 20px;
            }
            &.has-bottom-border {
                border-bottom: 1px solid #dedede;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            .calendar-view__month__name{
                display: inline-block;
                margin: 0;
                width: 40px;
                text-transform: uppercase;
                border: 1px solid #dedede;
                border-top-width: 0;
                background: #fafafa;
                text-align: center;
                font-size: 14px;
                height: 1.9em;
                padding-top:6px;
                white-space: nowrap;
                overflow: hidden;
                &.weeknames {
                    height: 1.6em;
                }
                &.top-row{
                    margin-bottom:-5px;
                    height: 1.8em;
                    border-bottom-width: 0;
                }
            }
            .calendar-view__days{
                display: inline-block;
                width: calc(100% - 40px);
                padding: 2px 5px 0 7px;
                .calendar-view__day{
                    display: inline-block;
                    width: 2.8%;
                    height: 22px;
                    line-height: 1.5em;
                    font-family: $main-font-family;
                    font-weight:200;
                    font-size:11px;
                    border: 1px solid #dedede;
                    margin: 0 0 0 -1px;
                    text-align: center;
                    background: transparent;
                    outline:none;
                    padding:0;
                    &.weekend {
                        background: #dedede;
                        border-color: #dedede;
                    }
                    &:not(.dayname):not(.empty){
                        box-sizing: border-box;
                        line-height: 1.7em;
                        cursor: pointer;
                        > div {
                            width: 100%;
                            height: 100%;
                            .button__gradient{
                                height: 100%;
                                width: 100%;
                                border: 1px solid transparent;
                                border-right-width: 2px;
                                position: relative;
                                &:before{
                                    content: '';
                                    position: absolute;
                                    top: 0; right: 0;
                                    border-left: 6px solid transparent;
                                    width: 0;
                                    visibility: hidden;
                                    border-top: 6px solid $keycolor;
                                }
                            }
                        }
                        &:hover, &:not(.daytype--opened):focus{
                            border-color: $keycolor;
                            > div {
                                .button__gradient{
                                    border-color: $keycolor;
                                    &:before{
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                        &:not(.daytype--opened).selected, &:not(.daytype--opened):active{
                            border-color: $active;
                            > div {
                                .button__gradient{
                                    border-color: $active;
                                    &:before{
                                        border-top-color: $active;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                    }
                    
                    &.daytype--1{
                        background: $color-calendarSpecial1;
                        color: $white;
                        &:not(.selected){
                            border-color: $color-calendarSpecial1;
                        }
                    }
                    &.daytype--2{
                        background: $color-calendarSpecial2;
                        color: $white;
                        &:not(.selected){
                            border-color: $color-calendarSpecial2;
                        }
                    }
                    &.daytype--3{
                        background: $color-calendarHoliday;
                        color: $white;
                        &:not(.selected){
                            border-color: $color-calendarHoliday;
                        }
                    }
                    &.daytype--forward{
                        background: $keycolor;
                        color: $white;
                        &:not(.selected){
                            border-color: $keycolor;
                        }
                    }
                    &.daytype--backward{
                        background: $active;
                        color: $white;
                        &:not(.selected){
                            border-color: $active;
                        }
                    }
                    &.daytype--opened{
                        background:#ffffcc;
                        color: black;
                        border-color: #dedede !important;
                    }
                }
                &.weeknames {
                    margin-left: 0;
                    text-transform: uppercase;
                    font-size: 14px;
                    vertical-align: top;
                    color: #a5a5a5;
                    width: calc(100% - 44px);
                    padding: 0 5px 0;
                    .calendar-view__day{
                        border-width: 0;
                        font-size:14px;
                        height: 1.4em;
                        line-height: 1.3em;
                        &.weekend {
                             background: #929292;
                             color: $white;
                             border-top:1px solid $white;
                             padding-top:1px;
                        }
                        &:not(.weekend){
                            padding-top:2px;
                        }
                    }
                }
            }
        }
    }
}

ul.calendar-view__list{
    margin-top: 16px;
    list-style: none;
    margin-left: 12px;
    li{
        margin-right:23px;
        display: inline-block;
        font-size: 13px;
        font-weight: 200;
        color: black;
    }
}
.list-calendar{
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #929292;
    display: inline-block;
    vertical-align: top;
    margin-right: 7px;
    &:before{
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: top;
        margin-left: 3px;
        margin-top: 3px;
    }
    &.calendar-holiday{
        &:before {background: $color-calendarHoliday;}
    }
    &.calendar-special1 {
        &:before {background: $color-calendarSpecial1;}
    }
    &.calendar-special2 {
        &:before {background: $color-calendarSpecial2;}
    }
    &.calendar-dstbackward {
        border-color: $active;
        &:before {background: $active;}
    }
    &.calendar-dstforward {
        border-color: $keycolor;
        &:before {background: $keycolor;}
    }
}
.calendar__dst{
    vertical-align: top;
    .calendar__dst__info{
        span {
            margin-left: 23px;
            font-weight: 400; 
            &.no-data{
                color: #a5a5a5; 
            }
        }
    }
}
.calendar__dialog{
    width: 420px;
    .calendar__same-as__selects{
        width: calc(100% + 32px);
        padding:12px 12px 0;
        display: inline-block;   
        .calendar-to-copy ~ .select2-container {
	        width: 240px;
        } 
        .year-to-copy ~ .select2-container {
	        width: 80px;
        } 
    }
   
}

.calendar__dst-container{
    position: absolute;
    border: 2px solid black;
    border-radius: 3px;
    //width: 250px;
    //height: 80px;
    background: $white;
    z-index: $dialog-z-index + 10;
    padding: 7px;
    &.show-info{
        display: block;
    }
    .arrow {
        border-color: transparent;
        border-left-width: 12px;
        border-right-width: 12px;
        border-bottom-width: 12px;
        border-bottom-color: black;
        top: -15px;
        left: 113px;
        position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
    }
}