$details-head-height: 55px;

.details, .item-list{
	height:100%;
    .content__body {
        background-image: url(../img/kiosk-locker/a.svg);
        background-position: 100% 450%;
        background-repeat: no-repeat;
        background-size: contain;
        .white-background {
            background: $white;
        }
    }
    .partial-detail-content__body {
        padding: 10px 8px;
    }
}

.details {
    .content__body {
        // Los detail-box también tienen un margin-bottom, 
        // lo que provocaba que al final del contenedor 
        // hubiera el doble de margen
        padding-bottom: 0px;
    }
}

.details-container{
    padding: 16px;
    height: calc(100% - 95px - 55px);
    overflow-y: auto;
    
    .height-total{
        height: 100%;
    }
}

.details-head {
    background: #666666;
    color: white;
    font-size:24px;
    font-weight: 400;
    height: $details-head-height;
    line-height: 37px;
    padding: 9px;
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

detail-box{
    display: block;
    // Para que coja toda la altura del hijo (incluido el margen),
    // si no las directivas que necesitan la altura del detail-box
    // (box-min-height...) no funcionan correctamente (porque no tienen
    // la altura real)
    @include clearfix2;                 
}

detail-box.no-margin-bottom {
    .detail-box {
        margin-bottom: 0;
    }
}

detail-box.no-margin-top {
    .detail-box__content {
        margin-top: 0;
    }
}

ul.detail-box__list-box {
    list-style: none;
    margin: 15px 0;
    padding: 0;
    li{
        display: block;
    }
}

detail-box.no-inside-padding {
    .detail-box .detail-box__content {
        margin: 0;
        padding: 0;
    }
}

detail-box.all-height {
    .detail-box .detail-box__content {
        height: calc(100% - 36px);
    }
}

detail-box.max-height-all {
    .detail-box {
        .detail-box__content {
            max-height: 340px;
            overflow-y: auto;
            margin-right: 0;
        }
    }
}

.detail-box--flex-container{
    @include flexbox;
    .detail-box--flex-container__box{
        @include flex(1 1 auto);
        &.fixed-width{
            @include flex(0 1 auto);       
        }
        &:not(:last-child){
            margin-right: 16px;
        }
        .field--select2-fixed{
            .ctrl{
                max-width: 180px;
            }
        }
    }
}

.detail-box {
    background: $white;
	display: block;
	border: 1px solid $black90;
	border-radius: $detail-box-radius;
	margin: 0 auto 16px auto;
	min-height: 100px;
	width:100%;
    &, * {
        box-sizing: border-box;
    }
    .no-margin-bottom & {
        margin-bottom: 0;
    }
    .no-bottom-padding &{
        .detail-box__content{
            padding-bottom: 0;
        }
    }
    &.detail-box--fix-height{
        height: 95%;
    }
    &.detail-box--high-l{
        min-height: 229px;
    }
    &.detail-box--high-xl{
        min-height: 268px;
    }
    &.detail-box--high-xxl{
        min-height: 359px;
    }
	.detail-box__title{
        position: relative;
		color: $black70;
		width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
		border-bottom: 1px solid $black90;
		padding: 8px 10px 9px 10px;
		text-transform: uppercase;
		background-color: $black97;
        font-weight: 400;
        span {
            color: $default-text-color;
        }
        .pointer{
            cursor:pointer;
        }
        
        & > div {
            overflow: hidden;
            text-overflow: ellipsis;
        }
	}
    .detail-box__footer{
        color: $black70;
		width: calc(100% + 32px);
		border-top: 1px solid $black90;
        border-bottom: 1px solid #e6e6e6;
		padding: 8px;
		text-transform: uppercase;
		background-color: $black97;
        margin-bottom: -4px;
        display: inherit;
        margin-left: -16px;
        height: 54px;
        margin-top: 15px;
        .button-list{
            padding: 0;
            li{
                margin: 0 8px 0 0;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
	.detail-box__content{
        margin: 15px 16px 0 16px;
		padding-bottom: 2px;
		.separator{
			border-bottom: 1px solid $black90;
            margin-bottom: 16px;
		}
        .detail-box__list{
            border: 1px solid $black90;
            border-radius: $detail-box-radius;
            padding-top: 10px;
            padding-bottom: 10px;
            li{
                &:before {
                    vertical-align: top;
                }
                .detail-box__list__container {
                    display: inline-block;
                    max-width: calc(100% - 35px);
                }
            }
            
        }
	}
}

.inner-detail-box{
    background: $white;
	display:inline-block;
	border: 1px solid $black90;
	border-radius: $detail-box-radius;
    margin-bottom: 16px;
	min-height: 100px;
	width:100%;
    &.no-margin-bottom{
        margin-bottom: -1px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .inner-detail-box__title{
        &.first-title{
            border-radius: $detail-box-radius $detail-box-radius 0 0;
        }
		width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
		border-bottom: 1px solid $black90;
		padding: 8px 10px 9px 10px;
		text-transform: uppercase;
		background-color: $black40;
        font-weight: 400;
        text-align: center;
        span, label{
            color: white;
            display:inline;
            font-weight: 600;
            &.disabled, &.disabled-style{
                color: $black70;
            }
        }
	}
    .inner-detail-box__content{
        padding: 16px 8px 8px 8px;
        position: relative;
        // padding-bottom: 2px;
        &.extra-bottom-padding {
            padding-bottom: 10px;
        }
        &.has-fixed-menu{
            padding-bottom: 16px;
        }
        .center{
            text-align: center;
        }  
        &.content-disabled{
            background: $black95;   
        }
        &.has-top-border {
            border-top: 1px solid $black90;
        }
    }
}

.details-list__container {
	padding: 16px 16px 0px 16px;
    height: 100%;
}

.details-list__header{
    background: linear-gradient(to bottom,  #7B7B7B 0%,#9A9A9A 70%); /* W3C */
    padding: 8px 16px 0px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #cccccc;
    height: 92px;
    input[type=text], 
    input[type=search], 
    input[type=number], 
    input[type=password] {
        &[readonly] {
            color: $black90;
        }
    }
    label {
        text-align: right;
    }
    .ctrl {
        overflow: hidden; /* necesario para que el select2 no se salga de su espacio máximo */
    }
}

/*Custom list styles*/
.details-list {
    &:not(.no-padding){
           padding-left: 14px; 
    }
    li {
        list-style: none;
        text-align: left;
        font-size: 15px;
        padding-left: 25px;
        &:before {
            content: "\e69f";
            font-family: icomoon;
            font-size: 27px;
            vertical-align: middle;
            margin-left:-32px;
            line-height: 27px;
        }
        p.message-text{
            display: inline-block;
            padding-top:4px;
            vertical-align: top;
        }
        span{
            vertical-align: middle;
        }
        & > .details-list--container {
            margin-top: 2px;
            vertical-align: text-top;
            @include inline-flex;
            width: 100%;
            &.firefox-fix {
                @include flexbox;
                margin-top: -21px;
                .details-list--label {
                    margin-left: -1px;
                }
            }
            .details-list--label {
                font-weight: 400;
                float: left;
            }
            .details-list--content {
                overflow: hidden;
                padding-left: 8px;
            }
        }
        .list-label{
            margin-left: -8px;
            font-weight: 400;
        }
        .list-label-with-margins {
            margin-right: 4px;
            font-weight: 400;
        }
    }
}

/*Wizard components*/

.wizard__container {
    display: table;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    background: #e1e1e1;
    width: 100%;
    font-size: 0;
    white-space: nowrap;

    .wizard-step-wrapper {
        display: inline-block;
    }

    .wizard__icon {
        display: block;
        font-size: 90px;
        height: 92px;
        color: #f0f0f0;
        width: 90px;
        overflow: hidden;
        margin: 0 7px;
        position: absolute;
        top: 0;
        right: 0;
    }
    .wizard__step {
        font-size: 15px;
        display: block;
        text-align: center;
        height: 92px;
        vertical-align: middle;
        background: #d3d3d3;
        color: white;
        border-right: 1px solid $white;
        &.selected {
            background: $color-info;
            .wizard__step__head{
                visibility: visible;
            }
            .wizard__step__number {
                background: $white;
                color: $color-info;
            }
        }
        .wizard__step__head{
            visibility: hidden;
            border-bottom: 1px solid $white;
            width: 100%;
            height: 4px;
            background: $color-success;
        }
        .wizard__step__text {
            font-size: 0.75em;
            margin: 5px 0 3px 0;
            text-transform: uppercase;
        }
        .wizard__step__number {
            font-size: 22px;
            border-radius: 50%;
            background: #e2e2e2;
            height: 36px;
            width: 36px;
            line-height: 1.7em;
            margin: 0 auto;
            font-weight: 900;
        }
        .wizard__step__title-wrapper {
            width: 100%;
            padding: 2px 20px;
        }
        .wizard__step__title {
            font-size: 18px;
            font-weight: 200;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    
}

/*
 * Full Picker component
 */

full-picker {
    display: inline-block;
    height: 34px;
    &[readonly]{
        .fullpicker{
            input {
                border:none;
                background: transparent;
                &.fullpicker__input{
                    &.has-icon{
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }
            button{
                display: none;
            }
        }
    }
}

.fullpicker {
    display: inline-block;
    .fullpicker__input {
        // padding: 6px 3px;
        padding: 6px;
        text-align: center;
        vertical-align: middle;
        margin-left: 0px !important;
        &.has-icon {
            border-top-right-radius: 0px;
            border-bottom-right-radius:0px;
        }

        &.fullpicker-date {
            width: 86px !important;
        }

        &.fullpicker-time {
            width: 50px;
            margin-left: 5px;

            &.only-hour {
                width: 38px;
            }

            &.bigtime {
                width: 68px;
            }
        }
    }
    button {
        vertical-align: middle;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-left: -3px;
        width: 34px;
        font-size: 16px;
        span[class^="icon-"]{
            margin:0 0 0 -2px;
        }

        &.opened, &:not(:disabled):active {
            .button__gradient{
                background: $active !important;
            }
        }
    } 
}

.fullpicker__datepicker {
    position:absolute;
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding:2px;
    margin-top:2px;
    background: linear-gradient(to top,#FFFFFF 0%, #FCFCFD 16%);
}

/*
 * Modify Input dialog SCSS 
 */
 .macro-list-iterator__warning-container {
     margin-bottom: 15px;
 }

 .macro-list-iterator {
     max-width: 600px;
     .macro-list-iterator__macro-name-column {
         width: 120px;
         &.bigger {
             width: 150px;
         }
     }
 }


.dbbackup__dialog{
    width: 450px;
    padding: 10px;
    .field__explanation{
        padding: 7px 0 0 0;
        font-size: 14px;
        color: $black60;
    }
}
 
 /*
  * Hour Range component
  */
 .hour-range {
     display: block;
     width: 100%;
     margin: 12px auto;
     border-radius: 3px;
     border: 1px solid #e5e5e5;
     background: #ECECEC;
     height: 34px;
     .hour-range__range {
         display: inline-block;
         width: 12%;
         text-align: left;
         font-size:12px;
         color: #BBBBBB;
         padding-top: 9px;
         vertical-align: top;
         float: left;
         margin: 0;
         &:first-child{
             padding-left: 2%;
         }
         &.align-right {
             text-align: right;
             float:right;
             padding-right:5px;
         }
         &.half-width{
             width:6%;
         }
     }
     .hour-range__selected {
         background: rgba($active, 0.13);
         height: 34px;
         border-radius: 3px;
         border: 2px solid rgba($active, 0.85);
         max-width: 100%;
     }
 }
 
 /*
  * Dayset Selector
  */
 
.dayset-selector {
    display: block;
    margin: 0 auto;
    width: 255px;
    .dayset-selector__block{
        display: inline-block;
        margin-right:2px;
        vertical-align: top;
        .dayset-selector__block__button-container {
            float: left;
            button.dayset-selector__block__day {
                vertical-align: top;
                display: inline-block;
                width: 24px;
                height: 35px;
                font-size: 12px;
                font-weight: 600;
                border: 1px solid #e5e5e5;
                border-right-width: 0px;
                cursor: pointer;
                padding:0;
                color: #BBBBBB;
                background: $white;
                font-family: $main-font-family;
                margin:0;
                &.selected {
                    background: $active;
                    border: 1px solid #008469;
                    border-bottom-width: 3px;
                    color: $white;
                    border-radius: 0px;
                    &:first-child{
                        border-radius: 0px;
                    }
                    &:last-child{
                        border-right-width: 1px;
                        border-radius:0px;
                    }
                }
                &:disabled{cursor:default;}
                &:not(:disabled):active, &:not(:disabled):focus, &:not(:disabled):visited{
                    outline:0;
                    &:not(.selected) {
                        color: #ECECEC;
                        background: #BBBBBB;
                        outline:0;
                    }
                }
                &:not(.selected):not(:disabled):hover{
                    &:not(:focus){
                        background: #ECECEC;   
                    }
                }
            }
            &:first-child{
                button.dayset-selector__block__day {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius:3px;
                }
            }
            &:last-child{
                button.dayset-selector__block__day {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius:3px;
                    border-right-width: 1px;
                }
            }
         }
     }
 }
 
 /*
  * TimezoneList component
  */
 timezone-list{
     height: 100%;
     width: 100%;
     display: block;
 }

/*
 * Rooms & Room Detail
 */

.room-type {
    display: inline-block;
    min-width: 16px;
}

#room-associated-devices-box {
    .table-container {
        min-height: 120px; 
        margin-bottom: 16px;
        .tbody-wrapper {
            min-height: 83px;
        }
    }
}
  
.detail-box-filters {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    padding: 6px 20px 0 0;

    button {
        @include filter-button;

        &.active span {
            color: $white !important;
        }
    }
}
