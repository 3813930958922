$filter-field-background: #ccf5ed;
$dark-filter-field-background: #005585;
$dark-filter-label-background: #003f63;
$filter-remove-hover: #3dc6ff;
applied-filters {
	display: block;

	&.inline-blocks .applied-filters {
		.label--wrapper, .filter--wrapper {
            display: inline-block;
        }
	}
}

.applied-filters {
	display: table;
	padding: 0 0 12px 0;
	margin: 0;
	font-size: 14px;
	letter-spacing:-0.31em;

	.flex-filters & {
		@include flexbox;
		@include flex-wrap(wrap);
	}

	.label--wrapper {
		display: table-cell;
		vertical-align: top;
		.flex-filters & {
			display: inline-block;
		}
	}

	.filter--wrapper {
		display: table-cell;
		vertical-align: top;
		overflow: hidden;
		.flex-filters & {
			display: inline-block;
			max-width: 100%;
		}
	}

	.applied-filters__label, .applied-filters__filter {
		display: inline-block;
		vertical-align: top;
		letter-spacing: normal;
		margin: 0 2px 2px 0;
		/* Needed to have the CSS ellipsis at the end follow the same format as
		   the rest of the content. */
		font-weight: 600;
		color: $black40;
	}
	.applied-filters__label {
		background: $black60;
		color: $white;
		text-transform: uppercase;
		height: 33px;
		line-height: 33px;
		padding: 0 12px;
		font-weight: 200;
		white-space: nowrap;
	}
	.applied-filters__filter {
		padding: 0;
		display: inline-block;
		background: $filter-field-background;
		height: 33px;
		line-height: 33px;
		padding: 0 12px;
		vertical-align: middle;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 100%;

		.tooltip-container {
			@include flex-row-container;
		}

		&.clickable:hover {
			cursor: pointer;
			background-color: #B8F2E6;
		}

		.applied-filter__label {
			padding-right: 3px;
			font-weight: 200;
			text-transform: uppercase;
		}

		.applied-filter__value {
			text-overflow: ellipsis;
			overflow: hidden;
			display: inline-block;
			padding-right: 1px;
			&.placeholder-option {
				padding-right: 4px;
			}
		}

		.close-button-wrapper {
			vertical-align: middle;
			padding-left: 4px;
			padding-right: 1px;

			button:focus {
				outline: 1px dotted $active;
			}
		}

		.applied-filter__remove-filter {
			border: none;
			background: transparent;
			padding: 0;
			color: $active;
			font-size: 16px;
			display: inline-block;
			vertical-align: middle;
			&:hover {
				color: $filter-remove-hover;
			}
			& * {
			vertical-align: sub;
			}
		}
	}
}
.applied-filters--dark {
    .applied-filters__label {
        background: $dark-filter-label-background;
		color: white;
    }
    .applied-filters__filter {
		background: $dark-filter-field-background;
		color: white;
    }
}