$main-title-background-color: $black97 !default;
$side-menu-width: 88px;
$content-header-height: 56px;
$status-bar-height: 58px;
.body > .container {
	height: 100%;
}
.content
{
	margin: 16px 16px 0 16px;
	position: relative;
	height: 100%;
	&:empty
	{
		display: none;
	}

	.content__header {
		position: absolute;
		top: 0;
		width: 100%;
		color: $black40;
		background: $main-title-background-color;
		padding-left: 16px;
		margin: 0;
		border: 1px solid $white;
		border-width: 1px 1px 0 1px;
		height: $content-header-height;
		line-height: $content-header-height;
        font-weight: 800;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		@include flex-row-container;

		h1{
            font-family: $main-font-family;
            margin: 0;
            font-weight: 800;
			text-overflow: ellipsis;
			overflow: hidden;
			@include flex(1 1 auto);
			&.trim-with-ellipsis {
				max-width: calc(100% - 60px);
				&.showing-errors {
					max-width: calc(100% - 200px);
				}
			}
		}
		span {
			margin-right: 11px;
			vertical-align: middle;
            &.header-icon {
                font-size: 2em;
                vertical-align: top;
                display: inline-block;
            }
		}
		.content__warning-bar {
			display: inline-block;
			float: right;
			height: 30px;
			margin: 8px 8px 8px 0;

			button {
                vertical-align: top;
				margin-top: 4px;
			}
		}

        .selected-items-message {
            padding-right: 20px;

            a {
                text-decoration: underline;
                color: $keycolor;
            }
        }
	}
	.content__status-bar {
		position: absolute;
		top: 56px;
		height: $status-bar-height;
		background: #c5c5c5;
		box-shadow: inset 0px 4px 4px 0px rgba(0,0,0,0.1);
		left: 0;
		right: 0;
		padding: 0 2px;
		
		.status-bar__group {
			display: inline-block;
			background: transparentize($black60, .75);
			box-shadow: inset 0px 4px 4px 2px rgba(0,0,0,0.1);
			height: 42px;
			margin: 8px;
			padding: 4px;
			border-radius: 4px;
		}
		.status-bar__block {
			float: left;
			margin: 0 0 0 1px;
			height: 34px;
			line-height: 34px;
			vertical-align: middle;
			padding: 0 8px;
			color: white;
			font-weight: 600;
			font-size: 14px;
			
			&.status-bar__block--default {
				background: #565656;
				text-transform: uppercase;
			}
			&.status-bar__block--secondary {
				background: #787878;
				text-transform: uppercase;
				
				[class^="icon-"]:before, [class*=" icon-"]:before, %icon {
					font-size: 16px;
					margin: 0 4px;
					// vertical-align: auto;
				}
			}
			&.status-bar__block--warning {
				background: $color-warning;
				text-transform: uppercase;
				line-height:36px;
			}
			&.status-bar__block--error {
				background: $color-error;
				text-transform: uppercase;
				line-height:36px;
			}
			
			&.status-bar__block--button {
				padding: 0 0 0 8px;
			}
			&.status-bar__block--green-icon {
				>span,
				>.tooltip-container>span {
					color: #9fdf20;
				}
			}

			.status-bar__block--light{
				font-weight: 200;
				margin-right: 4px;
			}
			
			&:first-child {
				margin: 0;
				
				&.status-bar__block--button {
					padding: 0;
				}
			}
			&:last-child {
				padding-right: 0;
			}
			[class^="icon-"]:before, [class*=" icon-"]:before, %icon {
				font-size: 14px;
				vertical-align: middle;
			}

			&.status-bar__block--default--green-icon {
				@extend .status-bar__block--default;
				@extend .status-bar__block--green-icon;
			}

			.icon-warning {
				position: relative;
				bottom: 1px;
			}
		}

		.button-list {
			padding: 0;
			display: inline-block;
			width: auto;
			margin: 0 0 0 8px;
			&:first-child {
				margin-left: 0;
			}

			li {
				margin: 0 0 0 8px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
	.content__body {
		position: absolute;
		padding: 16px 16px 16px 16px;
		background-color: $white;
		top: 56px;
		bottom: 47px;
		width: 100%;
		overflow: auto;
		&.no-padding{
			padding: 0px 1px;
		}
		&.padding-top-16px{
			padding-top:16px;
		}
		&.padding-bottom-16px{
			padding-bottom:16px;
		}
        .content__body__full-height{
            height: 100%;
        }
		.content__body__list{
			width: 200px;
			display: inline-block;
			vertical-align: top;
			float: left;	
		}
		.content__body__detail{
			width: calc(100% - 200px);
			display: inline-block;
			vertical-align: top;
			float: left;
		}
        & > applied-filters{
            padding-top: 10px; 
        }
	}

	.content--has-status-bar .content__body {
		top: $content-header-height + $status-bar-height;
	}
	.content__footer {
		position: absolute;
		bottom: 0px;
		width: 100%;
		@include flexbox;
		@include flex-direction(row);
		@include flex-wrap(nowrap);
		@include justify-content(space-between);
		@include align-content(stretch);
		@include align-items(flex-start);
		
		.button-list {
			padding: 0;
			// flex-grow needed for the button list in FF 45.3
			@include flex(1 1 auto);

			&.right {
				text-align: right;

				& > li:first-child {
					margin-left: 7px;
				}
			}

			&.no-wrap{
				white-space: nowrap;
			}

			.add-buttons {
				white-space: nowrap;
				display: inline-block;
				margin: 0 0 0 7px;

				& > li {
					letter-spacing: normal;
				}
			}

			&.button-list__centered {
				button, .button-group__title {
					vertical-align: middle;
				}
			}

			li {
				display: inline-block;
				margin: 12px 0 0 8px;

				&:first-child {
					margin-left: 0;
				}

				&.minimum-margin {
					margin-left: 1px;
				}
			}
		}
	}
	.content--no-footer {
		.content__body {
			bottom: 24px;
		}
	}

	.content--has-side-menu{
		width: calc(100% - #{$side-menu-width});
		position: absolute;
		.content__header, .content__status-bar {
			position: relative;
			top: 0;
		}
        
		.content__side-layer{
			display: none;
			position: absolute;
			z-index: 1;
			width: calc(100% - #{$side-menu-width});
			background: rgba(233,233,233,0.7);
			top: 0;
			left: 0;
            width: 100%;
			&.show {
				display: block;
			}
		}
	}

	.content__side-container{
		position: absolute;
        width: #{$side-menu-width};
		right: 0;
		ul.content__side-menu {
			display: inline-block;
			list-style: none;
			vertical-align: top;
			margin-top: 15px;
			padding: 0;
			&:hover{
				li:not(:hover):not(.selected) {
					.side-menu__option span{
						opacity: 0.5;
					}
				}
				li:hover {
					.side-menu__option span{
						opacity: 1;
					}
					box-shadow: none;
					background-color: $side-menu-content;
				}
			}
			li {
				color: $white;
				display: block;
				height: $side-menu-width;
				width: $side-menu-width;
				cursor: pointer;
				box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
				display: table;
				position: relative;
                word-wrap: break-word;
				.side-menu__option {
					width: 100%;
                    max-width: $side-menu-width;
					//height: 100%;
					max-height: 100%;
					padding: 0 0.3em;
					text-align: center;
					overflow: hidden;
					display: table-cell;
					vertical-align: middle;
					.side-menu__title{
						display: block;
						width: 100%;
						text-transform: uppercase;
						font-size:12px;
						line-height: 1.1em;
						font-weight: 600;
						/*
						overflow: hidden;
						max-height: 100%;
						*/
					}
					.side-menu__icon {
						cursor: pointer;
						font-size: 29px;
					}
				}
				.side-menu__warning {
					position: absolute;
					background: $color-error-bright;
					border: 2px solid #fff;
					border-radius: 100%;
					width: 23px;
					height: 23px;
					text-align: center;
					line-height: 1.2em;
					top: 5px;
					right: 5px;
				}
				&:first-child {
					border-top-right-radius: 0.2em;
				}
				&:last-child {
					border-bottom-right-radius: 0.2em;
				}
				&:nth-child(odd){
					background-color: $side-menu-option;
				}
				&:nth-child(even){
					background-color: $side-menu-option-dark;
				}
				&.selected {
					opacity: 1;
					box-shadow: none;
					background-color: $side-menu-content;
				}
				&.not-selected {
					.side-menu__option span{
						opacity: 0.5;
					}
				}
			}
			}
		.side-menu__content {
			z-index: 1;
			color: white;
			position: absolute;
			top: 0;
			right: $side-menu-width;
			background-color: $side-menu-content;
			float:right;
			cursor: default;
			min-height: 420px;
			max-width: 870px;
			.menu-content__header {
				background-color: $side-menu-header;
				display: table;
				width: 100%;
				font-size: 32px;
				font-weight: 800;
				height: 56px;
				line-height: 1.8em;
				text-align: center;
				.menu-content__icon {
					float: left;
					background-color: $side-menu-header-icon;
					font-size: 1em;
					padding: 0.1em 0.4em;
					margin-right: 1em;
					height: 56px;
					width: 56px;
					display: table-cell;
					&:before {
						margin-top:2px;
					}
				}
				.menu-content__close {
					cursor: pointer;
					float: right;
					background-color: $side-menu-close;
					font-size: 1em;
					padding: 0.1em 0.4em;
					margin-left: 1em;
					height: 56px;
					width: 56px;
					display: table-cell;

					&:hover {
						background-color: $side-menu-close-hover;
					}
				}
				.menu-content__header__title {
					display: table-cell;
					vertical-align: top;
					text-overflow: ellipsis;
					white-space: nowrap;
					max-width: calc(870px - 112px - 2em);
					overflow: hidden;
				}
			}
			.menu-content__body {
				width: 100%;
				padding: 20px;
				.shorter .table-container {
					min-height: 250px;
					height: 250px;

					.tbody-wrapper {
						min-height: 200px;
					}
				}

                .table-container {
                    min-height: 280px;
                    min-width: 400px;
                    .tbody-wrapper {
                        min-height: 230px;
                        .table-empty {
							color: #80aac2;
                        }
                    }
                }
                .multi-tab-container {
                    width:850px;
					@include flexbox;
					> * {
						@include flex(1 1 auto);
					}
                }
			}
		}
	}
}
.off-canvas-table--xl {
	width: 800px;
	.thead-wrapper, .tbody-wrapper {
		table {
			max-width: 798px;
		}
	}
}
.off-canvas-table--l {
	width: 600px;
	.thead-wrapper, .tbody-wrapper {
		table {
			max-width: 598px;
		}
	}
}
.off-canvas-table--m {
	width: 540px;
	.thead-wrapper, .tbody-wrapper {
		table {
			max-width: 538px;
		}
	}
}
.off-canvas-table--s {
	width: 350px;
	.thead-wrapper, .tbody-wrapper {
		table {
			max-width: 348px;
		}
	}
}
.off-canvas-table__period-column {
	width: 250px;
}
.off-canvas-table__period-column--ampm {
	width: 290px;
}
.off-canvas-table__name-column {
	width: 355px;
}
.select2-dropdown {
	z-index: inherit;
}

.trim-with-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	max-width: 100%;
}

.select2 .select2-image, 
.select2-results__option .select2-image {
	vertical-align: middle;
	margin-right: 5px;	
	display: inline-block;
	width: 16px;
	height: 16px;
}

.loading-spinner {
	height: 26px;
	width: 26px;
	animation-name: spin;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

.flex-footer {
    @include flexbox;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include justify-content(space-between);
    overflow: hidden !important;

    .table-footer__total {
        @include flex(0 0 auto);
    }

    .button-list {
        @include flex(1 1 1px !important);
        min-width: 0;
        text-align: right !important;

        li {
            max-width: 100%;
            margin: 0 !important;
        }

        button {
            max-width: 100%;
            text-align: left;
        }

        .button__gradient {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
