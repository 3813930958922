/**
 * Reverses the order of `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-reverse
 *
 * @param {List} $list - list to reverse
 *
 * @requires sl-to-list
 *
 * @example
 * sl-reverse(a b c)
 * // c b a
 * 
 * @return {List}
 */

@function sl-reverse($list) {
  @if sl-missing-dependencies(sl-to-list) == true { @return null; }

  $length: length($list);
  $end: floor($length / 2);

  @if $length < 2 {
    @return $list;
  }

  @for $i from 1 through $end {
    $tmp: nth($list, $i);
    $list: set-nth($list, $i, nth($list, -$i));
    $list: set-nth($list, -$i, $tmp);
  }

  @return sl-to-list($list);
}

/**
 * @requires sl-reverse
 * @alias sl-reverse
 */
 
@function sl-mirror($list) {
  @return sl-reverse($list);
}