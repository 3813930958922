@mixin flex-row-container
{
    @include flexbox;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include justify-content(flex-start);
    @include align-content(stretch);
    @include align-items(flex-start);
}

.flex-row {
	@include flex-row-container;
}

.flex-item-noresize {
    @include order(0);
    @include flex(0 0 auto);
    @include align-self(auto);
}

.flex-item-resize {
    @include order(0);
    @include flex(1 1 auto);
    @include align-self(auto);
}

.flex-item-resize-nogrow {
    @include order(0);
    @include flex(0 1 auto);
    @include align-self(auto);
}