.webcam-image--dialog{
    width: 340px;
    height: 300px;
    .webcam-image__viewer{
        display: block;
        height: 250px;
        overflow: hidden;
        max-width: 100%;
        .webcam-loader__text{
            color: $black70;
            display: block;
            border: 1px solid $black70;
            border-radius: 4px;
            padding: 16px;
            height: 250px;
            span {
                display: block;
                text-align: center;
                margin-bottom: 16px;
                text-transform: uppercase;
            }
        }
    }
    .webcam-image__footer{
        display: block;
        padding: 15px 0;
        button {
            display: inherit;
            margin: 0 auto;
        }

    }
    .webcam-loader {
        display: block;
        margin: 15% auto;
        animation: roll 1s infinite;
        @keyframes roll {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(360deg);
            }
        }   
    }
    
    #ng-webcam-counter{
        display: none;
        text-align: center;
        position: absolute;
        z-index: 1;
        line-height: 16em;
        font-weight: 700;
        color: white;
        background: black none repeat scroll 0% 0%;
        width: 340px;
        height: 250px;
        opacity: 0.5;
        z-index: 1000;
    }
    #ng-webcam-overlay{
        display: none;
    }

    #crop-image-container{
        height: 100% !important;
    }
}

.user-photo-selection-options{
    display: block;
    .button-list {
        margin: 0 auto;
        display: inherit;
        text-align: center;
        padding: 0;
        li{
            margin-right: 16px;
            margin-bottom: 0;
            vertical-align: top;
        }
    }
    button{
        width: 150px;
        height: 150px;
        vertical-align: top;
        font-family: $main-font-family;
        background: #fafafa;
        font-weight: 800;
        color:#D1D1D1;
        border-style:solid;
        border-width: 1px;
        border-color: #e8e8e8;  
        outline: 0px; 
        padding: 10px;
        border-radius: 4px;
        .icon{
            font-size: 45px;
            height: 60px;
            color: #D1D1D1;
        } 
        span{
            font-size: 24px;
            display: block;
            height: 40px;
            line-height: 1em;
            text-transform: uppercase;
            text-align: center;
        }
        .button__gradient{
            height: auto;
            background: transparent;
        }
        &.smaller-text {
            span:not(.icon) {
                font-size: 16px;
            }
            span.icon{
                height: 55px;
            }
        }
        &:hover:not(:active){
            background-color:#1FAFEB;
            color: white !important;
            .button__gradient{
                background-color: #1FAFEB;
                .icon{
                    color:white;
                }
            }
        }
        &:active, &:focus{
            background-color: $active;
            color: $white;
            .icon{
                color: $white;
            }

        }/*
        &:focus:not(:hover):not(:active) {
            border-style:solid;
            border-width: 1px;
            border-color: $keycolor;
            color: $keycolor !important;
            .button__gradient{
                .icon{
                    color:$keycolor;
                }
                span{
                    text-align: center;
                    padding: 0 3px;
                }
            }

        }
        */
    }
    
    input[type="file"]{
        position: absolute;
        visibility: hidden;
    }
}