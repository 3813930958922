.table-wrapper {
	table {
		border-collapse: collapse;
	}
	&,* {
		box-sizing: border-box;
	}
	td, th {
		white-space: nowrap;
	}
	.table-wrapper__thead {
		.table-wrapper__thead__content {
			overflow: hidden;
			float: left;
		}
		.table-wrapper__thead__scollplace {
			float: left;
			height: 38px;
			background: -moz-linear-gradient(top,  #d5d5d5 0%, #e0e0e0 30%, #e8e8e8 60%, #e0e0e0 100%); /* FF3.6+ */
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d5d5d5), color-stop(30%,#e0e0e0), color-stop(60%,#e8e8e8), color-stop(100%,#e0e0e0)); /* Chrome,Safari4+ */
			background: -webkit-linear-gradient(top,  #d5d5d5 0%,#e0e0e0 30%,#e8e8e8 60%,#e0e0e0 100%); /* Chrome10+,Safari5.1+ */
			background: -o-linear-gradient(top,  #d5d5d5 0%,#e0e0e0 30%,#e8e8e8 60%,#e0e0e0 100%); /* Opera 11.10+ */
			background: -ms-linear-gradient(top,  #d5d5d5 0%,#e0e0e0 30%,#e8e8e8 60%,#e0e0e0 100%); /* IE10+ */
			background: linear-gradient(to bottom,  #d5d5d5 0%,#e0e0e0 30%,#e8e8e8 60%,#e0e0e0 100%); /* W3C */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5d5d5', endColorstr='#e0e0e0',GradientType=0 ); /* IE6-9 */
			border: 1px solid $black80;
			border-width: 0 0 1px 1px;
		}
	}
	.table-wrapper__tbody {
        margin-top: 1px;
		.table-wrapper__tbody__content {
			display: inline-block;
			overflow: hidden;
			float: left;
		}
		.table-wrapper__tbody__scroll {
			position: relative;
			float: left;
			width: 23px;
			background: #e5e5e5;
			.table-scroll__before-button, .table-scroll__after-button, .table-scroll__button {
				position: absolute;
				left: 0;
				right: 0;
			}
			.table-scroll__before-button {
				top: 0;
			}
			.table-scroll__after-button {
				bottom: 0;
			}
			.table-scroll__button:before {
				background: url('../img/icon-scroll-vertical.png') no-repeat center center white;
				left: 3px;
				right: 3px;
				top: 4px;
				bottom: 4px;
			}
			&:before {
				display: block;
				position: absolute;
				left: 3px;
				right: 3px;
				top: 4px;
				bottom: 4px;
				background: #d8d8d8;
				border: 1px solid $black80;
				content: ' ';
			}
		}
	}

	.table-wrapper__horizontal-scroll {
		height: 23px;
		background: #e5e5e5;
		position: relative;

		.table-scroll__before-button, .table-scroll__after-button, .table-scroll__button {
			position: absolute;
			top: 0;
			bottom: 0;
		}
		.table-scroll__before-button {
			left: 0;
		}
		.table-scroll__after-button {
			right: 0;
		}
		.table-scroll__button:before {
			background: url('../img/icon-scroll-horizontal.png') no-repeat center center white;
			left: 4px;
			right: 4px;
			top: 3px;
			bottom: 3px;
		}
		&:before {
			display: block;
			position: absolute;
			left: 4px;
			right: 4px;
			top: 3px;
			bottom: 3px;
			background: #d8d8d8;
			border: 1px solid $black80;
			content: ' ';
		}
	}


	.table-scroll__after-button {
		
	}
	.table-scroll__before-button {
		
	}
	.table-scroll__button {
		cursor: pointer;
		&:before {
			background-color: white;
			display: block;
			position: absolute;
			content: ' ';
			border: 1px solid $black80;
		}
		&:hover:before {
			border-color: $black60;
		}
	}
	.table-empty {
		color: $black70;
		height: 130px;
		text-align: center;
		vertical-align: middle;
		line-height: 130px;
		.table-empty__icon, .table-empty__text {
			line-height: 18px;
			vertical-align: text-top;
		}
		.table-empty__icon {
			padding: 0 8px 0 0;
		}
	}
	.table-wrapper__clean {
		clear: both;
	}

}