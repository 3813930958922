#home7Elements{
    height: 522px;
    display: inline-block;
    
    .primaryButton{
        height: 340px;
        width: 340px;
    }
    .secondaryButton{
        height: 170px;
        width: 170px;
    }
     .twoButtons{
        display: inline-block;
     } 
     .column2{
         width: 344px;
     } 
    
    .button1{  
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
    }
    .button2{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
    } 
    .button3{
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8;
        border-right: solid 1px #e8e8e8;
        
    }    
    .button4{
        border-left: solid 1px white; 
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
    }
    .button5{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
        button{
            width:342px;
        }
    } 
    .button6{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-bottom: solid 1px #e8e8e8;
    }   
    .button7{
        border-left: solid 1px white; 
        border-top: solid 1px white;
    }    
    
    //las clases border y sus divs corresponientes se usan únicamente
    //para pintar los bordes.
    .border1{
        height:170px;
        width:170px;
        border-right: solid 1px #e8e8e8;
        border-top: solid 1px white;
    }
    .border2{
        height:171px;
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8;
    }
}