$header-height: 80px;
$notification-background: #efefef;
$notification-background--error: $color-error;
header {
	&:before {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		content: ' ';
		height: 100%;
		width: 100%;
		background: linear-gradient(to bottom,  rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 100%);
	}
	> div {
		height: $header-height;
	}
    font-weight: 400;
	background: $header-background;
	width: 100%;
	position: relative;
	overflow: hidden;
	.sparkle {
		position: absolute;
		width: 100%;
		height: 100%;
		background: url('../img/header-sparkle.png') right bottom no-repeat;
		right: 0;
		top: 0;
	}
	.container {
		@include clearfix2;
		position: relative;
		.title {
			background: url('../img/salto-logo-white.svg') no-repeat center;
			width: 102px;
			height: $header-height;
			padding: 0;
			margin: 0 0 0 12px;
			float: left;
		}
		.toolbar {
			float: right;
			height: $header-height;
			.options, .mini-options {
				display: inline-block;
			}
			.options {
				height: $header-height;
				vertical-align: middle;
				line-height: $header-height;
				margin-right: 35px;
				a.option, .option button, button.option {
					color: $white;
					cursor: pointer;
					transition: opacity 0.1s linear;
					&:hover, &.hovered {
						color: transparentize($white,.2);
					}
				}
				.option {
					padding: 0 12px 0 16px;
					line-height: 4em;
					display: inline-block;
					vertical-align: middle;
					color: $white;
					text-decoration: none;
					position: relative;
					margin-top:10px;
					float: left;
					span {
						display: inline-block;
						vertical-align: middle;
						line-height: $main-font-size;
						&:before {
							font-size: 26px;
							line-height: 1em;
						}
						&.name {
							margin-right: 6px;
							font-size: 1.1em;
						}
					}
					&:before {
						content: ' ';
						display: block;
						width: 1px;
						height: 53px;
						position: absolute;
						left: 0;
						top: 2px;
						background: linear-gradient(to bottom,  #1090c1 0%, #78c4e8 50%, #45bcda 100%);
					}
					&:first-child {
						&:before {
							display: none;
						}
					}
					&.exit {
						background: transparent;
						border: none;
						overflow: visible;
						line-height: 3.8em;
						padding-right: 11px;
					}
					&.user {
						padding-right: 16px;
						line-height: 3.8em;
					}
				}
				.notifications {
					position: relative;
					padding: 0 4px 0 0;
					margin-top:9px;
					.notifications__toggle-button {
						padding: 0 16px;
						line-height: 57px;
						overflow: visible;
						background: transparent;
						border: none;
						&:focus{
							outline:none;
						}
					}
					.counter {
						height: 22px;
						width: 22px;
						border: 2px solid $white;
						background: $color-error-bright;
						color: white;
						border-radius: 50%;
						line-height: 18px;
						font-size: 12px;
						position: absolute;
						top: 1px;
						right: -13px;
						text-align: center;
						line-height: 1.6em;
						p {
							margin: 0 -46%;
							width: 35px;
							text-align: center;
							&.big-number{
								letter-spacing: -1px;
								margin: 0 -51%;
							}
						}
					}
				}
			}
			.mini-options {
				position: absolute;
				right: 11px;
				top: 5px;
				.mini-option {
					display: block;
					text-align: right;
					margin: 0 -1px 2px 0;
					font-size: 15px;
					a, button {
						cursor: pointer;
						color: rgba(0,0,0,.4);	
						text-decoration: none;
						background: transparent;
						border: none;
						padding: 0 0 0 1px;
					}
					a:hover, button:hover {
						opacity: .6;
					}
				}
			}
		}
	}
	.head__back-svg {
		position: absolute;
		top: -336px;
		right: -450px;
		float:right;
		stroke: white;
		stroke-width: 1;
		stroke-opacity: 0.2;
		fill: transparent;
	}
}

.notifications-panel {
		/* triangulo */
		&:before {
			display: block;
			content: '';
			border: 10px solid $white;
			border-top-color: transparent;
			border-right-color: transparent;
			border-left-color: transparent;
			position: absolute;
			width: 0px;
			top: -20px;
			right: 110px;
		}
		width: 362px;
		position: absolute;
		background: $white;
		border: 1px solid $black80;
		border-radius: 4px;
		line-height: 16px;
		color: $default-text-color;
		display: none;
		h4 {
			text-transform: uppercase;
			padding-bottom: 8px;
			border: 1px solid $black90;
			border-width: 0 0 1px 0;
			font-size: 17px;
			line-height: 16px;
			margin: 10px 12px 8px;
			padding: 0 0 6px 4px;
		}
		.notification-list-container {
			max-height: 280px;
			overflow-y: auto;
			margin: 0 12px 12px 12px;
			.notification-list {
				@extend .no-bullet;
				margin: 0 8px 0 0;
				li {
					position: relative;
					&:last-child {
						.notification {
							margin-bottom: 0;
						}
					}
				}
				a {
					text-decoration: none;
					color: $default-text-color;
				}
				.notification {
					border: 1px solid $notification-background;
					margin: 0 0 8px 0;
					border-radius: 4px;
					display: block;
					width: 100%;
					.notification__icon {
						@extend %icon;
						font-size: 30px;
						line-height: 30px;
						width: 40px;
						display: inline-block;
						text-align: center;
					}
					
					.notification__content {
						display: inline-block;
						vertical-align: middle;
						font-size:16px;
						line-height: 17px;
						margin-top: -2px;
						max-width: 245px;
						margin-left:10px;
						.notification__content__affected-items,.notification__content__acction-text {
							font-weight: 800;
							margin-top: 4px;
							width: 100%;
							overflow: hidden;
						}
						.notification__content__acction-text {
							color: $header-background;
						}
						&.closeable {
							max-width: 218px;
							margin-left: 7px;
						}
					}
					
					a{
						display: block;
						padding: 11px 5px 5px;
						&.not-executable:hover{
							cursor: auto;
						}	
					}
					
					.notification__close {
						@extend %icon;
						@extend .icon-close;
						position:absolute;
						top: 0;
						right: 6px;
						display: inline-block;
						vertical-align: top;
						width: 30px;
						text-align: right;
						font-size: 22px;
						color: $black60;
						cursor: pointer;
						transition: opacity 0.1s linear;
						&:hover {
							color: $black40;
						}
					}
					&.notification--info {
						background: $notification-background;
						border-color: $notification-background;
						.notification__icon {
							color: $black60;
						}
						.notification__content {
							color: $default-text-color;
						}
						&:hover {
							background: $white;
						}
					}
					&.notification--error {
						background: $notification-background--error;
						border-color: $notification-background--error;
						.notification__icon {
							color: #e06666;
						}
						.notification__content {
							color: $white;
						}
						
						.notification__close {
							color: $white;
							transition: opacity 0.1s linear;
							
							&:hover {
								opacity: 0.6;
							}
						}
					}
				}
			}
		}
	}