#home{
    background: #fafafa;
    height: 100%;
    font-size:30px;
    // vertical-align: middle;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);

    button{
        font-family: $main-font-family;
        background: #fafafa;
        font-weight: 800;
        color:#D1D1D1;
        border: solid 10px #fafafa; 
        border: none;
        outline: 0px; 
        padding: 10px;
        .icon{
            font-size: 95px;
        }     
        span{
            display: block;
            font-size:54px;
        }
        
    } 
    .secondaryButton{
            span{
                font-size: 22px;
            }
            .icon{
                font-size: 40px;
            }  
    }   
    button:hover{
        background-color:#1FAFEB;
        color: white !important;
        border-style: solid;
        border-color:white;
        border-width: 10px;
        outline: none;
        outline: 0px;
        padding: 0px;
    }
    
   .left{
       float:left;
   }
   .right{
       float:right;
   }
    
}

.content {
    height: calc(100% - 16px);
}
