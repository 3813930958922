tagged-input {
    display: inline-block;

    &, &.ng-pristine, &.ng-invalid.ng-pristine {
        .tagged-input {
            border-color: $black85 !important;

            &.focused {
                border-color: $active !important;
            }

            &.hovered:not(.focused) {
                border-color: $keycolor !important;
            }
        }
    }
}

tagged-input.ng-invalid:not(.ng-pristine) .tagged-input {
    &, &.focused, &.hovered {
        border-color: $color-error-border !important;
    }
}

.tagged-input {
    @extend input[type=text];
    min-height: 34px;
    height: auto;
    padding: 3px 8px 1px 8px;
    max-height: 100px;
    overflow-y: auto;
    .tagged-input__tag__container{
        display: inline-block;
        .tagged-input__tag {
            display: inline-block;
            background: $black20;
            border: 1px solid $black50;
            color: $black50;
            padding: 2px 7px;
            font-weight: 800;
            font-size: 14px;
            margin: 0 4px 2px 0;
            max-width: 100%;
            span {
                // TODO Esto es un poco número mágico, como por ahora el componente
                // solo se utiliza en un sitio, así está bien, pero el max-width
                // deberá ser el espacio que tenga dentro del componente
                max-width: 204px;
                .has-button & {
                    max-width: 164px;
                }
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                white-space: nowrap;
                vertical-align: middle;
            }
            button {
                border: none;
                background: transparent;
                color: $header-background;
                cursor: pointer;
                margin: 0;
                padding: 0;
                display: inline-block;
                vertical-align: middle;
                padding-top: 1px;
                line-height: 10px;
                &:before {
                    font-size: 15px;
                }
            }
        }
    }
    input {
        border: none;
        background: transparent;
        margin: -4px -8px 0px -8px;
        height: 32px;
    }
}