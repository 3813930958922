#access-point-data-for-ppd {
    #access-points-box {
        .detail-box__content {
            margin-right: 0;
            .col--noresize {
                width: 50px;
            }
            .detail-box__footer {
                width: auto;
            }
        }
    }
    #ppd__content__body {
        > .cols--noresize {
            > .col--noresize {
                width: 240px; 
                padding-left: 16px;
            }
        }
        #applied-filters {
            display: block;
        }
    }
    .access-point-data-for-ppd--first-level-item {
        padding-left: 4px;
        &.access-point-data-for-ppd--item-has-no-children {
            .tree-grid-first-cell {
                margin-left: 20px;
            }
        }
    }
    .access-point-data-for-ppd--second-level-item .access-point-data-for-ppd--second-level-item__checkbox-container {
        margin-left: 26px;
    }
    .access-point-data-for-ppd--item-has-children__ppd-order {
        padding-left: 4px;
    }
    .access-point-data-for-ppd--item-has-no-children__ppd-order {
        padding-left: 24px;
    }
    .content__status-bar .status-bar__block span {
        font-weight: 200;
    }
    .td-check {
        width: 81px !important;
        min-width: 81px !important;
    }
    .tree-grid-first-cell {
        position: relative;
        top: 2px;
    }
}
.change-ppd-language-dialog {
    .ngdialog__content {
        > * {
            display: block;
            text-align: center;
            height: 70px;
            line-height: 70px;
            > .fields {
                display: inline-block;
            }
        }
    }
}
.ppd-firmware-table {
    .ppd-firmware-table--device-column {
        width: 75px;
    }
    .ppd-firmware-table--filename-column {
        width: 210px;
    }
}
