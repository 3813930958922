table {
	.table-filter {
		position: relative;
	}
}
.table-filter__content {
	position: absolute;
	background: white;
	border: 1px solid $black85;
	right: 0;
	top: 30px;
	padding: 12px;
	min-width: 240px;
	.table-filter--close-button {
		padding: 0;
		position: absolute;
		box-sizing: border-box;
		display: block;
		right: -1px;
		width: 26px;
		height: 29px;
		border: 1px solid $black85;
		border-width: 1px 1px 0 1px;
		background: $white;
		top: -29px;
		line-height: 24px;
		font-size: 14px;
		text-align: center;
		cursor: pointer;
		color: $active;
	}
    .select2 {
        min-width: 180px;
		vertical-align: top;
    }
	.field {
		width: 100%;
	}

	&.popup--right {
		.table-filter--close-button {
			right: initial;
			left: -1px;
		}
	}

	&.table-filter__datetime {

		.input-button-ctrl {
			.input-button-ctrl__input {
				line-height: inherit;
			}
		}

		.fullpicker > input:nth-child(4), .fullpicker > input.fullpicker-time {
			/* Ensure that the time input has border-radius: 4px */
			border-radius: 4px !important;
		}

		button[type=submit] {
			border-radius: 4px !important;
		}

		field {
			margin-right: 15px;

			&.no-margin {
				margin-right: 0;
			}
		}
	}

	button.alone-button {
		border-radius: 4px !important;
	}
}
.table-filter__content--altern {
	right: inherit;
	.table-filter--close-button {
		right: -1px;
	}
}

.table-filter-text-combo {
	.input-button-ctrl {
		text-align: right;
	}
}

.table-filter--outer {
	padding: 16px;
	background-color: #F4F4F4;
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
	.table-filter__field{
		&:not(:first-child):not(.no-top-margin){
			margin-top:16px;
		}
		&.fixed-icon {
			white-space: nowrap;
			input{
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				width: calc(100% - 32px);
				height: 35px;
				&::-webkit-input-placeholder {
					font-style: italic;
					font-weight: 200;
				}
			}
			button {
				color: $white;
				font-size: 16px;
				padding: 8px;
				background-color: #A6A6A6;
				border-radius: 0px 3px 3px 0px;
				margin-left: -4px;
				vertical-align: top;
				display: inline-block;
				pointer-events: none;
				height: 35px;
				width: 35px;
				border:none;
				box-sizing: border-box;
				outline: none;
				&.clear-button {
					pointer-events: auto;
					cursor: pointer;
				}
				&:focus{
					padding-top: 7px;
					border: 1px solid $active;
				}
			}
		}
	}
}
.table-container table thead tr th {
	.table-filter--container {
		display: block;
		margin-left: -16px;
		.table-header {
			@include flexbox;
			@include flex-direction(row);
			@include align-items(center);
			
			.table-header-title {
				padding-left: 16px;
				height: 36px;
				@include flex(1 1 auto);
				@include flexbox;
				@include align-items(center);
				overflow: hidden;
			}
			.table-options {
				@include flex(0 0 auto);
				display: block;
				padding-left: 0;
				margin-left: 8px;
				width: auto;
			}
		}
	}
}

.table-filter__content--number{
    .select2{
        width:100px;
        min-width: 100px;
    }
}

.filter-wrapper {
	position: absolute;
	width: 100%;

	* {
		direction: ltr;
	}
}