$hovercolor: #e2eff3;
.autocomplete {
    position: relative;
    input, .autocomplete__button {
        display: inline-block;
    }
    input {
        padding-right: 20px;
    }
    .autocomplete__button {
        position: absolute;
        right: 0;
        height: 30px;//34px;
        background: transparent;
        border: 0;
        width: 25px;
        /*
        @-moz-document url-prefix() {
            margin-left: 0;
            position: relative;
            left: -25px;
        }
        */
        // vertical-align: middle;
        button, button:focus, button:hover{
            height: 34px;
            background: transparent;
            border: 0;
            width: 25px;     
            outline: none;
            color: black;
            [class^="icon-"] {
                font-size: 10px;
            }
        }
    }
}
.autocomplete-popup {
    .select2-container {
        width: 100%;
        .select2-dropdown {
            border-top: 1px solid $active;
        }
        .select2-results__option:not(.empty-option) {
            cursor: pointer;
        }
        .select2-results__option--selected {
            background: $hovercolor;
            
            div {
                background: $hovercolor;
            }
        }
    }
    
    li div {
        display: inline-block !important;
    }
}

filter-autocomplete, salto-autocomplete {
    display: inline-block;
    height: 35px;

    &.ng-invalid:not(.ng-dirty) input[type=text] {
        border-color: $black85;

        &.hovered, &:hover {
            border-color: $keycolor;
        }

        &.focused, &:focus {
            border-color: $active;
        }
    }

    .autocomplete {
        white-space: nowrap;
    }
}