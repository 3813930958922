/* Have a certain number of columns such that some of them take only the space
   they need while the rest take up all of the remaining space. */
.cols--noresize {
    @include flexbox;
    @include flex-direction(row);
    @include align-items(flex-start);
    @include flex-wrap(nowrap);
    &.align-items-stretch{
        @include align-items(stretch);
    }
    &.align-items-center{
        @include align-items(center);
    }
    &.space-between {
        @include justify-content(space-between);
    }
    
    // If no behavior is specified, content columns will grow automatically
    >* {
        @include flex(1 0 0px);
        overflow: hidden;
    }

    > .col--resize {
        @include flex(1 1 auto);
    }
    
    > .col--noresize {
        @include flex (0 0 auto);
    }
   
    // Add some padding in form fields so that they don't get too close
    > .fields.col--noresize {
        padding-right: 25px;
    }
    
    > :last-of-type.fields.col--noresize {
        padding-right: 0;
    }
}