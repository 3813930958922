label {
	display: block;
	margin-bottom: 6px;
    text-align: left;
	padding-right: 1px;
	&.inline {
		display: inline !important;
	}

    &.center-spinner-label {
        margin: 8px 0;
    }

	&.disabled {
		@include opacity(1);
		color: $black60;
	}
	&.label--ellipsis{
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

label, .form-label {
	font-weight: 400;
	color: #666666;
	
	.dark & {
		color: $white;
	}
}

strong {
	font-weight: $bold-weight;
}

.checkbox-label {
    font-weight: normal;
    color: $default-text-color;
    display: inline;
}

.macros{
	&:not(.no-border){
		border: 1px solid #e6e6e6;
		border-radius: 3px;	
		padding: 8px;
		// width:87%;
	}
	margin-right: 5px;
	color: $black60;
    margin-top:4px;
	font-weight: 200;
}
