/**
 * Initialize an empty comma-separated list.
 * 
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-comma-list
 *
 * @example
 * sl-comma-list()
 * // ()
 *
 * @return {List}
 */

@function sl-comma-list() {
  @return zip((), ());
}