.same-as{
    width: 415px;
    .same-as__table {
        height: 340px; 
        .tbody-wrapper table {
            table-layout: fixed;
        }
    }
    .content-footer .field {
        width: 100%;
    }
}
