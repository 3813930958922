$standard_color: #676767;
$autooffice_color: #CFC000;
$autotoggle_color: #0092CF;
$keypin_color: #CF5900;
$keypad_color: #CF0066;
$office_color: #6BCF00;
$toggle_color: #0045CF;
$twoperson_color: #CF45CF;

.opening-mode-schedule-list-block{
    display: block;
    height: 100%;
}
.opening-mode-schedule-list{
    width: 100%;
    border: 1px solid #cccccc;
    border-top-width: 0px;
    border-bottom-width: 0px;
    padding: 12px 7px;
    height: calc(100% - 68px);
    max-height: 461px;
    overflow-y: auto;
    overflow-x: hidden;
    .opening-mode-schedule-list__row{
        width: 100%;
        height: 40px;
        margin-bottom: 4px;
        white-space: nowrap;
        &:last-child{
            margin-bottom: 0;
        }
        .opening-mode-schedule-list__day{
            line-height: 2.2em;
            padding-left:10px;
            border: 1px solid #cccccc;
            border-right-width: 0px;
            border-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            display: inline-block;
            width: 155px;
            text-align: right;
            text-transform: uppercase;
            padding-right: 14px;
            white-space: nowrap;
        }
        .opening-mode-schedule-list__mode{
            width: calc(100% - 242px);
            display: inline-block;
            margin-left:-3px;
            &.read-mode{
                width: calc(100% - 155px);
            }
        }
        .button-list{
            display: inline;
            margin-left:8px;
        }
    }
}

.opening-mode-schedule__dialog{
    width: 522px;
    &.om--edit{
        height: 442px;
        width: 548px;
    }
    &.om--same-as{
        display: block;
        width:552px;
    }
    
    .opening-mode-schedule__edit__table{
        height: 308px;
        display: block;
        width: calc(100% + 30px);
        margin-left: -15px;
        &.full-height{
            height: 407px; 
        }
    }

    .table-container{
        min-height: 0;
        .tbody-wrapper, .thead-wrapper{
            min-height: 0;
            table{
                table-layout: fixed;
                width: calc(100% - 1px);
                thead {
                    tr{
                        th{
                            &.no-right-border {
                                border-right-color: transparent;
                            }
                            &.dummy-resizable-column{
                                border-color:transparent;
                            }
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            border-right: 1px solid #cccccc;
                            border-bottom: 1px solid #cccccc;
                            white-space: nowrap;
                            &:last-child{
                                border-right-color: transparent;
                            }
                            &.no-right-border {
                                border-right-color: transparent;
                            }
                            &.padding-right-less{
                                padding-right: 8px;
                            }
                            &.td--inner-full-width{
                                span.td-wrapper{
                                    width: 100%;
                                }
                            }
                        }
                        &:last-child{
                            td{
                                border-bottom-color:transparent;
                            }                     
                        }
                        .button-list{
                            white-space: nowrap;
                            display: block;
                            button {
                                .button__gradient{
                                    width: 34px;
                                }
                            }
                            &.no-padding{
                                li{
                                    margin-bottom:0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .opening-mode-schedule__same-as__selects{
        width: calc(100% + 32px);
        margin-top:-16px;
        padding:12px 0 0;
        background: #e9e9e9;
        display: inline-block;
        white-space: nowrap;
        .field{
            &.field--padding-sides-10{
                padding-left: 10px;
                padding-right: 10px;
                label {
                    @include flex(0 0 auto);
                    margin-right: 7px;
                    &.margin-left--7 {
                        margin-left: 7px;
                    }
                }
                .opening-mode-schedule__select-container {
                    padding-left: 0; 
                    min-width: 180px;
                }
            }
        }
    }
    .opening-mode-schedule__same-as__open-mode{
        margin-top:16px;
        display: block;
        width: 101%;
        margin-left:-0.5%;
    }
    .opening-mode-schedule__edit__open-mode{
        margin-bottom:12px;
        display: block;
        overflow:hidden;
        margin-top: 5px;
    }
    .opening-mode-schedule__edit__side-border {
        height: 57px;
        width: 7px;
        display: inline-block;
        float: left;
        margin: 0 7px 0 0;
    }
    .opening-mode-schedule__edit__dates{
        display: inline-block;
        height: 100%;
        padding-top: 12px;
    }

    .opening-mode-schedule__edit__field-container {
        display: block;
        width: calc(100% - 2px);
        margin-left: -16px;
        background: linear-gradient(to bottom, #7B7B7B 0%, #9A9A9A 25%,#7B7B7B 100%);
        padding: 12px 19px;
        bottom:53px;
        position: absolute;
        z-index: 1;
        h2{
            text-transform: uppercase;
            color: $white;
            padding-bottom: 7px;
            font-size:18px;
            font-weight: 800;
        }
        input {
            width: 100%;
            margin-bottom: 12px;
        }
        .fields {
            margin-top:6px;
            margin-bottom: 5px;
            margin-left:-5px;
        }
        .button-list{
            display: inline-block;
            margin-left:20px;
            vertical-align: top;
            button {
                .button__gradient{
                    width: 34px;
                    padding-top:1px;
                    span.icon-add{
                        margin-left: -1px;
                    }
                }
            }
        }
    }

}

.opening-mode-schedule{
    width: calc(100% + 1px);
    border-radius: 5px;
    color: white;
    height: 35px;
    display: block;
    overflow: hidden;
    .opening-mode-schedule__range{
        display: inline-block;
        padding-left: 7px;
        line-height: 2.4em;
        border-left: 1px solid white;
        height: 100%;
        overflow:hidden;
        white-space: nowrap;
        font-weight: 600;
        cursor:default;
        &.mode__default {
            width: 100%;
        }
        &:first-child{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-left-width: 0px;
        }
        &:last-child{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        &:hover {
            box-shadow: inset 0 0 200px rgba(black, 0.3);
        }
        &.no-padding{
            padding-left:0;
        }
    }
}

.om-tooltip{
    display: block;
    width: 165px;
    padding: 1px;
    background: black;
    position: absolute;
	z-index: $tooltip-z-index;
	color: white;
	font-size: 11px;
    border-radius: 3px;
	line-height: 11px; // Vertical centering.
	//font-family: Verdana, Geneva, sans-serif;
    .om-tooltip__title{
        width: 100%;
        display: table;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        white-space: normal;
        word-break: break-word;
        padding: 7px 9px;
        line-height: 1.2em;
        min-height: 35px;
        span {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .om-tooltip__dates {
        text-align: center;
        line-height: 2.2em;
        font-weight: 600;
        font-size: 1.2em;
        color: $white;
    }
}

.om-tooltip-arrow {
    border-color: transparent;
    border-left-width: 12px;
    border-right-width: 12px;
    border-top-width: 12px;
    border-top-color: black;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
}

.mode__color-0{
    background: $standard_color;
}
.mode__color-1{
    background: $office_color;
}
.mode__color-2{
    background: $toggle_color;
}
.mode__color-3{
    background: $autooffice_color;
}
.mode__color-4{
    background: $autotoggle_color;
}
.mode__color-5{
    background: $keypad_color;
}
.mode__color-6{
    background: $keypin_color;
}
.mode__color-7{
    background: $twoperson_color;
}