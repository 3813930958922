#progress-bar {
    margin: 20px 0;
    .progress-bar--container {
        height: 27px;
        width: 440px;
        box-shadow: 0px 0px 0px 3px #e4e4e4;
        border-radius: 10px;
        border: 1px solid #cecece;
        position: relative;
        background-color: #ededed;
        background: linear-gradient(to bottom, #cecece 0%,#ededed 100%);
        .bar {
            border-radius: 10px;
            width: 0%;
            top: -1px;
            bottom: -1px;
            position: absolute;
            background-color: #045feb;
            background: linear-gradient(to right, #50e3fc 0%,#045feb 100%);
        }
    }
    p {
        color: #999999;
        text-align: center;
    }
}
