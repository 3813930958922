// Primary Button

@include create-button("button-primary",
/* background */		$primary-background-color, $primary-background-color--hover, $primary-background-color--hover,
/* border-color*/		$primary-border-color,
/* icon-color */		$primary-text-color, $primary-text-color, $primary-text-color,
/* text-color */		$primary-text-color);

// Secondary Button
@include create-button("button-secondary",
/* background */		$secondary-background-color, $secondary-background-color--hover, $secondary-background-color--pressed,
/* border-color*/		$secondary-border-color,
/* icon-color */		$secondary-icon-color, $secondary-icon-color--hover,$secondary-icon-color--focus,
/* text-color */		$secondary-text-color);

// Secondary button in status bar
$secondary-status-background-color: $black20;
$secondary-status-border-color: $black10;
@include create-button("button-secondary-status",
/* background */		$secondary-status-background-color, $secondary-background-color--hover, $secondary-background-color--pressed,
/* border-color*/		$secondary-status-border-color,
/* icon-color */		$secondary-icon-color, $secondary-icon-color--hover,$secondary-icon-color--focus,
/* text-color */		$secondary-text-color);

// Pagination Button
@include create-button("button-pagination",
/* background */		$pagination-background-color, $pagination-background-color--hover, $pagination-background-color--pressed,
/* border-color*/		$pagination-border-color,
/* icon-color */		$pagination-icon-color, $pagination-icon-color--hover, $pagination-icon-color--focus,
/* text-color */		$pagination-text-color);

// Error Button
@include create-button("button-error",
/* background */		$errorbutton-background-color, $errorbutton-background-color--hover, $errorbutton-background-color--pressed,
/* border-color*/		$errorbutton-border-color,
/* icon-color */		$errorbutton-icon-color, $errorbutton-icon-color--hover, $errorbutton-icon-color--focus,
/* text-color */		$errorbutton-text-color);

// Warning Button
@include create-button("button-warning",
/* background */		$warningbutton-background-color, $warningbutton-background-color--hover, $warningbutton-background-color--pressed,
/* border-color*/		$warningbutton-border-color,
/* icon-color */		$warningbutton-icon-color, $warningbutton-icon-color--hover, $warningbutton-icon-color--focus,
/* text-color */		$warningbutton-text-color);

//Settings Buttons
@include create-button("button-settings",
/* background */		$settingsbutton-background-color, $settingsbutton-background-color--hover, $settingsbutton-background-color--pressed,
/* border-color*/		$settingsbutton-border-color,
/* icon-color */		$settingsbutton-icon-color, $settingsbutton-icon-color--hover, $settingsbutton-icon-color--focus,
/* text-color */		$settingsbutton-text-color);

.button-white {
    height: 35px;
    width: 35px;
    color: #999999;
    background-color: #ffffff;
    border: 1px solid #c0c0c0;
    outline: none;
    &:hover, &:focus {
        background-color: #e6e6e6;
    }
    &:active {
        color: #dedede;
        background-color: #c0c0c0;
    }
    &:not(.button-round){
        border-radius: 4px;
    }
    &:disabled{
        border-color: $black70;
        background: $black80;
        color: transparentize($color: $white, $amount: 0.3);
        cursor:default;
        [class^="icon-"], [class*=" icon-"] {
            color: transparentize($color: $white, $amount: 0.3);
            .dark & {
                color: transparentize($white, .85);
            }
        }
    }
}

.button-transparent {
    background: transparent;
    border: none;
    outline: none;
}
