.clearfix {
  *zoom: 1;
  &:before {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    display: table;
    content: "";
    line-height: 0;
    clear: both;
  }
}

.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.date-picker-date-time {
  position: absolute;
}

.date-range .date-picker-date-time {
  position: inherit;
}

[date-picker-wrapper] {
  position: absolute;
  min-width: 220px;
  z-index: 10;
  display: block;
  font-size: 14px;
}

[date-time-append] {
  [date-picker-wrapper] [date-picker] {
    margin-top: -30px;
  }
  [date-picker] {
    position: relative;
    margin-right: -1000px;
    margin-bottom: -1000px;
  }
}

[date-range] [date-picker] .after.before {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #499dcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f6ab4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f6ab4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f6ab4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f6ab4);
  background-image: linear-gradient(to bottom, #5bc0de, #2f6ab4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff2f6ab4', GradientType=0);
  border-color: #2f6ab4 #2f6ab4 #1f4677;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  *background-color: #2f6ab4;
  /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  &:hover, &:active, &.active, &.disabled, &[disabled] {
    color: #ffffff;
    background-color: #2f6ab4;
    *background-color: #2a5ea0;
  }
  &:active, &.active {
    background-color: #24528c \9;
  }
}

[date-picker] {
  &.hidden {
    display: none;
  }
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 400;
  /* GENERAL */
  padding: 4px;
  /* SPECIFIC */
  table {
    margin: 0 0 4px;
    height: 174px;
    width: 179px;
  }
  td, th {
    //padding: 2px 5px;
    text-align: center;
    //width: 20px;
    //height: 20px;
    //-webkit-border-radius: 4px;
    //-moz-border-radius: 4px;
    //border-radius: 4px;
    border: none;
    font-size:11px;
  }
  .switch {
    width: 145px;
    font-size: 20px;
    text-transform: capitalize;
  }
  span {
    display: block;
    width: 23%;
    //height: 26px;
    //line-height: 25px;
    float: left;
    margin: 0.5%;
    cursor: pointer;
    /*
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    */
    &:hover {
      background: #eeeeee;
    }
    &.disabled {
      background: none;
      color: #999999;
      cursor: default;
      &:hover {
        background: none;
        //color: #999999;
        cursor: default;
      }
    }
  }
  tr.pickerhead {
      background: linear-gradient(to bottom,  #D5D5D5 0%,#E0E0E0 30%, #E8E8E8 60%, #E0E0E0 100%);
      color: $black20;
      border-radius: 0px;
      height: 40px;
      th {
          border-radius: 0px;
      }
      cursor: pointer;
  }
  .active, .now {
      /*
    color: #ffffff;
    background-color: #006dcc;
    background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
    background-image: -o-linear-gradient(top, #0088cc, #0044cc);
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #0044cc;
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */
    /*
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    color: #fff;
    */
    background: $active;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .active:hover, .now:hover, .active:active, .now:active, .active.active, .now.active, .active.disabled, .active[disabled] {
    /*
    color: #ffffff;
    background-color: #0044cc;
    *background-color: #003bb3;
    */
    background: $active;
  }

  .active:active, .now:active, .active.active {
    //background-color: #003399 \9;
    background: $active;
  }
  .now {
    &.active {
      //background-color: #003399 \9;
      background: $active;
      color: $black20;
    }
    color: $white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background: lightgrey;
    /*
    background-color: #ee735b;
    background-image: -moz-linear-gradient(top, #ee5f5b, #ee905b);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#ee905b));
    background-image: -webkit-linear-gradient(top, #ee5f5b, #ee905b);
    background-image: -o-linear-gradient(top, #ee5f5b, #ee905b);
    background-image: linear-gradient(to bottom, #ee5f5b, #ee905b);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffee5f5b', endColorstr='#ffee905b', GradientType=0);
    border-color: #ee905b #ee905b #e56218;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    *background-color: #ee905b;
    */
    /* Darken IE7 buttons by default so they stand out more given they won't have borders */
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    &:hover, &:active, &.active {
      /*
      color: #ffffff;
      background-color: #ee905b;
      *background-color: #ec8044;
      */
      background: $active;
    }
    
    &.disabled, &[disabled] {
      background: lightgrey;
    }
  
    &:active, &.active {
      background-color: rgba($active, 0.6);
    }
  }
  .disabled {
    background: none;
    color: #999999 !important;
    cursor: default;
    opacity: 1;
  }
  [ng-switch-when="year"] span, [ng-switch-when="month"] span, [ng-switch-when="minutes"] span {
    height: 42px;
    line-height: 4em;
    border: 1px solid transparent;
  }
  [ng-switch-when="date"] {
    span {
      width: 100%;
      padding: 2px 5px;
      line-height: 1.6em;
      border: 1px solid transparent;
      &.disabled {
        pointer-events: all;

        &:hover {
          background-color: rgba($active, 0.5);
        }
      }
    }
  }
  th:not(.dayname):hover, td span:hover {
    background-color: rgba($active, 0.5);
    cursor: pointer;
    border: 1px solid $active;
  }
  th.dayname.switch:hover, th.dayname.arrow:hover{
      cursor: pointer;
      opacity: 0.7;
  }
}
