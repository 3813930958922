#home4Elements{
    height: 350px;
    display: inline-block;
   button{
       
       width: 350px;
       height: 350px;
   }
   .twoButtons{
       display: inline-block;
       button{
           display: block;
           width: 175px;
           height: 175px;
        } 
   }
   .button1{  
        border-right: solid 1px #e8e8e8;
        float: left;
    }
    .button2{
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8; 
        border-right: solid 1px #e8e8e8;
    } 
    .button3{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
    }    
    .button4{
        border-left: solid 1px white; 
    }    
}