/**
 * Returns last element of `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-last
 *
 * @param {List} $list - list to retrieve last value from
 *
 * @throws Cannot find last item of empty list.
 *
 * @requires sl-is-empty
 *
 * @example
 * sl-last(a b c)
 * // c
 * 
 * @example
 * sl-last(a)
 * // a
 *
 * @example
 * sl-last(())
 * // null
 * 
 * @return {*}
 */
 
@function sl-last($list) {
  @if sl-missing-dependencies(sl-is-empty) == true { @return null; }

  @if sl-is-empty($list) {
    @warn "Cannot find last item of empty list.";
    @return null;
  }

  @return nth($list, -1);
}