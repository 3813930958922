.broker-test-key {
    margin: 16px 0;
    input#password {
        width: 100%;
    }
    .warning-label-border {
        margin: 0 1px;
        .warning-label-content {
            width: 350px;
            margin: 0 auto;
        }
    }
}
