.select2-selection--multiple {
  background: linear-gradient(to bottom,  rgba(0,0,0,.1) 0%,rgba(255,255,255,0.05) 40%,rgba(255,255,255,0.05) 100%); /* W3C */
  background-color: white;
  border: 1px solid $black85;
  border-radius: 4px;
  cursor: text;
  padding: 0 5px 0 7px;

  .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 4px 0 0;
    width: 100%;
  }

  .select2-selection__placeholder {
    color: #999;

    margin-top: 5px;

    float: left;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
  }

  .select2-selection__choice {
    /*
    background-color: #e4e4e4;

    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;

    float: left;

    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
    */
    background: $black20;
    border: 1px solid $black50;
    color: $black60;
    padding: 2px 6px 2px 7px;
    font-weight: 800;
    font-size: 14px;
    margin: 0 4px 2px 0;
    max-width: 204px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    float:left;
    span {
      display: inline-block;
      height: 14px;
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
    }
  }

  .select2-selection__choice__remove {
    /*
    color: #999;
    cursor: pointer;

    display: inline-block;
    font-weight: bold;

    margin-right: 2px;

    &:hover {
      color: #333;
    }
    */
    margin-top: 1px;
    font-weight: 800;
    border: none;
    background: transparent;
    color: $header-background;
    cursor: pointer;
    margin-left: 7px;
    padding: 0 1px 1px 0;
    display: inline-block;
    vertical-align: middle;
    padding-top: 1px;
    line-height: 10px;
    float: right;
    &:before {
        font-size: 15px;
    }
    // z-index: 1 ensures that it's shown above other items and thus we can
    // click it when we have long names with ellipsis.
    position: relative;
    z-index: 1;

    &:focus {
      outline: 1px lighten($active, 50%) dotted;
    }
  }

  &:hover {
    border-color: $keycolor;
  }
}

&[dir="rtl"] {
  .select2-selection--multiple {
    .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
      float: left;
    }

    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}

&.select2-container--focus {
  .select2-selection--multiple {
    border-color: $active;
    outline: 0;
  }
}

&.select2-container--disabled {
  .select2-selection--multiple {
    background-color: $black90;
    cursor: default;
    color: $black60;
    
    .select2-selection__arrow b {
      color: $black60;
    }
  }

  .select2-selection__choice__remove {
    display: none;
  }
}

.fake-multiple-select2-placeholder {
   display: inline-block;
   visibility: hidden;
   font-style: italic;
}