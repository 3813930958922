.user-photo-div {
    padding-right: 25px;
    display: inline-block;
    vertical-align: top;
}
    
.user-photo-wrapper {
    position: relative;
    width: 120px;
    height: 150px;
    border: 1px solid $black90;
    text-align: center;
    padding: 4px;
    
    .spinner-wrapper {
        @include flexbox;
        @include flex-direction(row);
        @include flex-wrap(nowrap);
        @include justify-content(center);
        @include align-content(center);
        @include align-items(center);
        height: 100%;
        img {
            @include order(0);
            @include flex (0 1 auto);
            @include align-self(auto);
        }
    }
    
    .user-photo {
        width: 110px;
        height: 140px;
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        .user-photo--img {
            z-index: 0;
            width: 110px;
            height: 140px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    .user-photo-cover {
        width: 110px;
        height: 140px;
        z-index: 1;
        opacity: 0.8;
        color: #FFF;
        background-color: #1FAFEB;
        position: absolute;
        top: 4px;
        left: 4px;
        opacity: 0; // hide until :hover or .force-show
        padding: 0 28px;
        @include flexbox;
        @include flex-direction(column);
        @include flex-wrap(nowrap);
        @include justify-content(center);
        @include align-items(center);
        
        .photo-separator {
            margin: 3px 0;
            border-bottom: 1px solid $black90;
            width: 100%;
        }
        
        .max-size-letters {
            text-transform: uppercase;
            max-width: 100%; // prevent nowrap behavior in IE
            font-size: 80%;
        }
        
        .max-size-amount {
            font-size: 120%;
            font-weight: $bold-weight;
        }
        
        .photo-icons {
            height: 58px;
            margin-top: 4px;
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(center);
            @include align-content(center);
            font-size: 150%;
            
            span.icon:hover {
                color: #9FE6F9;
            }
            
            div {
                &:first-of-type
                {
                    margin-bottom: 2px;
                }

                position: relative;
                text-align: center;
                
                input[type=file] {
                    visibility: hidden;
                    position: absolute;
                    width: 0;
                    height: 0;
                }

                label {
                    text-align: center;
                    color: #FFF;
                    margin: 0;
                }

                button {
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    color: #FFF;
                    border: none;

                    &:focus .icon {
                        opacity: 0.8;
                    }

                    &:active, &:hover, &:focus {
                        background: transparent;
                        border: none;
                        outline: none;
                    }
                }
            }
        }
    }
    
    .user-photo--error {
        height: 100%;
        width: 100%;
        padding: 10px;
        background-color: #C00;
        color: #FFF;
        display: flex;
        @include flex-direction(column);
        @include justify-content(center);
        
        .message {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10px;
        }
        
        .icon {
            font-size: 30px;

            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
    
    &:hover .user-photo-cover, .user-photo-cover.force-show {
        opacity: 0.8;
    }
    
    .banned {
        position: absolute;
        top: 12px;
        left: 0;
        height: 24px;
        font-size: 0;
        overflow: hidden;
        
        > * {
            vertical-align: top;
            display: inline-block;
        }
        
        .banned-text {
            text-transform: uppercase;
            color: #FFF;
            font-size: 14px;
            font-weight: 600;
            background-color: #C00;
            padding: 4px 2px 4px 8px;
            max-width: 110px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.ctrl {
    .button-secondary, .button-error {
        margin: 0 15px;
    }
}
.field--xl-except-button {
    width: #{$field-xl-size - 38px};
}
.fields.field-below-tabs {
    letter-spacing: normal;
    text-align: center;
    margin: 0 5px 5px 0; // cancel previous margins
    padding-top: 4px;
    background-color: $side-menu-header;
    
    field {
        margin-bottom: 0;
    }
    
    label {
        color: #FFF;
    }
    
    .select2-selection {
        text-align: left;
        color: #666;
    }
}

.fields.vertical--radios {
    margin-left: -13px;
}

.fields.update-periods {
    margin-left: 0;

    .update-period-label-wrapper, .update-period-radio-wrapper {
        display: inline-block;
        vertical-align: top;
    }

    label[for=updatePeriod] {
        margin-bottom: 10px;
    }
}

// This div is inside a grid, but fails to use a large portion of the container div.
// Let's give it 30 extra pixels to make up for that. 
.calendar--div {
    width: calc(50% + 30px);
}

.status-bar__block .valid-until {
    font-weight: 200;
}

.pin-field {
    text-align: left !important;
}

.pincode{
    margin-bottom: 16px;
    margin-left: 16px;

    .key-and-confirm-key-container input{
        width: $field-s-size;
    }

    .defined-key-container{
        width: $field-s-size+39px;
    }

    // input{
    //     display:block;
    //     margin-left: 10px;
    // }
    // label{
    //     text-align: right;
    //     margin-top: 6px;
    //     margin-left:20px;
    // }
    // .confirmation-label{
    //     margin-top: 30px;
    //     &.label-hidden{
    //         visibility: hidden;
    //         margin-top: -23px;
    //         margin-bottom: 0px;
    //     }      
    // }
    // .confirmation-input{
    //     margin-top: 12px;
    // }
    // white-space: nowrap;
    // .key-inputs{
    //     display: block;
    //     margin-left: 10px; 
    //     input:not(.key-defined){
    //         display: block;
    //         margin-right: 0px;
    //     }
    // }
    // label{
    //     text-align: right;
    //     margin-top: 6px;
    // }
     
    // input{
    //     width:100%;
    // }
   
    // .confirmation-label{
    //     margin-top: 30px;
    //     &.label-hidden{
    //         visibility: hidden;
    //         margin-top: -23px;
    //         margin-bottom: 0px;
    //     }
    // }
    // .confirmation-input{
    //     margin-top: 12px;
    // }
}

/***********************************
KEYS
***********************************/
.keys--centered-content {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include flex-direction(column);
    @include align-items(center);
}
.keys--dialog-message {
    color: $black60;
}
.edit-user-key {
    @extend .keys--centered-content;
    width: 400px;
    margin-top: 8px;
    margin-bottom: -10px;
    .edit-user-key--mobile-notification-message-field {
        width: 100%;
        textarea {
            height: 80px;
            width: 270px;
        }
    }

    .date {
        display: inline-block;
        padding-top: 4px;
        color: $black60;
    }

    .key-operation--separator {
        display: inline-block;
        margin: 18px 0 14px 0;
    }
    
    .selected-encoder .check-in-selected-encoder-label {
        max-width: 320px;
    }
}

.center-select {
    .select2 {
        position: relative;
        top: 6px;
    }
}

.user-notification-message {
    height: 120px;
    margin-right: 4px;
    & + button {
        vertical-align: bottom;
    }
}
.user-detail {
    .authorization-code-button {
        vertical-align: bottom;
        margin-bottom: 1px;
    }
    tagged-input {
        vertical-align: top;
    }
    .content__status-bar .status-bar__group {
        @include flexbox;
        float: left;
        .status-bar__block {
             @include flex(0 0 auto);
        }
        button {
            @include flex(0 1 auto);
            text-align: left;
            margin-left: 8px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.phone-data-left-col {
    display: inline-block;
    vertical-align: top;
}

.phone-data-right-col {
    display: inline-block;
    width: calc(100% - 290px);
    vertical-align: top;
}
.identification-fields {
    width: calc(100% - 150px);
    display: inline-block;
    .identification-fields--highlighted.fields.padded {
        @include flexbox;
        padding: 0 0 15px 0;
        & > * {
            background-color: #F5F5F5;
            &:first-child {
                border-radius: 3px 0 0 3px;
            }
            &:last-child {
                border-radius: 0 3px 3px 0;
            }
        }
        field {
            padding: 6px 0 12px 0;
            margin-bottom: 0;
            input {
                width: 100%;
            }
        }
        .identification-fields--title {
            @include flex(0 1 80px);
            min-width: 50px;
        }
        .identification-fields--firstname {
            @include flex(0 1 185px);
            min-width: 75px;
        }
        .identification-fields--lastname {
            @include flex(0 1.5 285px);
        }
        .identification-fields--ban-option {
            @include flex(0 0 auto);
        }
        .blank-space {
            @include flex(0 0 20px);
            &:first-child, &:last-child {
                @include flex(0 0 16px);
                margin-right: 0;
            }
        }
    }
}
