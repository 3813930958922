#about{
    height: 100%;
    .title {
        color: $color-info;
        font-size: 18px;
        vertical-align: middle;
        margin-right: 4px;
        text-align: center;
        font-weight: 600;
        .icon-info{
            font-size: 16px;
        }
    }
    label{
        font-weight: 400;
    }
    
    .row {
        margin-top: 16px;
        a {
            color: $default-text-color;
        }
    }
    .column{
        display: inline-block; 
        margin: 0 16px;
        hr{
            width: 40px;
            color: #bbbbbb;
            background-color: #bbbbbb;
            border-style: none;
            height: 2px;
        }
        .local-bridge{
            display: block;
            float: left;
            border-radius: 5px;
            background:#f4f4f4;
            text-align: center;
            width: 100%;
            padding: 10px;
            label, span{
                display: inline-block;
            }
            span{
                font-weight: 200;
                vertical-align: top;
            }
        }
        .button{
            text-align: center;
            width: 100%;
            display: table;            
            button{
                margin-top: 10px;
            }
        }
    }
    .about--copy-to-clipboard {
        background: none;
        padding: 1px 3px;
        border: none;
        color: $black40;
        font-size: 13px;
        &:hover {
            color: $black;
        }
        &:active {
            color: $black75;
        }
    }
}