// TYPOGRAPHY SCALE:
// Empleando escala tipográfica tradicional (según http://lamb.cc/typograph/)
// Escale: 6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 21, 24, 36, 48, 60, 72
// Para determinar la escala de los tamaños de fuente de los headings. Útil para sections, articles, etc.
@mixin hscale ($scale: 100) {
	$multiplier: $scale / 100;

	@each $header, $size in (
		h1: 2.25em,		// 36
		h2: 1.5em,		// 24
		h3: 1.3125em,	// 21
		h4: 1.125em,	// 18
		h5: 1em,		// 16
		h6: .875em		// 14
		) {
		#{$header} {
			font-size: $size * $multiplier;
		}
	}
}