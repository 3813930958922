.select2-container--salto {
  @import "single";
  @import "multiple";
  font-weight: 200;
  font-size: 15px;
  text-align: left;

  &.select2-container--open.select2-container--above {
    .select2-selection--single, .select2-selection--multiple {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.select2-container--open.select2-container--below {
    .select2-selection--single, .select2-selection--multiple {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .select2-dropdown {
    border-color: $active;
    border-radius: 0;
    background-color: $black98;
    .select2-results__options {
      .pre-fake, .post-fake {
        display: table-cell;
      }
    }
    .select2-results__option {
        .partition {
            color: $black80;
        }
    }
  }
  .select2-search--inline {
    .select2-search__field {
      display: inline !important;
      background: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
      margin-top: 0;
      height: 24px;
      &:-ms-input-placeholder {
        color: #a2a2a2;
      }
      &::-webkit-input-placeholder {
        color: #a2a2a2;
      }
      &::-moz-placeholder {
        color: #a2a2a2;
      }
    }
  }

  .select2-results {
    > .select2-results__options {
      max-height: 200px;
      overflow: auto;
      min-width: 100%;
      display: block;
    }
  }

  .select2-results__option {
    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: #999;
    }

    &[aria-selected=true] {
      background-color: #ddd;
    }

    .select2-results__option {
      padding-left: 1em;

      .select2-results__group {
        padding-left: 0;
      }

      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;

        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;

          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;

            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;

              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected], .select2-results__option-fake-selected {
    background-color: #e2eff3;
//    color: white;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
}

@import "in-table";
