.tree-grid-row--level-1--tab td.tree-cell{
	padding-left: 36px;
	.tree-grid-first-cell{
		display: inline-table;
	}
}

.tree-grid-row--level-2 td.tree-cell{
	padding-left: 50px;
}

.tree-grid-row--level-1-rooms td.tree-cell{
	padding-left: 0px;
	input[type=checkbox]{
		margin-left: 3px;
	}
}

.tree-grid-row--level-2-rooms td.tree-cell{
	padding-left: 29px;
	.tree-grid-first-cell{
		&.inline {
			margin-left:0;
		}
		&.inline-table {
			display: inline-table;
		}
	}
}

.tree-grid-row--level-3-rooms td.tree-cell{
	padding-left: 60px;
}

.tree-grid-row--level-3 td.tree-cell{
	padding-left: 84px;
}
.tree-grid-toggle {
	display: inline-block;
	width: 16px;
	height: 16px;
	padding: 0;
	border: none;
	background: transparent;
	color: $default-text-color;
	&.closed {
		-ms-transform: rotate(270deg); /* IE 9 */
		-webkit-transform: rotate(270deg); /* Chrome <=35, Safari <=8, Opera 15-22 */
		transform: rotate(270deg);
	}

	&:focus {
        outline: $button-outline;
    }
}
tree-grid-toggle {
	display: block;
	width: 20px;
}
.tree-grid-first-cell {
	display: table;
	&.inline{
		display: inline;
		margin-left: 5px;
	}
	.tree-grid-first-cell__toggle {
		display: table-cell;
		width: 20px;
	}
	.tree-grid-first-cell__content {
		display: table-cell;
	}
	&.hidden-first-cell {
		visibility: hidden;
	}
	&.inline-table{
		display: inline-table;
	}
}
.tree-item-list {
	margin: 0; 
	padding-left: 16px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	.tree-item {
		list-style-type: none; 
		list-style-position: inside;
		white-space: nowrap;
		&.selected > .field {
			background: $active;
		} 
		.field {
			width: calc(100% - 20px);
			label {
				width: calc(100% - 20px);
			}
		}
		.field:hover {
			background: transparentize($keycolor,0.25);
		}
	}
	&:focus {
		outline: 0;
	}
}

table .tree-grid-first-cell {
	position: relative;
	top: 2px;
}
