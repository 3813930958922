/**
 * Shift indexes from `$list` of `$value`.
 *
 * @author Ana Tudor
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-loop
 *
 * @param {List}   $list      - list to update
 * @param {Number} $value (1) - number of position between old and new indexes
 *
 * @throws $value is not a number for `loop`.
 *
 * @requires sl-has-multiple-values
 *
 * @example
 * sl-loop(a b c)
 * // c a b
 * 
 * @example
 * sl-loop(a b c, 2)
 * // b c a
 *
 * @return {List | Null}
 */

@function sl-loop($list, $value: 1) {
  @if sl-missing-dependencies(sl-has-multiple-values) == true { @return null; }

  @if type-of($value) != "number" {
    @warn "#{inspect($value)} is not a number for `sl-loop`.";
    @return null;
  }

  @if not sl-has-multiple-values($list) {
    @return $list;
  }

  $result: ();
  $length: length($list);

  @for $i from 0 to $length {
    $result: append($result, nth($list, ($i - $value) % $length + 1), list-separator($list));
  }

  @return $result;
}

/**
 * @requires sl-loop
 * @alias sl-loop
 */
 
@function sl-shift-indexes($list, $value: 1) {
  @return sl-loop($list, $value);
}