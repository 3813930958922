// Primero estará todo configurado 
// para las tablas de colores predefinidos
.table-container {
    // \9\0 is a hack for IE. See
    // https://stackoverflow.com/questions/8004765/css-9-in-width-property
	display: inline-block\9\0;
	width: 100%;
	min-height: 200px;
    border: 1px solid $black80;
    background-color: white;
    .light-border & {
        border-color: $black90;
    }
    .dark & {
        border-color: $black20;
        background: transparentize(#000000, 0.80);
    }
    &.table-container--outer {
		margin-left: 1px;
	}
	&.no-border {
		border: none;
	}
    position: relative;
    .thead-wrapper {
        border: 1px solid $black80;
        .light-border & {
            border-color: $black90;
        }
        border-width: 0 0 1px 0;
        overflow: hidden;
        table {
            width: auto;
            .resizable {
                position: relative;
                .resizable-element {
                    position: absolute;
                    right: -5px;
                    width: 10px;
                    top: 0;
                    bottom: 0;
                    cursor: col-resize;
                    /*z-index: 20;*/
                    height: 37px;
                }
            }
        }
        background: #d5d5d5; /* Old browsers */
        background: linear-gradient(to bottom,  #d5d5d5 0%,#e0e0e0 30%,#e8e8e8 60%,#e0e0e0 100%); /* W3C */
        .dark & {
            background: linear-gradient(to bottom, #272727 0%,#161616 50%,$black 100%);
            border-color: $black;
        }
        .white & {
            background: $white;
            background: linear-gradient(#fefefe 0%, #fefefe 61%, #f5f5f5 89%, #f5f5f5 92%, #fdfdfd 100%);
        }
        
        .scrollspace {
            display: none;
        }
        &.has-vertical-scroll {
            
            .thead-wrapper-content {
                padding-right: 24px;
                display: table;
            }
            .scrollspace {
                display: block;
                width: 24px;
                height: 37px;
                position: absolute;
                top: 0;
                right: 0;
                //border-left: 1px solid $black80;
                background: linear-gradient(to bottom,  #d5d5d5 0%,#e0e0e0 30%,#e8e8e8 60%,#e0e0e0 100%); /* W3C */
                .dark & {
                    border-left-color: $black30;
                    background: linear-gradient(to bottom, #272727 0%,#161616 50%,$black 100%);
                }
                .white & {
                    background: $white;
                    background: linear-gradient(#fefefe 0%, #fefefe 61%, #f5f5f5 89%, #f5f5f5 92%, #fdfdfd 100%);
                }
            }
        }
    }
    &.table--highlight-active {
        .thead-wrapper{
            background: linear-gradient(to bottom,  #D9D9D9 0%,#F3F3F3 100%); /* W3C */
            border-top: 1px solid $white;
        }
        &.hide-scrollspace {
            .thead-wrapper-content {
                padding-right: 0 !important;
                width: 100% !important;
                table {
                    width: 100% !important;
                }
            }
        }
    }
    &.hide-scrollspace {
        .scrollspace{
            display: none !important;   
        }
    }
    .tbody-wrapper {
        overflow: auto;
        min-height: 163px;
        table {
            outline: none;
            margin-top: -38px;
            width: calc(100% - 1px);
            .dark & {
                width: 100%;
            }
            thead {
                visibility: hidden;
            }
            tr {
                cursor: default;
                outline: none;
            }
        }
        .table-empty {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: calc(100% - 32px);
            color: $black70;
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
			text-align: center;
            margin: 0 16px;
            .table-empty__icon, .table-empty__text {
                line-height: 18px;
                vertical-align: text-top;
            }
            .table-empty__icon {
                padding: 0 8px 0 0;
            }
            .table-empty__content {
                margin-top: 24px;
                max-width: 100%;
            }
        }
    }
    table {
        /* Prevents blue outlines from appearing when clicking on table items on
           Firefox as the Ctrl key is pressed. This has the side effect of
           disabling text selection. */
        -moz-user-select: none;

        &:not(.disable-highlight) {
            tbody tr.highlight {
                td {
                    animation-name: highlight-row;
                    animation-duration: 3.5s;
                    animation-timing-function: ease-in-out;
                }
                &.selected {
                    td {
                        animation-name: inverse-highlight-row;
                    }
                }
            }
        }
        thead {
            tr th {
                border: 1px solid $black80;
                @extend .table-container-th;
            }
        }
        tbody {
            td {
                white-space: nowrap;
                padding: 0 16px;
                height: 28px;
                font-size: 15px;
                overflow: hidden;
                [class^="icon-"], [class*=" icon-"] {
                    font-size: 16px;
                    vertical-align: middle;
                }
                .inline-icon{
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    margin-left: -8px;
                    margin-right: 8px;
                }
                &.hidden-column {
                    padding: 0;
                    border: none;
                    width: 0;
                    > * {
                        visibility: hidden;
                    }

                }
            }
            tr { 
                &:nth-child(even) {
                    td {
                        background: #f4f4f4;
                        .dark & {
                            background: transparentize(#000000, .80);
                        }
                    }
                }
                &.flat {
                    td {
                        background: #fefefe;
                    }
                }
                &.non-removable-item {
                    td {
                        background-color: #DAF2FF;
                    } 
                }
                &.selected {
                    td {
                        background: transparentize($active, 0.25);
                        .dark & {
                            background: transparentize($active, 0.25);
                        }
                    }
                }
                &:hover {
                    td {
                        background: transparentize($keycolor,0.25);
                        .dark & {
                            background: transparentize($keycolor,0.25);
                        }
                    }
                }
                td.table-row--word-break{
                    max-width: 293px;
                    overflow: hidden;
                }
            }
            /*Time-Period-Actions*/
            &.list-and-detail-actions {
                tr {
                    &:nth-child(odd), &:nth-child(even) {
                        td {
                            background: $white;
                            border-bottom: 1px solid #e5e5e5;
                            border-right: 1px solid #e5e5e5;
                            cursor: default;
                            padding: 0 8px;
                            width: auto;
                            &.no-rborder {
                                border-right: none;
                            }
                            &.list-and-detail-actions__row-action {
                                padding:0;
                                button{
                                    outline: 0;
                                    background: #FAFAFA;
                                    text-align: center;
                                    cursor: pointer;
                                    span{cursor: pointer;}
                                    height: 58px;
                                    width: 100%;
                                    border:none;
                                    margin: 0;
                                    padding: 0 10px;
                                    display: inline-block;
                                    color: #666666;
                                    &:hover, &:focus {
                                        background: $black80;
                                    }   
                                }
                            }
                        }
                    }
                }
                &:hover {
                    td {
                        background: $white;
                    }
                }
            }
        }
        .td-center {
            text-align: center;
        }
        .td-checkbox {
            width: 40px !important;
            padding: 5px 0 0 0;
            
            .td-wrapper {
                width: 40px !important;
                text-align: center;
            }

            input {
                margin: 0 !important;
            }
        }
        th.td-checkbox {
            text-align: center;
        }
        td.td-checkbox input[type='checkbox'] {
            position: relative;
            top: -1px;
        }
        .td-small {
            width: 50px;
        }
        .td-medium {
            width: 120px;
        }
        .td-full {
            width: 100%;
            min-width: 0px !important;
        }
        .td-max-310 {
            max-width: 310px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        input[type='checkbox'] {
            margin-right: 5px;

            &.checkbox-top-1px {
                position: relative;
                top: 1px;
            }

            &.checkbox-top-2px {
                position: relative;
                top: 2px;
            }
        }
        &.selectable {
            tbody {
                tr {
                    cursor: default;
                }
            }
        }

        &.valign-mid .td-wrapper {
            vertical-align: middle;
        }
    }
}

.table-container-th {
    white-space: nowrap;
    box-sizing: border-box;
    background: #d5d5d5; /* Old browsers */
    background: linear-gradient(to bottom,  #d5d5d5 0%,#e0e0e0 30%,#e8e8e8 60%,#e0e0e0 100%); /* W3C */
    .dark &, &.dark {
        background: linear-gradient(to bottom, #272727 0%,#161616 50%,$black 100%);
        border-color: $black;
    }
    .white &, &.white {
        color: #aaaaaa;
        background: $white;
        background: linear-gradient(#fefefe 0%, #fefefe 61%, #f5f5f5 89%, #f5f5f5 92%, #fdfdfd 100%);
    }
    &.gu-mirror {
        padding-top: 7px;
        border: 1px solid #cccccc;
        &.dark {
            border: none;
        }
    }
    .light-border & {
        border-color: $black90;
    }
    border-width: 0 0 0 1px;
    @-moz-document url-prefix() {
        border-width: 0;
        box-shadow: 1px 0 0 $black80;
    }
    height: 37px;
    font-size: 14px;
    /*line-height: 37px;*/
    text-align: left;
    padding: 0 8px 0 16px;
    text-transform: uppercase;
    color: $black40;
    font-weight: 600;
    .dark & {
        color: $white;
        border-color: $black30;
        @-moz-document url-prefix() {
            border-width: 0;
            box-shadow: 1px 0 0 $black30;
        }
    }
    .white & {
        color: #aaaaaa;
    }
    &.td-icon {
        text-align: right;
        padding-left: 0;
    }
    [class^="icon-"], [class*=" icon-"] {
        vertical-align: middle;
        font-size: 16px;
    }
    &:first-child { 
        border-left-width: 0;
    }
    &.th--xs {
        width: 70px;
    }
    &.th--s {
        width: 110px;
    }
    &.no-rborder {
        border-right: 0;
    }
    &.no-lborder {
        border-left: 0;
    }
    .table-header {
        display: table;
        width: 100%;
        .table-header-title {
            display: table-cell;
            vertical-align: middle;
            cursor: default;
        }
        .table-options {
            display: table-cell;
            width: 1.5em;
            padding-left: 8px;
            & [class^='icon-'], & [class*=' icon-'] {
                font-size: 14px;
                vertical-align: middle;
            }
        }
        .table-options--x2 {
            width: 3em;
        }
    }
    
    .table-header-button {
        @include filter-button;
    }
    input[type='checkbox'] {
        margin-left: 20px;
    }
    .tooltip-container {
        cursor: default;
    }
    &.hidden-column {
        padding: 0;
        border: none;
        width: 0;
        > * {
            visibility: hidden;
        }

    }
    &.checkbox-column {
        min-width: 40px;
    }
    &.dummy-resizable-column {
        width: auto;
    }
}

.main-list-table {
    .mCSB_1_container_wrapper {
        height: 560px;
        max-height: calc(100% - 24px);
    }
}
.list-and-detail__table {
    .tbody-wrapper {
        table {
            table-layout: fixed;
        }
        
    }
    table {
        th,td {
            overflow: hidden;
        }
    }
    .scrollspace {
        display: none;
    }
}
.tab {
    .tbody-wrapper, .thead-wrapper {
        table {
            table-layout: fixed;
        }
    }
    table {
        th,td {
            overflow: hidden;
        }
    }
}
table-footer {
    display: block;
}
.table-footer {
	background: $black85;
	background: linear-gradient(to bottom, #d8d8d8 0%,#eaeaea 70%,#e9e9e9 78%,#dddddd 96%,#dedede 100%);
	height: 52px;
	border: 1px solid $black80;
	border-top-color: $black85;
	.table-footer-pagination {
		display: table;
		width: 100%;
		height: 100%;
		.table-footer-pagination__previous-page, 
		.table-footer-pagination__current-information, 
		.table-footer-pagination__next-page {
			display: table-cell;
			padding: 8px;
			text-align: center;
		}
		.table-footer-pagination__previous-page, .table-footer-pagination__next-page {
			width: 1em;
			border: 1px solid $black80;
			border-width: 0;
		}
		.table-footer-pagination__previous-page {
			text-align: left;
			border-width: 0 1px 0 0;
		}
		.table-footer-pagination__current-information {
			text-align: center;
			text-transform: uppercase;
			font-size: 14px;
		}
		.table-footer-pagination__next-page {
			text-align: right;
			border-width: 0 0 0 1px;
		}

        &.non-table{
            display: block;
            .table-footer-pagination__main-block{
                display: inline-block;
                vertical-align: top;
                padding: 0;
                height: 100%;
                margin: 0;
                .table-footer-pagination__current-information
                {
                    display: inline-block;
                    padding: 0px 8px;
                    text-align: center;
                    text-transform: none;
                    &.no-padding{
                        padding: 0;
                    }
                    &.only-text {
                        padding: 16px 0 16px 16px;
                    }
                    &.bolder, .bolder{
                        font-weight: 600;
                        text-transform: uppercase;
                        &.no-text-transform{
                            text-transform: none;
                        }
                    }
                    .right-border{
                        border-right: 1px solid $black80;
                        padding-right: 8px;
                    }
                    &.half-padding {
                        padding-left: 8px;
                    }
                    &.centered{
                        display: block;
                        width: auto;
                        margin: 0 auto;
                    }
                    .fixed-span{
                        display: inline-block;
                        min-width: 2.5em;
                        text-align: left;
                        &.big{
                            min-width: 3em;
                        }
                        &.small{
                            min-width: 1.5em;
                        }
                        &.text-right{
                            text-align: right;
                        }
                    }
                    .info-block{
                        text-transform: capitalize;
                        margin: 8px 0 0 16px;
                        font-weight: 200;
                        [class^=icon-]{
                            font-size:12px;
                            color: $black70;
                        }
                    }
                    .spin{
                        width: 14px;
                        height: 14px;
                        display: inline-block;
                        animation: roll 0.5s infinite;
                        @keyframes roll {
                            0% {
                                transform: rotate(0);
                            }
                            100% {
                                transform: rotate(360deg);
                            }
                        }   
                    }
                }
                .button-list{
                    display: inline-block;
                    margin: 0;
                    height: 100%;
                    padding: 8px;
                    &.button-list--left-border{
                        border-left: 1px solid $black80;
                        padding-left: 16px;
                    }
                }
            }
        }
	}
	ul.button-list {
		margin: 8px;
        &.no-padding{
            padding: 0;
        }
	}
	.info-block {
		text-transform: uppercase;
		border: 1px solid #bcbcbc;
		background-color: #f2f2f2;
		border-radius: 4px;
		padding: 7px;
		font-size: 15px;
		margin: 0 8px 8px 0;
	}
	.table-footer__total{
		font-size: 14px;	
		margin-left: 15px;
		margin-top: 17px;
		margin-right: 15px;
		display: inline-block;
		text-transform: uppercase;
        span.total {
            font-weight: 600;
        }
	}

	&.table-footer--dark{
		color: $white;
		background: linear-gradient(to bottom, #272727 0%,#161616 50%,$black 100%);
		border: none;
		button {
			float: right;
			margin-top: 7px;
			margin-right: 8px;
		}
	}
    
    &.table-footer--white {
        background: $white;
        background: linear-gradient(#fefefe 0%, #fefefe 61%, #f5f5f5 89%, #f5f5f5 92%, #fdfdfd 100%);
		button {
			float: right;
			margin-top: 7px;
			margin-right: 8px;
		}
    }
}

.table-footer--slim {
    @extend .table-footer;
    height: 39px;
    .table-footer__total {
        margin-top: 10px;
    }
}

.table--highlight-active{
    background: #fafafa;
    border-width: 0;
	thead {
		tr th {
			font-size:18px;
			text-transform: none;
			background: linear-gradient(to bottom,  #D9D9D9 0%,#F3F3F3 100%); /* W3C */
            .list-and-details--action{
                width: auto;
                display: inline-block;
                float: right;
                button {
                    outline: 0;
                }
            }
		}
	}
	tbody {
		tr {
			font-size: 15px;
			font-weight: 200;
			td{
				padding: 8px 5px;
			}
			&:nth-child(odd) {
				td {
					background:#eeeeee;
				}
			}
			&:nth-child(even) {
				td {
					background: #fafafa;
				}
			}
			&.selected {
				td {
					color: $white;
					background: $active !important;
				}
			}
			&:hover {
				td {
					background: transparentize($active, 0.5) !important;
				}
			}
            .table--highlight__word-break{
                width: 100%;
                max-width: 100%;
                overflow:hidden;
                padding: 0;
                height: 34px;
                display:block;
                .table--highlight__word-break__text{
                    margin: 8px 5px;
                    display: inline;
                    vertical-align: middle;
                    max-width: 170px;
                    overflow: hidden;
                    //Para ocultar el tooltip
                    padding-right:1px;
                    &.has-error {
                        //width: 130px;
                        width: calc(100% - 40px);
                        overflow: hidden;
                    }
                    &.table--highlight__word-break__text--large {
                        max-width: 220px;
                    }
                }
                .table--highlight__word-break__error{
                    margin: 8px 11px 8px 0;
                    font-size:16px;
                    display: inline-block;
                    float:right;
                }
            }
		}
	}
}

@keyframes highlight-row {
	from {
		background: transparentize($active, 0.25);
	}
}

@keyframes inverse-highlight-row {
	from {
		background: $white;
        color: $default-text-color;
	}
}

.off-canvas-tab-footer {
    display: table; 
    width: 100%; 
    padding-left: 10px;
    .table-footer__total,
    .table-footer__button {
        display: table-cell; 
        width: 1px; 
        white-space: nowrap;
        vertical-align: middle; 
        button {
            margin-top: 0;
        }
    }   
    .table-footer__total {
        padding-right: 15px;
    }
    .table-footer__separator {
        width: 100%;
        display: table-cell;
    } 
}
.table-legend-container {
    padding-top: 16px;

    &.three-quarters-padding {
        padding-top: 15px;
    }

    .table-legend {
        @include flexbox;
        @include align-items(center);
        .table-legend___icon {
            width: 15px; 
            height: 15px; 
            border: 1px solid #CCCCCC; 
            background-color: #BAE7FF;
        }
        .table-legend___text {
            font-weight: 400;
            color: $black60;
            margin-left: 5px; 
            line-height: 15px;
            &.table-legend___text--no-margin {
                margin-left: 0px; 
            }
        }
    }
}
.default-modal-table {
    width: 320px;
}

.table--no-min-height {
    .table-container {
        min-height: 0;
        .tbody-wrapper {
            min-height: 0;
        }
    }
}

.main-list-table .table-container {
    height: 600px;
    &.has-horizontal-scroll {
        height: 624px;
    }
    .tbody-wrapper {
        height: calc(100% - 38px) !important;
    }
}
.ignore-min-width td, .ignore-min-width th {
    min-width: 0px !important;
}

.no-stripes .table-container table tbody tr:nth-child(even):not(.selected):not(:hover) td {
    background: none;
}

.ngdialog .no-scroll-table .table-container .tbody-wrapper table,
.ngdialog .no-scroll-table .table-container .thead-wrapper table {
    table-layout: fixed;
    word-wrap: normal;
    th, td {
        overflow: hidden;
    }
}

.table-multiple-selection{
    button {
        display: inline-block;
        position:relative;
        outline: none;
        background: none;
        border: none;
        width: 26px;
        height: 24px;
        text-align: center;
        color: #666666;
        padding: 0;
        box-shadow: none;
        margin: 0;
        box-sizing: border-box;
        [class^="icon-"], [class*=" icon-"] {
            font-size: 13px !important;
        }
        &:hover{
            cursor: pointer;
        }

        &:focus {
            outline: 1px dotted $active;
        }
    }
}

.table-multiple-selection__popup{
    position: absolute;
    background: $white;
    padding: 18px 0 12px;
    display: block;
    border: 1px solid $black80;
    ul.table-multiple-selection__list{
        padding: 0px 16px 0 20px;
        margin: 0;
        list-style: none;
        li.table-multiple-selection__options{
            margin-bottom: 4px;
            cursor: pointer;
            &:before{
                font-family: 'icomoon';
                font-size: 11px;
                visibility: hidden;
                content: '\2713';
                margin-left: -1em;
                margin-right: .100em;
            }
            &.selected{
                &:before{
                    visibility: visible;
                }
            }
            &:hover:not(.selected) {
                &:before{
                    visibility: visible;
                    color: #cccccc;
                }
            }

            &:focus {
                outline: none !important;
            }

            .table-multiple-selection__options__text{
                font-weight: 600;
            }
            .wrapper {
                margin-left: 5px;
            }

            &:focus .wrapper {
                border-bottom: 1px $active dotted;
            }
        }
    }
    button {
        outline: none;
        background: $white;
        color: $active;
        border: none;
        width: 26px;
        height: 24px;
        text-align: center;
        position:absolute;
        left: 0;
        top: -24px;
        padding: 0;
        box-shadow: none;
        margin: 0;
        [class^="icon-"], [class*=" icon-"] {
            font-size: 13px !important;
        }
        &:hover{
            cursor: pointer;
        }
    }
}
