.sprite-access-point-online-ip {
	background-image: url(/assets/img/SaltoNetwork/default/access-point-online-ip.png);
}
.sprite-access-point-online-ip.active {
	background-image: url(/assets/img/SaltoNetwork/hover/access-point-online-ip.png);
}

.sprite-gateway {
	background-image: url(/assets/img/SaltoNetwork/default/gateway.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/gateway.png);
	}
}
.sprite-gateway-rf {
	background-image: url(/assets/img/SaltoNetwork/default/gateway-rf.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/gateway-rf.png);
	}
}
.sprite-gateway-bas {
	background-image: url(/assets/img/SaltoNetwork/default/gateway-bas.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/gateway-bas.png);
	}
}
.sprite-gateway-cu4200 {
	background-image: url(/assets/img/SaltoNetwork/default/gateway-cu4200.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/gateway-cu4200.png);
	}
}

.sprite-node-rf {
	background-image: url(/assets/img/SaltoNetwork/default/node-rf.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/node-rf.png);
	}
}

.sprite-node-rf3 {
	background-image: url(/assets/img/SaltoNetwork/default/node-rf3.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/node-rf3.png);
	}
}

.sprite-node-cu4keb {
	background-image: url(/assets/img/SaltoNetwork/default/node-cu4keb.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/node-cu4keb.png);
	}
}

.sprite-node-cu4k {
	background-image: url(/assets/img/SaltoNetwork/default/node-cu4200.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/node-cu4200.png);
	}
}

.sprite-access-point-rf {
	background-image: url(/assets/img/SaltoNetwork/default/access-point-online-rf-salto.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/access-point-online-rf-salto.png);
	}
}

.sprite-access-point-rf3 {
	background-image: url(/assets/img/SaltoNetwork/default/access-point-online-rf3-salto.png);
	&.active {
		background-image: url(/assets/img/SaltoNetwork/hover/access-point-online-rf3-salto.png);
	}
}

.sprite-encoder {
	background-image: url(/assets/img/SaltoNetwork/default/encoder.png);
}
.sprite-encoder.active {
	background-image: url(/assets/img/SaltoNetwork/hover/encoder.png);
}
.sprite-access-point-online-rf-bas {
  	background-image: url(/assets/img/SaltoNetwork/default/access-point-online-rf-bas.png);
}
.sprite-access-point-online-rf-salto {
  	background-image: url(/assets/img/SaltoNetwork/default/access-point-online-rf-salto.png);
}
.sprite-access-point-online-rf3-salto {
  	background-image: url(/assets/img/SaltoNetwork/default/access-point-online-rf3-salto.png);
}
.sprite-offline {
  	background-image: url(/assets/img/SaltoNetwork/default/offline.png);
}