#features{
    width:500px;
    margin:10px;
    #feature_constraints{
        .featureConstraint{
            display: inline-block;
            margin: 0px 15px 10px 0px;
        }
        label{
            margin-right: 2px;
            display: inline-block;
            float: left;
        }
    }
    #features__table-container {
        height: 350px;
        overflow: hidden,
    }
    .status-center-icon{
        text-align: center;
    }
    
    .licenseStatus{
        width: 12px;
        height: 12px;
        border-radius: 6px;
        display: inline-block;
    }
    tr {
        &:nth-child(even) {
            .constraint {                
                border-color: white;
            }
        }
        &:nth-child(odd) {
            .constraint {
                border-color: #f4f4f4;
            }
        }
    }
    .constraint{
        display: inline-block;
        border-style: solid;
        border-width: 1px;
        margin: 3px 8px 3px 8px;
        padding: 2px 5px;
        span{       
           color: #9B9B9B;
           font-size: 13px;
           span{
               font-size: 12px;
               font-weight: $bold-weight;
               margin: 0px;
               display: inline-block;
           }
        }
    }
    
}