$fonts-path: "../fonts";
$icon-path: "fonts/icomoon";

$main-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$main-font-size: 15px;

$main-background-color: #e9e9e9;

// Css Wizardry grids
$use-markup-fix: false;

$dialog-z-index: 1450;
$tooltip-z-index: 2000;
$error-z-index: 2100;
$menu-dropdown-z-index: 2500;
$silverlight-z-index: 3000;

$detail-box-radius: 3px;

//Tamaños para inputs tipo text
$field-xxs-size: 40px;
$field-xs-size: 60px;
$field-s-size: 80px;
$field-m-size: 150px;
$field-l-size: 225px;
$field-xl-size: 285px;
$field-xxl-size: 400px;
$field-xxxl-size: 507px;

$button-outline: 1px $active dotted;

$bold-weight: 800;
