#home6Elements{
    height: 470px;
    display: inline-block;
    
    .primaryButton{
        height: 310px;
        width: 310px;
    }
    .secondaryButton{
        height: 155px;
        width: 155px;
    }
     .twoButtons{
        display: inline-block;
        button{
            display: block;
        }
        
     }   
    
    .button1{  
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
    }
    .button2{
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8; 
        border-right: solid 1px #e8e8e8;
    } 
    .button3{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
        
    }    
    .button4{
        border-left: solid 1px white; 
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
    }
    .button5{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
    } 
    .button6{
        border-left: solid 1px white; 
        border-top: solid 1px white;
    }    
    
    //las clases border y sus divs corresponientes se usan únicamente
    //para pintar los bordes.
    .border1{
        height:77.5px;
        border-right: solid 1px #e8e8e8;
    }
    .border2{
        height:77.5px;
        border-right: solid 1px #e8e8e8;
        border-top: solid 1px white;
    }
    .border3{
        height: 77.5px;
        border-left: solid 1px white; 
    }
    .border4{
        height:77.5px;
        border-left: solid 1px white;
        border-right: solid 1px #e8e8e8;
    }
    .border5{
        height:77.5px;
        border-right: solid 1px #e8e8e8;
    }
    .border6{
        height:77.5px;
        border-left: solid 1px white;
        border-right: solid 1px #e8e8e8;
    }
    .border7{
        height:150px;
        border-left: solid 1px white;
        border-bottom: solid 1px #e8e8e8;
    }
}