/**
 * Chunks `$list` into `$size` large lists.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-chunk
 *
 * @param {List}   $list  - list to chunk
 * @param {Number} $size  - length of lists
 *
 * @throws $size is not a number for `sl-chunk`.
 *
 * @requires sl-to-list
 *
 * @example
 * sl-chunk(a b c d e, 2)
 * // a b, c d, e
 *
 * @return {List | Null}
 */
 
@function sl-chunk($list, $size) {
  @if sl-missing-dependencies(sl-to-list) == true { @return null; }

  @if type-of($size) != "number" {
    @warn "#{inspect($size)} is not a number for `sl-chunk`.";
    @return null;
  }

  @if $size >= length($list) {
    @return sl-to-list($list);
  }

  $index: 1;
  $result: ();
  $length: length($list);
  $end: ceil($length / $size);

  @for $i from 1 through $end {
    $tmp: ();

    @for $j from 1 through $size {
      @if $index <= $length {
        $is-orphan: $length % $size == 1 and $j == 1;
        
        @if $is-orphan {
          $tmp: nth($list, $index);
        }

        @else {
          $tmp: append($tmp, nth($list, $index), list-separator($list));
        }
      }

      $index: $index + 1;
    }

    $result: append($result, $tmp);
  }

  @return $result;
}