/**
 * Returns a list of values from `$lists` minus duplicates.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-union
 *
 * @requires sl-flatten
 * @requires sl-remove-duplicates
 *
 * @param {ArgList} $lists - lists to unify
 *
 * @example
 * sl-union(a b c, b e d, a c b)
 * // a b c e d
 * 
 * @return {List}
 */
 
@function sl-union($lists...) {
  @if sl-missing-dependencies(sl-flatten, sl-remove-duplicates) == true { @return null; }

  $result: sl-remove-duplicates(sl-flatten($lists));
  
  @return $result;
}

/**
 * @requires sl-union
 * @alias sl-union
 */

@function sl-merge($lists...) {
  @return sl-union($lists...);
}
