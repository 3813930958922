/**
 * Slices `$list` between `$start` and `$end`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-slice
 *
 * @param {List}   $list                  - list to slice
 * @param {Number} $start (1)             - start index
 * @param {Number} $end   (length($list)) - end index
 *
 * @throws List indexes $start and $end must be numbers for `sl-slice`.
 * @throws Start index has to be lesser than or equals to the end index for `sl-slice`.
 * @throws List indexes must be non-zero integers for `sl-slice`.
 * @throws Start index has to be lesser than or equal to list length for `sl-slice`.
 * @throws End index has to be lesser than or equal to list length for `sl-slice`.
 * 
 * @example
 * sl-slice(a b c d e, 2, 4)
 * // b c d
 *
 * @example
 * sl-slice(a b c d e, 2, 2)
 * // b
 *
 * @example
 * sl-slice(a b c d e, 4, 2)
 * // null
 *
 * @example
 * sl-slice(a b c d e, -1, 6)
 * // null
 * 
 * @return {List | Null}
 */
 
@function sl-slice($list, $start: 1, $end: length($list)) {
  @if type-of($start) != "number" or type-of($end) != "number" {
    @warn "List indexes #{$start} and #{$end} must be numbers for `sl-slice`.";
    @return null;
  }

  @if $start > $end {
    @warn "Start index is #{$start} but has to be lesser than or equals to the end index (#{$end}) for `sl-slice`.";
    @return null;
  }

  @if $start < 1 or $end < 1 {
    @warn "List indexes must be non-zero integers for `sl-slice`.";
    @return null;
  }

  @if $start > length($list) {
    @warn "Start index is #{$start} but list is only #{length($list)} items long for `sl-slice`.";
    @return null;
  }

  @if $end > length($list) {
    @warn "End index is #{$end} but list is only #{length($list)} items long for `sl-slice`.";
    @return null;
  }

  $result: ();
  
  @for $i from $start through $end {
    $result: append($result, nth($list, $i), list-separator($list));
  }

  @return $result;
}