/**
 * Counts the number of occurrences of each value of `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-count-values
 *
 * @param {List} $list - list to count values from
 *
 * @example
 * sl-count-values(a b c a)
 * // (a: 2, b: 1, c: 1) 
 *
 * @return {Map} Values mapped to their count
 */
 
@function sl-count-values($list) {
  $map: ();

  @each $item in $list {
    $index: map-get($map, $item);
    $value: if($index, $index + 1, 1);
    $map: map-merge($map, ($item: $value));
  }

  @return $map;
}