.add-items-wrapper {
    display: inline-block;
    width: calc(100% - 35px);
    vertical-align: middle;
}

.order-list-wrapper {
    display: inline-block;
    width: 31px;
    vertical-align: middle;

    ul {
        margin: 0 !important;
        position: relative;
        left: 4px;
    }
}

.scheduling-table {
    display: table;

    .row {
        display: table-row;

        & > div {
            display: table-cell;
            white-space: nowrap;
            vertical-align: middle;

            &:last-child .ctrl { // fullpicker divs
                margin-left: 8px;
            }
        }
    }
}

#db-table-sync-step1{
    .key-labels--inline{
        label{
            width: calc( #{$field-l-size} + #{$psw-right-margin});
        }
    }
    .edition-input{
        width: $field-l-size;    
    }
    .key-defined-input{
        width: 191px;
    }
}

.fields-table-wrapper {
    detail-box {
        height: 100%;
        padding-bottom: 15px;
    }
    
    .detail-box {
        height: 100% !important;
        min-height: 355px;
    }

    .detail-box__content {
        margin-top: 0;
        padding-top: 15px;
        height: calc(100% - 36px);

        .table-container .tbody-wrapper {
            min-height: 160px;
        }
    }
}

#users-step1 {
    .user-export--partition-container {
        padding-right: 16px;
    }
    .ctrl {
        .button-secondary {
            margin: 0;
        }
    }
}