/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/



/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/



/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/

//	.mCustomScrollbar{ -ms-touch-action: pinch-zoom; touch-action: pinch-zoom; /* direct pointer events to js */ }
//	.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action{ -ms-touch-action: auto; touch-action: auto; }
	
	.mCustomScrollBox{ /* contains plugin's markup */
		position: relative;
		overflow: hidden;
		height: 100%;
		max-width: 100%;
		outline: none;
		direction: ltr;
	}

	.mCSB_container{ /* contains the original content */
		overflow: hidden;
		width: auto;
		height: auto;
	}



/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/

	.mCSB_inside > .mCSB_container{ 
        margin-right: 24px; 
    }

	.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden { 
        margin-right: 0;
    } /* non-visible scrollbar */
	
	.mCSB_scrollTools{ /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
		position: absolute;
		width: 24px;
		height: auto;
		left: auto;
		top: 0;
		right: 0;
		bottom: 0;
        background: #e5e5e5;
	}

	.mCSB_outside + .mCSB_scrollTools{ right: -26px; } /* scrollbar position: outside */
	
	.mCSB_scrollTools .mCSB_draggerContainer{ /* contains the draggable element and dragger rail markup */
		position: absolute;
		top: 4px;
		left: 4px;
		bottom: 4px;
		right: 4px; 
		height: auto;
	}

	.mCSB_scrollTools a + .mCSB_draggerContainer{ margin: 20px 0; }

	.mCSB_scrollTools .mCSB_draggerRail{
		width: 16px;
		height: 100%;
		margin: 0 auto;
        border: 1px solid #ccc;
        background: #d8d8d8;
	}

	.mCSB_scrollTools .mCSB_dragger{ /* the draggable element */
		cursor: pointer;
		width: 100%;
		height: 30px; /* minimum dragger height */
		z-index: 1;
	}

	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ /* the dragger element */
		position: relative;
		width: 16px;
		height: 100%;
		margin: 0 auto;
		text-align: center;
        border: 1px solid #ccc;
        background: url('../img/icon-scroll-vertical.png') no-repeat center center white;
        &:hover {
            border-color: $black60;
        }
	}
	
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{ width: 12px; /* auto-expanded scrollbar */ }
	
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{ width: 8px; /* auto-expanded scrollbar */ }

	.mCSB_scrollTools .mCSB_buttonUp,
	.mCSB_scrollTools .mCSB_buttonDown{
		display: block;
		position: absolute;
		height: 20px;
		width: 100%;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
	}

	.mCSB_scrollTools .mCSB_buttonDown{ bottom: 0; }


/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/

	.mCSB_horizontal.mCSB_inside > .mCSB_container{
		margin-right: 0;
		margin-bottom: 24px;
	}
	
	.mCSB_horizontal.mCSB_outside > .mCSB_container{ min-height: 100%; }

	.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden{ margin-bottom: 0; } /* non-visible scrollbar */

	.mCSB_scrollTools.mCSB_scrollTools_horizontal{
		width: auto;
		height: 24px;
		top: auto;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
	.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal{ bottom: -26px; } /* scrollbar position: outside */

	.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer{ margin: 0 20px; }

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail{
		width: 100%;
		height: 16px;
		margin: 0;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger{
		width: 30px; /* minimum dragger width */
		height: 100%;
		left: 0;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar{
		width: 100%;
		height: 16px;
		margin: 0 auto;
        background: url('../img/icon-scroll-horizontal.png') no-repeat center center white;
	}
	
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{
		height: 12px; /* auto-expanded scrollbar */
		margin: 2px auto;
	}
	
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
	.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{
		height: 8px; /* auto-expanded scrollbar */
		margin: 4px 0;
	}

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight{
		display: block;
		position: absolute;
		width: 20px;
		height: 100%;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
	}
	
	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft{ left: 0; }

	.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight{ right: 0; }



/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/

	.mCSB_container_wrapper{
		position: absolute;
		height: auto;
		width: auto;
		overflow: hidden;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin-right: 24px;
		margin-bottom: 24px;
	}
	
	.mCSB_container_wrapper > .mCSB_container{
		padding-right: 24px;
		padding-bottom: 24px;
		-webkit-box-sizing: content-box; -moz-box-sizing: content-box; box-sizing: content-box;
	}
	
	.mCSB_container_wrapper.mCS_no_scrollbar_x, .mCSB_container_wrapper.mCS_no_scrollbar_y {
		> .mCSB_container {
			-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;
			
		}
	} 
	
	.mCSB_container_wrapper.mCS_no_scrollbar_x {
		padding-bottom: 0;
	}
	.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical{ bottom: 24px; }
	
	.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal{ right: 24px; }
	
	/* non-visible horizontal scrollbar */
	.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical{ bottom: 0; }
	
	/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
	.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal, 
	.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal{ right: 0; }
	
	/* RTL direction/left-side scrollbar */
	.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal{ left: 20px; }
	
	/* non-visible scrollbar/RTL direction/left-side scrollbar */
	.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal{ left: 0; }
	
	.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper{ /* RTL direction/left-side scrollbar */
		margin-right: 0;
		margin-left: 30px;
	}
	
	.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container{ padding-right: 0; }
	
	.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container{ padding-bottom: 0; padding-right: 0;}
	
	.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden{
		margin-right: 0; /* non-visible scrollbar */
		margin-left: 0;
	}
	
	/* non-visible horizontal scrollbar */
	.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden{ margin-bottom: 0; }



/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/

	/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
	
	/* default theme ("light") */

.dark {
        .mCSB_scrollTools {
            background: #00466f;
            .mCSB_draggerRail {
                background: #004165;
                border-color: #00324f;
            }
            .mCSB_dragger .mCSB_dragger_bar {
                background-color: black;
                border-color: #336f91;
            }
        }
    }