@mixin filter-button {
    padding: 0;
    border: 1px solid $table-header-button-border-color;
    display: inline-block;
    background: #d3d3d3; /* Old browsers */
    background: -moz-linear-gradient(top,  #d3d3d3 0%, #dbdbdb 60%, #d8d8d8 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d3d3d3), color-stop(60%,#dbdbdb), color-stop(100%,#d8d8d8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #d3d3d3 0%,#dbdbdb 60%,#d8d8d8 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #d3d3d3 0%,#dbdbdb 60%,#d8d8d8 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #d3d3d3 0%,#dbdbdb 60%,#d8d8d8 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #d3d3d3 0%,#dbdbdb 60%,#d8d8d8 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d3d3d3', endColorstr='#d8d8d8',GradientType=0 ); /* IE6-9 */
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    color: $black40;
    box-sizing: border-box;
    .dark & {
        color: $white;
        background: linear-gradient(to bottom, $black 0%,$black 60%, #252525 100%);
        border-color: $black30;
    }
    &:hover:not(.button-disabled) {
        background: $keycolor;
    }
    &:focus {
        outline: $button-outline;
    }
    &.active {
        background: $active; /* Old browsers */
        background: -moz-linear-gradient(top,  $active 0%, $active 60%, darken($active, 9) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$active), color-stop(60%,$active), color-stop(100%,darken($active, 9))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  $active 0%,$active 60%,darken($active, 9) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  $active 0%,$active 60%,darken($active, 9) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  $active 0%,$active 60%,darken($active, 9) 100%); /* IE10+ */
        background: linear-gradient(to bottom,  $active 0%,$active 60%,darken($active, 9) 100%); /* W3C */
        border-color: $white;
        color: $white;
        .dark & {
            background: $active; /* Old browsers */
            background: -moz-linear-gradient(top,  $active 0%, $active 60%, darken($active, 9) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$active), color-stop(60%,$active), color-stop(100%,darken($active, 9))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  $active 0%,$active 60%,darken($active, 9) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  $active 0%,$active 60%,darken($active, 9) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  $active 0%,$active 60%,darken($active, 9) 100%); /* IE10+ */
            background: linear-gradient(to bottom,  $active 0%,$active 60%,darken($active, 9) 100%); /* W3C */
            border-color: $white;
        }
    }
    &:not(:first-child){
        margin-left: 5px;
    }
    span {
        vertical-align: top;
    }
    &.list-and-details{
        display: inline-block;
        float: right;
    }
    &.button-disabled {
        opacity: 0.5;
        cursor: auto;

        &:focus {
            outline: none;
        }
    }
}