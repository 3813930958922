/**
 * Returns a random value of `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#random-value
 *
 * @param {List} $list - list to random value from
 *
 * @throws Cannot find a random value in an empty list.
 *
 * @requires sl-is-empty
 *
 * @example
 * sl-random-value(a b c)
 * // a
 * 
 * @return {*}
 */

@function sl-random-value($list) {
  @if sl-missing-dependencies(sl-is-empty) == true { @return null; }

  @if sl-is-empty($list) {
    @warn "Cannot find a random value in an empty list.";
    @return null;
  }

  @return nth($list, random(length($list) - 1) + 1);
}

/**
 * @requires sl-random-value
 * @alias sl-random-value
 */

@function sl-roll($list) {
  @return sl-random-value($list);
}

/**
 * @requires sl-random-value
 * @alias sl-random-value
 */
 
@function sl-luck($list) {
  @return sl-random-value($list);
}