// Using Rems with Sass: http://davidensinger.com/2013/03/using-rems-with-sass/
// Podemos pasar varios tipos de unidades (px, rem, %).
// Si no definimos unidades se entiende que son rems.
// Cuando son px o % se convierte a rem y se provee fallback en px.
// Examples:
// @include rem(font-size, 14px);
// @include rem(margin, 0 auto 1);
// @include rem(padding-bottom, 3%);
@mixin rem($property, $values) {
	// Create a couple of empty lists as output buffers.
	$font-size: $font-base-size;
	$px-values: ();
	$rem-values: ();

	// Loop through the $values list
	@each $value in $values {
		// For each property value, if it's in rem or px, derive both rem and
		// px values for it and add those to the end of the appropriate buffer.
		// Ensure all pixel values are rounded to the nearest pixel.
		@if $value == 0 or $value == 0px {
			// 0 -- use it without a unit
			$px-values: join($px-values, 0);
			$rem-values: join($rem-values, 0);
		} @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
			// px value given - calculate rem value from font-size
			$new-rem-value: strip-unit($value / $font-size);
			$px-values: join($px-values, round($value));
			$rem-values: join($rem-values, #{$new-rem-value}rem);
		} @else if type-of($value) == number and not unitless($value) and (unit($value) == "%") {
			// % value given - don't add px or rem
			$px-values: join($px-values, #{$value});
			$rem-values: join($rem-values, #{$value});
		} @else if $value == auto {
			// auto - don't add px or rem
			$px-values: join($px-values, auto);
			$rem-values: join($rem-values, auto);
		} @else {
			// unitless value - use those directly as rem and calculate the px-fallback
			$px-values: join($px-values, unquote( round($value * $font-size) + "px" ) );
			$rem-values: join($rem-values, #{$value}rem);
		}
	}

	// output the converted rules
	#{$property}: $px-values;
	#{$property}: $rem-values;
}