* {
	box-sizing: border-box;
}
a {
	cursor: pointer;
}
html, body
{
	height: 100%;
	overflow: auto;
	color: $default-text-color;
}
body
{
	font-family: $main-font-family;
	font-weight: 200;
	margin: 0;
	padding: 0;
	font-size: $main-font-size;
	background-color: $main-background-color;
}
@-moz-document url-prefix() {
	body {
		*:not([class^="icon-"]) {
			text-shadow: 0 0 0;
		}
		[class^="icon-"]{
			font-size: 15px;
		}
	}
}
.container
{
	min-width: 980px;
	max-width: 1200px;
	margin: 0 auto;
}
#popup-layer {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0;
}
@import "busy-indicator";
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { transform:rotate(360deg); } }
#menu {
	min-height: 43px;
}

.ribbon {
	position: absolute;
	left: 155px;
	top: -3px;
	width: 37px !important;
	height: 82px !important;
}