.multiple-add-form {
    width: 290px;
    margin-left: 12px;

    // .select2-container {
    //     width: 100%;
    // }

    .label-disabled {
        color: $black50;
    }

    .cols--noresize {
        .field {
            padding-left: 0 !important;
            width: 70px;
        }
        .field:last-of-type {
            width: 85px;
        }
    }
}

.multiple-add-example {
    text-align: center;
    margin: 0 0 16px 0 !important;
    line-height: 24px;
    position: relative;
    top: -5px;

    .multiple-add-name {
        background-color: $black95;
        color: $black60;
        font-weight: 600;
        border-radius: 5px;
        padding: 8px 5px;
        margin: 0 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        line-height: 16px;
        max-width: 240px;
        position: relative;
        top: 11px;
    }
}

// El botón de multiple-add lleva dentro dos iconos de add que tienen que
// mostrarse desplazados uno encima del otro
.multiple-add {
    text-transform: none;
    white-space: nowrap;
	width: 35px;

	& .icon-add:first-of-type {
		top: 0;
		left: 0;
		background-color: $primary-background-color;
		z-index: 1;
		width: 15px;
		height: 16px;
		border: 1px $primary-background-color solid;
		border-radius: 7px;
		line-height: 0;
	}

	&:hover .icon-add:first-of-type, &:focus .icon-add:first-of-type, &:active .icon-add:first-of-type {
		background-color: $primary-background-color--hover;
		border-color: $primary-background-color--hover;
	}

	& .icon-add {
		position: relative;
		margin: 0;
		padding: 0;
		left: -20px;
		top: -4px;
	}
}