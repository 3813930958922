/**
  * Por defecto será .fields--inline--stacked
  **/
field{
    display: block;
}

.fields {
    &:not(.no-margin-left){
        margin-left: -16px; 
    }
    text-align: left;
    .field {
        display: inline-block;
        vertical-align: top;
        position: relative;
        max-width: 100%; /* para que los select2 no se salgan de las agrupaciones */
        &:not(.no-margin-bottom){
            margin-bottom: 16px;
        }
        &.half-margin-bottom {
            margin-bottom: 8px;
        }
        &:not(.no-padding){
            padding-left: 16px; 
        }
        &.field--double-padding {
            padding-left: 32px; 
        }
        &.field--half-padding {
            padding-left: 8px; 
        }
        &.field--flex-simple {
            /* necesitamos el !important, por lo que no se puede usar el mixin */
            display: -ms-flexbox !important;
            display: flex !important;
        }
        .link {
            color: $keycolor;
            font-weight: 400;
            
            &:hover {
                color: $active;
            }
        }
        
        .discard-link--inline {
            display: inline-block;
        }
        
        .discard-link--float {
            margin: 0;
            float: right;
            display: block;
        }
        
        label {
            &:not(.without-display){
                display: block;
            }
            min-height: $main-font-size*1.2;
            &.label--partition {
                font-size: 15px;
            }
            &.label--lighter {
                font-weight: 200;
            }
            &.label--align-right{
                text-align: right;
            }
            &.label--align-center{
                text-align: center;
            }
            &.label--double-bottom-margin{
                margin-bottom:16px;
            }
            &.label--margin-top-5{
                margin-top:5%;
            }
            &.label--margin-top-8px{
                margin-top:8px;
            }
            &.label--margin-top-16px{
                margin-top:16px;
            }
            &.label--bolder{
                font-weight: 400;
            }
            &.label--white{
                color: $white;
            }
            &.label--right{
                float:right;
            }
            &.label--no-top-margin{
                margin-top: 0;
            }
            &.label--top-padding-8{
                padding-top: 8px;
            }
            &.label--left-padding-16{
                padding-left: 16px;
            }
            &.label--right-padding-5{
                padding-right: 5px;
            }
            &.label--font-16{
                font-size: 16px;
            }            
            &.label--font-17{
                font-size: 17px;
            }            
            &.label--font-18{
                font-size: 18px;
            }
            &.label--min-140px-width{
                min-width: 140px;
            }
            &.label--min-125px-width{
                min-width: 125px;
            }
            &.label--min-100px-width{
                min-width: 100px;
            }
            &.label--min-50px-width{
                min-width: 50px;
            }
            &.label--margin-right-8px{
                margin-right: 8px;
            }
            &.label--margin-right-14px{
                margin-right: 14px;
            }
            &.label--vertical-middle{
                vertical-align: middle;
            }
        }
        .ctrl {
            display: block;
            vertical-align: inherit;
            
            &.ctrl--inline{
                display: inline-block;
            }

            .field__info--right{
                margin-left: 8px;
            }
            
            .field__info-focus, .field__info {
                display: block;
                margin-top: 5px;
                color: #AAAAAA;
                margin-right: 4px;
                &.visibility-hidden{
                    color: $white;
                }
                .field__info-icon {
                    padding: 2px 4px;
                    font-size: 12px;
                    font-weight: 400px;
                    color: $white;
                    background: #AAAAAA;
                }
                .field__info-high {
                    font-weight: $bold-weight;
                }
            }

            .field__info-focus{
                font-size:$main-font-size;
                opacity: 0.7;
            }

            .field__info{
                font-size:14px;
            }

            &.right{
                float: right;
            }
        }
        &.field--bottom {
            vertical-align: bottom;
        }
        &.field--inline {
            vertical-align: middle;
            .ctrl:not(.no-padding:first-child){
                padding-left: 5px;
            }
            label{
                min-height: 0;
                vertical-align: middle;
                // &:not(:last-child){
                //  margin-right: 5px;   
                // }
                &.field__label--radiocheck:not(.dont-calc-max) {
                    max-width: calc(100% - 20px);
                    vertical-align: top;
                }
                &.field__label--select {
                    padding-top: 12px;
                }
            }
            label:not(.ignore-inline), .ctrl {
                display: inline-block;
                margin-bottom: 0;
            }
            .composed-radiocheck {
                display: inline-block;
                min-height: 0;
                max-width: calc(100% - 20px);
                vertical-align: top;
            }
        }
        &.field--inline__full-width{
            @include flexbox;
            @include align-items(center);
            vertical-align: middle;
            .ctrl:not(:first-child){
                padding-left: 15px;
            }
            .ctrl {
                @include flex(1 1 auto);
                input {
                    width: 100%;
                }
            }
        }
        &.field--flex{
            @include flexbox;
            @include flex-direction(row);
            &.flex-column{
                @include flex-direction(column);
            }
            &.flex-inline{
                @include inline-flex;
            }
            &.flex-center {
                @include align-items(center);
                vertical-align: middle;
            }
            &.flex-full{
                @include flex(1 1 auto);
            }
            .ctrl{
                &.flex-auto{       
                    @include flex(1 1 auto);
                    padding-left: 16px;
                    input {
                        width: 100%;
                    }
                    &.half-padding{
                        padding-left:7px;
                    }
                }
                &.overflow--hidden{
                    overflow: hidden;
                }
            }
        }
        &.field--vertical--top{
            vertical-align: top;
        }
        &.field--vertical--middle{
            vertical-align: middle;
        }
        &.field--vertical--bottom{
            vertical-align: bottom;
        }
        &.field--no-left-padding {
            padding-left: 0px;
        }
        &.field--left-padding-5 {
            padding-left: 5px;
        }
        &.field--left-padding-12 {
            padding-left: 12px;
        }
        &.field--left-padding-25 {
            padding-left: 25px;
        }
        &.field--no-bottom-margin{
            margin-bottom: 0;
        }
        &.field--half-bottom-margin{
            margin-bottom: 8px;
        }
        &.field--nolabel {
            .ctrl {
                margin-top: 25px;
            }
        }
        &.field--no-input {
            label {
                margin-top: 30px;
            }
        }
        &.field--has-min-width{
            min-width: 70px;
        }
        &.field--has-min-110-width{
            min-width: 110px;
        }
        &.field--has-min-120-width{
            min-width: 120px;
        }
        &.field--has-min-130-width{
            min-width: 130px;
        }
        &.field--has-min-150-width{
            min-width: 150px;
        }
        &.field--has-min-200-width{
            min-width: 200px;
        }
        &.field--has-230-width{
            width: 230px;
        }
        &.field--has-fourty-min-width{
            min-width: 40%;
        }
        &.field--has-half-min-width{
            min-width: 50%;
            display: inline-block;
        }
        &.field--has-fourty-width{
            width: 40%;
        }
        &.field--has-half-width{
            width: 50%;
        }
        &.field--has-48-width{
            width: 48%;
        }
        &.field--has-full-width{
            width: 100%;
        }
        &.field--radiocheck {
            .ctrl, .field__label--radiocheck {
                display: inline-block;
                vertical-align: middle;
                margin: 0;
                &.heavy-font {
                    font-weight: 400;
                }
            }
            .ctrl input[type=checkbox],input[type=radio] {
                display: inline-block;
                vertical-align: bottom;
                margin-bottom: 3px;
                &.margin-top-10px{
                    margin-top:10px;
                }
                &.margin-top-16px{
                    margin-top:16px;
                }
            }
            [disabled] + .field__label--radiocheck {
                opacity: .67;
            }

            &.field--radiocheck--multilabel {
                & > .ctrl {
                    vertical-align: top;
                    margin-top: 2px;
                }

                .field--radiocheck--multilabel-container {
                    display: inline-block;
                    width: calc(100% - 20px);

                    .ctrl {
                        margin-top: 2px;
                    }

                    label {
                        vertical-align: top;
                    }

                    .spinner-wrapper {
                        top: -1px;
                        height: 17px;
                        overflow: visible;
                    }
                }
            }
        }
        &.field--margin-bottom-extra {
            margin-bottom: 25px;
        }
        .field__label--radiocheck {
            font-weight: 200;
            font-size: 15px;
        }
        &.field--margin-bottom-small {
            margin-bottom: 5px;
        }
        &.field--margin-left-half-extra {
            margin-left: 10px;
        }
        &.field--margin-left-extra {
            margin-left: 20px;
        }
        &.field--without-check-margin-left-extra {
            margin-left: 16px;
        }
        &.field--margin-right-extra {
            margin-right: 20px;
        }
        &.field--prevent-overflow {
            max-width: 100%;
        }
        &.field--margin-top-3px{
            margin-top: 3px;
        }
        &.field--no-label {
            padding-top: 24px;//18px; faltaba sumar los 6 pixeles de margen que tienen los label
        }
        .subctrl {
            margin-top: 8px;
            .subctrl--input {
                display: inline-block;
                margin-bottom: 0;
                vertical-align: middle;
            }
            label {
                display: inline-block;
                margin-bottom: 0;
            }
        }
        &.with-right-margin {
            margin-right: 16px;
        }
    }
    &.fields--spaceless {
        > .field {
            margin: 0;
        }
    }
    &.fields--no-margin-bottoms {
        .field {
            margin-bottom: 0;
        }
    }
    &.grid {
        .field {
            padding-left: 16px;
            input[type=text]:not(.fullpicker-time), input[type=password], input[type=number] {
                width: 100%;
            }
        }
    }
    
    &.fields--vertical--stacked {
        > .field {
            display: block;
            &.inline--block {
                display: inline-block !important;
            }
            > .field--radiogroup {
               padding-bottom: 5px; 
               margin-left: 7px;
            }
        }
    }
    &.fields--vertical--inline {
        .field {
            display: block;
            label, .ctrl {
                display: inline-block;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    &.fields--tab {
        margin-left: 0;
    }
    &.fields--radiocheck-group{
         .field:not(:last-child){
             margin-bottom: 8px;
         }   
    }
    &.fields--radiogroup-align {
        margin-left:8px;
    }

    &.fields--inverse{
        .field{
            padding-left: 0;
            margin-right: 16px;
        }
    }
    
    &.padded {
        display: inline-block;
        padding: 6px 0 12px 0;
        margin-left: 0;
        width: 100%;

        & > :last-child
        {
            margin-right: 15px;
        }
    }
    
    &.highlight {
        background-color: #F5F5F5;
        border-radius: 3px;
        margin-bottom: 15px;
        width: auto;
        > .field {
            margin-bottom: 0;
        }
    }

    &.fields--flex{
        @include flexbox;
        @include justify-content(space-between);
        @include flex-direction(row);
        .field{
            @include flex(1 0 auto);
            .ctrl{
                input{
                    width: 100%;
                }
            }
            &.field--flex-min{
                @include flex(0 1 auto);
            }
        }
    }

    &.fields--flex-simple{
        @include flexbox;
    }

    &.fields--half-width{
        display: inline-block;
        width: 50%;
        white-space: nowrap;
        vertical-align: top;
        margin-bottom: 5px;
        &.normal-wrap{
            white-space: normal;
        }
    }
    &.fields--inline{
        display: inline;
        &.has-right-margin{
            margin-right: 16px;
        }
    }
    &.fields--flex-auto {
        @include flex(0 0 auto);
    }
    &.fields--center{
        text-align: center;
    }
}

.field--xxl ~ .field__info{
    width: $field-xxl-size;
}

.field--xl ~ .field__info{
    width: $field-xl-size;
}

/* List And Details Fields */

.field--list-and-detail-fix {
    width: 285px;
}

.field--list-and-detail-flex {
    width: calc(100% - 290px);
}

.fieldset {
   
    .legend {
        text-transform: uppercase;
        color: $black40;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 8px;
    }
}

.fields-table {
    display: table;
    border-spacing: 8px;
    > .fields {
        display: table-row;
        > .field {
            display: table-cell;
            &.field--inline {
                label:not(.label--no-top-margin) {
                    margin-top: 6px;
                }
            }
            &.field--radiocheck{
                white-space: nowrap;
                .ctrl input[type=checkbox],input[type=radio] {
                    display: inline-block;
                    vertical-align: middle;
                    margin-bottom: 4px;
                }   
            }
        }
    }
}

.field__text {
    display: inline-block;
    margin-top:5px;
    &.field__text--light{
        color: #9A9A9A;
    }
    &.field__text--bold{
        font-weight: 400;
    }    
    &.field__text--white{
        color: $white;
    }    
    &.field__text--8px-top-margin{
        margin-top: 8px;
    }    
    &.field__text--2px-top-margin{
        margin-top: 2px;
    }
    &.field__text--4px-top-margin{
        margin-top: 4px;
    }
    &.field__text--9px-top-margin{
        margin-top: 9px;
    }
    &.field__text--no-top-margin{
        margin-top:0;
    }
    &.field__text--is-container{
        display: block;
        text-align: center;
        &.container--left{
            text-align: left;
        }
        &.container--left--padding-20{
            padding-left: 20px;
        }
    }
    &.field__text--ellipsis{
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        &.max-width{
            width: auto;
            max-width: 100%;
        }
    }
    &.field__text--is-block{
        display: block;
    }
}

.field__container--table{
    display: table;
    min-height: 55px;
    margin: 0 auto;
    .field__text{
        display: table-cell;
        vertical-align: middle;
    }
}

fieldset {
    border: 1px solid $black90;
    border-radius: $detail-box-radius;
    > legend {
        padding: 0 5px;
        font-weight: 600;
    }
    &.options-group {
        border-radius: 5px; 
    }
    &.center-legend{
        > legend{
            margin-left: auto;
            margin-right: auto;
        }
    }
}
