.operation-type
{
    display: inline-block;
    padding: 3px 6px;
    border-radius: 5px;
    font-size: 13px;

    &.openings {
        color: #468847;
        background-color: #DFF0D8;
    }

    &.rejections {
        color: #B94A48;
        background-color: #F2DEDE;
    }

    &.alarms {
        color: #FF9900;
        background-color: #FFE4B9;
    }

    &.com-status {
        color: #FFFFFF;
        background-color: #949494;
    }
}

.button-and-filters {
    display: table;

    .button--wrapper {
        display: table-cell;
        vertical-align: top;
    }

    button {
        overflow: hidden;
        white-space: nowrap;
    }

    applied-filters {
        display: table-cell;
        padding-left: 12px;
        position: relative;
        top: 1px;
    }
}

.add-delete-row {
    height: 51px;
    padding: 8px;
    background-color: $black97;
    border-top: 1px solid $black90;

    &.align-right {
        text-align: right;
    }

    .button-list {
        padding: 0;
    }

    &.fake {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}

.advanced-filter-tree {
    
    .detail-box__content {
        margin: 0;
        padding: 0;

        .fields {
            height: 170px;
            padding-top: 8px;
            margin: 0 !important;
            overflow: auto;

            field.selected {
                & > div {
                    background-color: $active;
                }
            }

            field:not([class^=vs-repeat-]) {
                height: 24px;
                
                & > div {
                    height: 24px;
                }
            }
        }

        tree-children field {
            padding: 3px 3px 3px 13px;
        }
    }

    .table-padding {
        padding: 16px 10px;
    }

    .tree-level-1, .tree-level-2 {
        white-space: nowrap;
        display: inline-block;
        min-width: 100%;

        &:hover {
            background: transparentize($keycolor,0.25) !important;
        }
    }

    .tree-level-1 {
        padding: 3px 3px 3px 8px;
    }

    .tree-level-2 {
        padding: 3px 3px 3px 50px;
    }

    .tree-grid-first-cell__toggle, .tree-grid-first-cell__content {
        display: inline-block !important;
    }

    .icon {
        font-size: 16px;
    }
}

.filter-partitions {
    .detail-box__content {
        margin: 0;
        padding: 0;
    }

    .fields {
        height: 235px;
        overflow: hidden;
        padding: 0 0 0 16px;

        .partition-wrapper {
            padding: 15px 0 2px 0;
            height: 100%;
            width: 100%;
            overflow: auto;
        }

        field {
            display: block;
            margin-bottom: 8px !important;
            
            &.field--indent {
                padding-left: 32px !important;
            }

            label {
                white-space: nowrap;
            }
        }
    }
}

.tabs {
    .add-delete {
        height: 300px;

        @media only screen and (min-height : 800px) and (min-width : 1224px){
            height: 450px;
        }
    }

    .absolute-tab {
        position: absolute;
        top: 4px;
        left: 15px;
    }
}

.advanced-filter-tree-size {
    width: 770px;
    height: 301px;

    @media only screen and (min-width : 1224px) {
        width: 982px;
    }

    @media only screen and (min-height : 800px) and (min-width : 1224px){
        height: 451px;
    }
}

.any-item {
    font-style: italic;
    color: $black60;
}

.strike {
    text-decoration: line-through;
}

.ngdialog--when {
    .ngdialog-content {
        width: 94%;
        min-width: 850px;//950px;
        max-width: 1030px;
    }

    .ngdialog__content {
        padding: 0 !important;
    }

    .table-wrapper {
        width: calc(100% - 300px);
        height: 450px;
        display: block;
        float: left;
        td {
            height: 50px;
        }
        
        .button-round {
            .icon-edit, .icon-delete {
                position: relative;
                top: -1px;
                left: -1px;
            }
        }
    }

    .side-form {
        display: inline-block;
        width: 300px;
        height: 450px;
        position: relative;
        z-index: 1;
        padding: 0 0 15px 15px;
        vertical-align: top;
        color: $white;
        background-color: $black50;

        label {
            color: $white;
        }

        .margin-small {
            margin-bottom: 10px;
        }

        .select2-container {
            color: $black40;
        }

        .from-to {
            & > input {
                vertical-align: top;
            }

            .inline-fullpicker {
                display: inline-block;
                margin-right: 5px;

                label {
                    display: block;
                    margin-bottom: 10px;
                }
            }
        }

        .weekday {
            margin: 10px 0;
        }

        .correct-fullpicker input {
            margin-left: 0 !important;
        }
    }

    &.ngdialog--when__basic-true {
        .table-wrapper {
            width: calc(100% - 280px);
        }
        .side-form {
            width: 280px;
        }
    }
}

.purge-dialog {
    width: 450px;
    padding: 15px 13px 15px 15px;

    .verify-button {
        position: relative;
        top: 2px;
    }

    .grid {
        margin-left: 0;
    }
}

.audit-trail-export-when .detail-box {
    margin-bottom: 0 !important;
}

.when-dialog-table {
    td {
        border-bottom: 1px $black80 solid;
    }

    tr:not(.selected):not(:hover) td {
        background-color: $white !important;
    }

    th.buttons, td.buttons {
        padding: 0 6px;
        width: 88px;

        button:not(:first-child)
        {
            margin-left: 3px;
        }
    }
}

.fake-tabindex-button {
    opacity: 0;
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: none;
    cursor: inherit;
}

audit-trail-advanced-filter select[select2]
{
    width: 0 !important;
}

audit-trail-advanced-filter detail-box .fields .field {
    max-width: calc(100% - 1px);
}