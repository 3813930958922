#home2Elements{
    @include inline-flex;
    button{
        width: 330px;
        height: 330px;
       
    }
    .button1{  
        border-right: solid 1px #e8e8e8;
    }
    .button2{
        border-left: solid 1px white; 
    } 
}