#loggedout {
	height: 100%;
	background: white;
	.screen {
		height: 100%;
		.background {
			position: absolute;
			margin-top: 90px;
			height: calc(100% - 90px);
			width: 54%;
			background-image: url('../img/login-background.jpg');
			background-position: top left;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.stroke {
			width: calc(50% + 260px);
			height: calc(100% + 120px);
			right: 0px;
			margin-top: -400px;
			margin-right: -260px;
			position: absolute;
		}
		.top-gradient {
			top: 0;
			background: linear-gradient(rgba(0, 0, 0, 0.24) 0px, rgba(0, 0, 0, 0) 40px);
			width: 100%;
			height: 40px;
			position: absolute;
			.about {
				position: absolute;
				right: 0;
				padding: 5px;
				button {
					background: none;
					padding: 0;
					border: none;
					font-size: 15px;
					color: #7a7a7a;
					&:hover {
						color: #9d9d9d;
					}
				}
			}
		}
		.content {
			margin: 0;
			height: 100%;
			@include flexbox;
			@include align-items(center);
			@include justify-content(center);
			text-align: center;
			.welcome {
				color: $keycolor;
				font-weight: 200;
				font-size: 30px;
				margin-top: 50px;
			}
			.subtitle {
                font-family: 'Helvetica Ce';
				color: $keycolor;
				font-weight: 200;
				font-size: 20px;
				margin-bottom: 50px;
			}
			img {
				height: 63px;
			}
			input {
				margin: auto;
				margin-bottom: 16px;
				width: 200px;
				display: block;
			}
            
            .licensing-msg{
				color: $black60;
				font-weight: 200;
				font-size: 30px;
				margin-top: 50px;
            }
			
            .licensing-subtitle{
                margin: 10px 0 0 0;
                color: $keycolor;
                font-size: 18px;
                a{
                    color: $keycolor;
                    text-decoration: none;
                }
            }
			
			#license-activation-component {
				input {
					margin-bottom: 0;
					display: inline-block;
				}
			}
		}
	}
    .separator {
        position: relative;
        // top: 0;
        height: 40px;
        left: 0;
        width: 100%;
        background: url("../img/line.png") center center no-repeat;
    }
}


.login {
        .welcome {
            color: $keycolor;
            font-weight: 200;
            font-size: 30px;
            margin-top: 50px;
        }
        .subtitle {
            font-family: 'Helvetica Ce';
            color: $keycolor;
            font-weight: 200;
            font-size: 20px;
            margin-bottom: 50px;
        }
        img {
            height: 63px;
        }
        input {
            margin: auto;
            margin-bottom: 16px;
            width: 200px;
            display: block;
        }
        input[type=checkbox] {
            display: inline-block;
            width: auto;
            vertical-align: middle;
        }
        label {
            margin-bottom: 16px;
            display: inline-block;
            vertical-align: middle;
        }
}

.reset-password {
	.password-change-reason {
		display: inline-block;
		font-weight: 800;
		font-size: 18px;
		margin-bottom: 40px;
		width: 350px;
	}
	label {
		display: inline-block !important;
		margin-top: 8px;
		margin-right: 16px;
		font-size: 15px;
	}
	.reset-password--form {
		min-height: 205px;
	}
}

.enforced-password-requirements {
    text-align: left;
    margin-bottom: 16px;  
    padding: 8px;
    ul {
        margin: 4px 0px;
    }
}
