.operator-group--tree.fields {
    tree-grid-toggle, field {
        vertical-align: middle;
    }
    tree-grid-toggle {
        display: inline-block;
    }
}
.operator-group--partition-permissions {
    .detail-box__title {
        @extend .trim-with-ellipsis
    }
}
.operator-group--default-permission-column {
    width: 190px;
    text-align: center;
}
.operator-group--has-access-column {
    width: 90px;
    text-align: center;
}
.operator-group--partitions-table {
    table {
        table-layout: fixed;
    }
}
.content .operator-detail {
    .content__status-bar .status-bar__group button {
        margin-left: 8px;
    }
    .content__status-bar .status-bar__block {
        padding-right: 8px;
    }
}

.locked-operator-icon {
    position: relative;
    bottom: 2px;
    left: 1px;
}

#operators__content__body .icon-lock {
    position: relative;
    left: 4px;
}
.operator-group--tree {
    outline: 0px;
}