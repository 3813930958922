/**
 * Tests whether `$list` has at least 2 values.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-has-multiple-values
 *
 * @param {List} $list - list to run test against
 *
 * @example 
 * sl-has-multiple-values(a)
 * // false
 *
 * @example 
 * sl-has-multiple-values(a b)
 * // true
 * 
 * @return {Bool}
 */
 
@function sl-has-multiple-values($list) {
  @return length($list) > 1;
}
