#home3Elements{
    height: 330px;
    display: inline-block;

    .primaryButton{
        width: 330px;
        height: 330px;
    }

    .twoRows{     
        float: left; 
        span{
            font-size: 27px;
        }
        .icon{
            font-size: 45px;
        }
        button{
            display:block;
            width: 165px;
            height: 165px;
        }        
    }
    .button1{  
        border-right: solid 1px #e8e8e8;
        float: left;
    }
    .button2{
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8;
    } 
    .button3{
        border-left: solid 1px white; 
        border-top: solid 1px white;
    } 
}