.button-group {
	@include inline-flex;
	@include align-items(center);
	background: transparentize($black40, .8);
	border-radius: 4px;
	padding: 4px;
	.content__footer & {
		margin: -4px 0;
	}
	.button-group__title {
		text-transform: uppercase;
		font-weight: 800;
		padding: 0 8px;
		font-size: 15px;
	}
	button {
		margin-right: 4px;
		&:last-child {
			margin-right: 0;
		}
	}
}
