.select2-selection--single {
  background: linear-gradient(to bottom, rgba(255,255,255,0.05) 0%,rgba(255,255,255,0.05) 60%,rgba(0,0,0,0.1) 100%);
  background-color: $white;
  border: 1px solid $black80;
  border-radius: 4px;
  height: 34px;
  outline: none;
  
  .select2-selection__rendered {
    line-height: 32px;
    white-space: nowrap;
    word-wrap: normal;
    .select2-selection__text {
      display: inline-block
    }
    .select2-selection__img {
      display: inline-block;
      margin-right: 5px;
    }
    .title {
      display: inline;
    }
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
  }

  .select2-selection__placeholder {
    color: #999;
    @include user-select(none);
  }

  .select2-selection__arrow {
    height: 32px;

    position: absolute;

    top: 1px;
    right: 1px;

    width: 20px;

    b {
      @extend %icon;
      @extend .icon-arrow-down;
      font-size: 10px;
      color: $black20;
      /*
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      */
      height: 0;
      left: 50%;

      margin-left: -6px;
      margin-top: -4px;

      position: absolute;

      top: 50%;
      width: 0;
    }
  }

  &:hover {
    border-color: $keycolor;
  }
  &:focus {
    border-color: $active;
  }
}

&[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
}

&.select2-container--disabled {
  .select2-selection--single {
    background-color: $black90;
    cursor: default;
    color: $black60;
    
    .select2-selection__arrow b {
      color: $black60;
    }

    [readonly] ~  & {
        background: transparent;
        border: 0;
        .select2-selection__arrow {
            display: none;
        }
        .select2-selection__rendered {
            padding-left: 0;
            color: $black60;
        }
    }
    .select2-selection__clear {
      display: none;
    }
    &:hover, &:focus {
      border-color: #cccccc;
    }
  }
}

&.select2-container--open {
  .select2-selection--single {
    border-color: $active;
    .select2-selection__arrow {
      b {
        border-color: transparent transparent #888 transparent;
        border-width: 0 4px 5px 4px;
      }
    }
  }
}