.locker-status-icon{
    float: right;
    margin-top: -3px;
    margin-left: 2px;
    display: block;
}

.locker-status-label{
    display: block;
    float: left;
    margin-top: 1px;
    font-weight: 200;
}

.locker-options-detail-box{
    .detail-box{
            height: 276px;
    }
}