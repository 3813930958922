@import
"hscale",
"rem",
"ir",
"user-select",
"columnizer",
"background-clip",
"clearfix2",
"ellipsis",
"listify",
"margin0",
"stylize",
"gradients",
"viewport-sizes",
"filter-button";



// Margins
@mixin margin-double 	{@include rem(margin-bottom, $line-height-base * 2);}
@mixin margin-normal 	{@include rem(margin-bottom, $line-height-base);}
@mixin margin-half 		{@include rem(margin-bottom, $line-height-base / 2);}
@mixin margin-third 	{@include rem(margin-bottom, $line-height-base / 3);}
@mixin margin-quarter 	{@include rem(margin-bottom, $line-height-base / 4);}
@mixin margin-zero 		{margin-bottom: 0;}



// Line heights
@mixin lineh-normal 	{line-height: $line-height-base;}		// 1.5
@mixin lineh-medium 	{line-height: $line-height-base / 1.2;}	// 1.25
@mixin lineh-small 		{line-height: $line-height-base * .75;}	// 1.125
@mixin lineh-low 		{line-height: 1;}