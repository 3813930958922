// Compass tiene mixins de columnas (http://compass-style.org/reference/compass/css3/columns/) pero no tiene una que tenga todos estos parámetros
@mixin columnizer ($column-width: 250px, $column-count: 0, $column-gap: 50px, $column-rule-color: #EEE, $column-rule-style: solid, $column-rule-width: 1px) {
	@include column-width ($column-width);
	@include column-count ($column-count);
	@include column-gap ($column-gap);
	@include column-rule-color ($column-rule-color);
	@include column-rule-style ($column-rule-style);
	@include column-rule-width ($column-rule-width);
}

@mixin cols-2 {@include columnizer (48%, 2, 2%, transparent, none, 0);}
@mixin cols-3 {@include columnizer (31.3333%, 3, 2%, transparent, none, 0);}
@mixin cols-4 {@include columnizer (23%, 4, 2%, transparent, none, 0);}
@mixin cols-5 {@include columnizer (18%, 5, 2%, transparent, none, 0);}