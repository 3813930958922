// Clearfix (h5bp.com/q): Compass tiene un clearfix diferente y a veces con elementos posicionados en absoluto y los oculta debido a overflow:hidden;
@mixin clearfix2 {
	*zoom: 1;

	&:before,
	&:after {
		display: table;
		content: "";
		// Fixes Opera/contenteditable bug:
		// http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
		line-height: 0;
	}

	&:after {
	clear: both;
	}
}