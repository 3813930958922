#silverlight
{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 1px;
	width: 1px;
	overflow: hidden;
    &.visible {
		height: 100%;
		width: 100%;
		overflow: visible;
		z-index: $silverlight-z-index;
    }
}

#silverlight object
{
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	min-width: 980px;
	min-height: 660px;
	display: block;
}

#angular
{
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	overflow: hidden;
	min-width: 980px;
	min-height: 660px;
}