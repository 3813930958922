/**
 * Removes duplicate values from `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-remove-duplicates
 *
 * @param {List} $list - list to remove duplicates from
 *
 * @requires sl-to-list
 *
 * @example
 * sl-remove-duplicates(a b a b)
 * // a b
 *
 * @return {List}
 */

@function sl-remove-duplicates($list) {
  @if sl-missing-dependencies(sl-to-list) == true { @return null; }

  $result: ();

  @each $item in $list {
    @if not index($result, $item) {
      $result: append($result, $item, list-separator($list));
    }
  }

  @return sl-to-list($result);
}

/**
 * @requires sl-remove-duplicates
 * @alias sl-remove-duplicates
 */
 
@function sl-unique($list) {
  @return sl-remove-duplicates($list);
}