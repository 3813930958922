/*
.limited-occupancy-monitoring {
	.table-container {
		overflow: hidden;
		tr.selected{
			border: 1px solid #008469;
			box-sizing: border-box;	
			&.tree-grid-row--level-2 td:first-child{
				padding-left: 49px;
			}
		}
		tr:focus:not(.selected){
			border: 1px dashed #008469;
			box-sizing: border-box;			
		}
		tr.selected:focus{
			border: 1px dashed $keycolor;
			box-sizing: border-box;			
		}
	}
}
*/
.single-input-dialog {
	min-width: 300px;
	margin: 16px;
}

tbody{
	td.monitoring-min-size{
		width: 70px;
		text-align: center;
	}
}
