/**
 * Turns multidimensional `$list` into a one-level list.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#flatten
 *
 * @param {List} $list - list to flatten
 * 
 * @requires sl-has-multiple-values
 *
 * @example
 * sl-flatten(a b c, d e f, g h i)
 * // a b c d e f g h i
 *
 * @return {List}
 */

@function sl-flatten($list) {
  @if sl-missing-dependencies(sl-has-multiple-values) == true { @return null; }

  $result: ();

  @each $item in $list {
    @if sl-has-multiple-values($item) {
      $flatten: sl-flatten($item);
      
      @each $i in $flatten {
        $result: append($result, $i, list-separator($list));
      }
    }

    @else {
      $result: append($result, $item, list-separator($list));
    }
  }

  @return $result;
}

/** 
 * @requires sl-flatten
 * @alias sl-flatten
 */

@function sl-unfold($list) {
  @return sl-flatten($list);
}