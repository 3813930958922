.ngdialog {
	box-sizing: border-box;
	//display: none; /* hide all dialogs except the last one (see &:last-of-type) */
	visibility: hidden;
	position: absolute;
	-webkit-overflow-scrolling: touch;
	z-index: $dialog-z-index;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	@include flexbox;
		
	*, *:before, *:after {
		box-sizing: inherit;
	}

	&:last-of-type {
		visibility: visible;
		-ms-flex-align: center; /* IE 10 */
		align-items: center;
	}

	h1 {
		color: #666666;
		float: left;
		font-size: 1.5em;
		width: calc(100% - 73px); /* 73 === width de ngdialog__close + (margin-left + margin-right) de este h1 */
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
        font-weight: 800;
		// The content inside might have several heights; for instance, a title
		// in Spanish has a height of 23px and in Russian, 27px. This allows us
		// to always display the title centered.
		line-height: 53px;
		margin: 0 5px 0 13px;
	}

	h2 {
		float: none !important;
		margin-top: 0 !important;
		margin-bottom: 5px !important;
        font-weight: 800 !important;
	}

	.ngdialog-content {
		border-radius: 5px;
		border: 1px solid #999999;
		margin: 0 auto;
		max-width: 100%;
		position: relative;
		width: 425px;
		display: table;
	}
	&.ngdialog--extended .ngdialog-content {
		width: auto;
	}

	.ngdialog__header {
		height: 53px;
		background: #d7d7d7;
		background: linear-gradient(to bottom, #e6e6e6 0%,#e4e4e4 10%,#dfdfdf 20%,#e0e0e0 22%,#d4d4d4 59%,#d1d1d1 75%,#b9b9b9 100%);
		border-radius: 5px 5px 0 0;
		border-bottom: 1px solid #999999;
		.ngdialog__close {
			padding: 0;
			background: #4b4b4b;
			background: linear-gradient(to bottom, #727272 0%,#717171 4%,#2f2f2f 100%);
			border: none;
			border-left: 1px solid #999999;
			border-top-right-radius: 5px;
			height: 100%;
			display: inline-block;
			width: 55px;
			float: right;
			cursor: pointer;
			color: #ffffff;
			padding: 14px;
			font-size: 1.6em;
			&:hover {
				background: linear-gradient(to bottom, #959595 0%,#949494 4%,#888888 20%,#6d6d6d 63%,#686868 75%,#525252 100%);
			}
		}
	}

	.ngdialog__content {
		width: 100%;
		background-color: #ffffff;
		padding: 16px;
		word-wrap: break-word; /* previous drafts, used by IE and Edge */
		overflow-wrap: break-word; /* lastest draft, supported by Chrome, Firefox, Safari, Opera... */
        max-height: 525px;
		p:first-of-type {
			margin-top: 0;
			&.extra--margin {
				margin-top: 5px;
			}
		}
		p:last-child {
			margin-bottom: 0;
		}

		p.affected-items {
			max-height: 400px;
			overflow-y: auto;
		}

		.plain-container {
			min-height: 108px;
			line-height: 108px;
			vertical-align: middle;
		}
		p.plain {
			white-space: pre;
			text-align: center;
			line-height: normal;
			display: inline-block;
			width: 100%;
		}
		.ngdialog__icon {
			font-size: 2em;
		}
        &.no-bottom-padding {
            padding-bottom: 0;
        }
		.continue-option {
			h3 {
				margin-bottom: 0;
			}
			.short-separator {
				border: 1px solid;
				width: 50px;
				margin-left: calc(50% - 25px);
			}
		}
		.edit-period-dialog {
    		padding: 0 16px;
			&.fix-width{
				width: 245px;
			}
			&.add-period--event-stream{
				width: 290px;
				padding: 8px 16px;
			}
		}
		.scheduled-jobs__add-dialog{
			min-width: 340px;
			margin: 26px 25px 15px;
		}
		.change-password-dialog {
			width: 580px;
		}
		.dialog-form-line {
			min-height: 50px;
			label {
				vertical-align: middle;
    			line-height: 34px;
				display: inline-block;
    			float: right;
			}
			input {
				width: calc(100% - 10px);
			}
		}
		.detail{
			font-size: 17px;
			color: #999999;
		}
        .edit-timetable-dialog{
            width: 350px;
        }
		.edit-optional-dialog {
    		padding: 0 16px;
			margin-left: 48px;
			margin-right: 64px;
			margin-bottom: 12px;
			margin-top:12px;

			&.left-align {
				margin-left: 0;
				margin-right: 0;
				width: 324px;
			}
		}
		.content-footer {
			width: calc(100% + 32px);
			margin: 16px 0 -16px -16px;
			background: linear-gradient(to bottom, #7B7B7B 0%, #9A9A9A 25%,#7B7B7B 100%);
			padding: 16px;
			color: $white;
			h2 {
				text-transform: uppercase;
				color: $white;
				margin-bottom: 12px;
				font-size: 16px;
				font-weight: 400 !important;
			}
			input[type=text], textarea {
				width: 100%;
			}
			textarea {
				height: 70px;
			}
			.grid__item:first-child {
				padding-left: 0;
			}
			label {
				color: $white;
			}
			.content-footer--disabled label {
				color: $black80;
			}
		}
	}

	.ngdialog__footer {
		height: 53px;
		background: #d7d7d7;
		background: linear-gradient(to bottom, #e6e6e6 0%,#747474 100%);
		border-radius: 0 0 5px 5px;
		border-top: 1px solid #999999;
		.ngdialog__buttons {
			float: right;
			margin: 10px 8px 0 0;
			button {
				margin-left: 8px;
				white-space: nowrap;
			}
		}
		.ngdialog__buttons_container {
			display: table-cell;
		}
	}

	%ngdialog__content--colored {
		border: 1px solid white;
		padding: 16px;
		color: #ffffff;
		text-align: center;
        font-size: 16px;
	}

	&.ngdialog--error .ngdialog__content {
		@extend %ngdialog__content--colored;
		background-color: $color-error;
		overflow-y: auto;
		overflow-x: hidden;

		ul {
			margin-bottom: 0;
		}
		
        .more-info {
            margin-top: 1em;
            .more-info-box {
                background-color: white;
                color: $default-text-color;
                padding: 10px;
                text-align: left;
                font-size: 14px;
                margin-top: 20px;
                overflow-y: scroll;
                max-height: 160px;
				max-width: 389px;
            }
        }
	}

	&.ngdialog--warning .ngdialog__content {
		@extend %ngdialog__content--colored;
		background-color: $color-warning;
		.related-items {
			margin-bottom: 16px;
			font-size: 16px;
			.related-items__item__label, .related-items__item__value {
				display: inline-block;
			}
			.related-items__item__label {
				vertical-align: top;
				font-weight: 600;
				color: transparentize(white, .4);
			}
			.related-items__item__value {
				max-width: 300px;
			}
		}
        hr {
            border: 1px solid white;
            width: 42px;
            margin-top: -4px;
        }
        .question {
            font-size: 18px;
            font-weight: 800;
			&.general-options-warning{
				color: white !important;
				margin: 18px 0px 8px 0px;
				text-align: center;
			}
        }

		.select2-container {
			color: $black40;
		}
	}
	
	&.ngdialog--warning {
		&.ngdialog--third-party-reader {
			.fields.fields--radiocheck-group {
				display: none;
			}
		}
	}

	&.ngdialog--default-big {
		.ngdialog-content{
			width: 789px;

			@media only screen and (min-width : 1224px) {
				width: 1001px;
			}

			.ngdialog__content {
				height: 397px;

				@media only screen and (min-height : 800px) and (min-width : 1224px){
					height: 500px;
				}
			}
		}
		table {width:100%;}
		.table-container {
			width: 100%;
		}
		.table-footer {
			width: 100%;
		}
	}
    
    &.ngdialog--default-auto {
		.ngdialog-content{
			width: auto;
		}
		table {width:100%;}
		.table-container {
			width: 100%;
		}
		.table-footer {
			width: 100%;
		}
	}
    
    &.ngdialog--success .ngdialog__content {
        @extend %ngdialog__content--colored;
		color: $default-text-color;
		& .ngdialog__icon.icon-info, & h2 {
        	color: $keycolor;
		}
        hr {
            border: 1px solid $black90;
            width: 42px;
        }
    }
    
    &.ngdialog--list, &.ng-dialog--narrow-form {
		
        .ngdialog-content{
			width: 350px;
            .ngdialog__header h1{
                width: calc(100% - 73px); /* 73 === width de ngdialog__close + (margin-left + margin-right) de este h1 */
            }
            .ngdialog__content{
                min-height: 232px;
            }
        }
    }

	&.ngdialog--list {
		.ngdialog-content{
            max-height: 500px;
			
			.ngdialog__content {
                max-height: 397px;
			}

		}
	}

	&.ng-dialog--narrow-form {
		.ngdialog-content{
			max-height: 560px;

			.ngdialog__content {
				max-height: 457px;
			}
		}
	}

	&.ngdialog--crop-image {
        .ngdialog-content {
			width: 500px;
		}
    }
	
	&.ngdialog--warning__confirm-delete {
		.related-items {
			max-height: 150px;
			overflow: auto;
		}
	}
	&.ngdialog--hidden-footer {
		.ngdialog-content{
			width: auto;
		}
		.ngdialog__footer{
			.ngdialog__buttons{
				display: none;
			}
		}
	}
}

.ngdialog-overlay {
	position: fixed;
	background: rgba(0, 0, 0, 0.725);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

body.ngdialog-open {
	overflow: hidden;
}