.roll-call-monitoring {
	.table-container {
		overflow: hidden;
	}
    .tbody-wrapper {
        table {
            table-layout: fixed;
        }
    }
	.roll-call-monitoring--column-count {
		width: 75px;
	}
	.roll-call-monitoring--column-datetime {
		width: 175px;
	}
}

#roll-call-add-user {
	height: 300px;
	width: 425px;
	.tbody-wrapper table {
		table-layout: fixed;
	}
	/*
	*/
}
