group-checkin {
    input#groupCheckinName {
        width: 180px;
    }

    #status-preedited-keys {
        padding: 0 8px 0 4px !important;
    }

    .no-items-warning, .selected-permission-list {
        padding-bottom: 4px;

        .green-check {
            color: $active;
        }
    }

    .optional-permissions-detail-box .detail-box__content {
        min-height: 95px;

        .no-items-warning {
            padding-top: 17px;
        }
    }

    .separator {
        margin-left: 15px;
    }

    .applied-filters {
        display: block;
    }
}

.group-checkin-rooms-wrapper {
    .detail-box__content {
        margin: 0;
        padding-bottom: 0;
    }
}

.group-checkin-rooms {
    .applied-filters{ 
        padding: 16px 8px 0 8px;
    }

    .group-checkin-rooms-table-wrapper {
        margin: 8px;

        table {
            table-layout: fixed;
        }
    }

    .add-delete-row {
        text-align: right;

        &.empty {
            height: 39px;
        }
    }

    .icon-surrogate-gap {
        display: inline-block;
        width: 17px;
    }
}

.content .content__status-bar .status-bar__group.group-checkin-status-bar {
    @include inline-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);

    & > * {
        @include flex(0 0 auto);

        &.shrink {
            @include flex(0 1 auto);
        }
    }

    & > button {
        text-align: left;
        margin-left: 8px;
    }
}

group-checkins table .icon-ok {
    position: relative;
    top: 1px;
}

.preedit-key-fields {
    text-align: center !important;

    & > field {
        text-align: left;
    }

    .key-operation--separator {
        display: inline-block;
        margin: 8px 0 14px 0;
    }
}