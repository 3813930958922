.button-list {
	@extend .no-bullet;
	font-size: 0;

	&.with-font-size {
		font-size: 15px;
	}

	margin: 0;
	padding: 1em 0 1em 0;
	letter-spacing: -0.31em;
	> li {
		letter-spacing: normal;
		display: inline-block;
		margin: 0 8px 8px 0;

		&.minimum-margin {
			margin-left: 1px;
		}

		&.no-space {
			button {
				&:not(:disabled){
                	cursor: pointer;
				}
				border-radius: 0px;
				.button__gradient {
					padding-left: 7px;
					padding-right: 1px;
				}
			}
			button:first-child{
				margin-left: 0px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			button:last-child{
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
			button:not(:last-child) {
				border-right: none;
			}
		}
	}
	&.right > li {
		margin: 0 0 8px 8px;

		&.minimum-margin {
			margin-left: -2px;
		}
	}
	&.button-list--vertical {
		width: 100%;
		li {
			display: block;
			width: 100%;
			margin-bottom: 24px;
			button{
				display: block;
				height: 35px;
				width: 35px;
				margin: 0 auto 8px;
				box-shadow: none;
				&:before{ font-size:14px; }
				&:last-child{
					margin-bottom: 0;
				}
                &:not(:disabled){
                    background: #A6A6A6;
				    cursor: pointer;
                    &:hover, &:focus {
                        background: #969696;
                    }
                }
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
        .order-list--margin-left-half-negative & {
            @media (max-width: 1099px) {
                margin-left: -0.9em;
            }
            @media (min-width: 1100px){
                margin-left: -40%;
            }
        }
	}
	&.no-padding {
		padding: 0;
	}
	&.double-margin-right {
		> li {
			margin-right: 8px;
		}
	}
}
.button-list--stacked {
	@extend .button-list;
	padding: 0;
	margin: 0;
	> li {
		display: block;
	}
}

.button--next2-field{
    vertical-align: top;
    margin-top: 27px;
}

$button-height: 34px !default;
%button {
	padding: 0px;
	overflow: hidden;
		
	&,&:visited {
		box-sizing: border-box;
		border: 1px solid $black;
		border-radius: 4px;
		font-family: $main-font-family;
		font-size: 14px;
		height: $button-height;
		line-height: $button-height;
		text-transform: uppercase;
		font-weight: 600;
		outline: none;
		-webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
		-moz-box-shadow:    0px 2px 0px 0px rgba(0, 0, 0, 0.1);
		box-shadow:         0px 2px 0px 0px rgba(0, 0, 0, 0.1);
	}
	[class^="icon-"], [class*=" icon-"] {
		margin: 0 10px 0 .3em;
	}
	&.button-right-icon [class^="icon-"], [class*=" icon-"] {
		margin: 0 .3em 0 10px;
	}
	&:active:not(:disabled) {
		-webkit-box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.1);
		-moz-box-shadow:    0px -2px 0px 0px rgba(0, 0, 0, 0.1);
		box-shadow:         0px -2px 0px 0px rgba(0, 0, 0, 0.1);
	}
	
	.button__gradient{
		padding-left: 10px;
    	padding-right: 10px;
		height: $button-height;
	}
}

@mixin create-button($button-name,
					$background-color, $background-color--hover, $background-color--pressed,
					$border-color,
					$icon-color, $icon-color--hover, $icon-color--focus,
					$text-color) {
						
	$button-bg-gradient: linear-gradient(to bottom,  $background-color 0%, $background-color 75%,darken($background-color,8%) 100%); /* W3C */
	$button-hover-bg-gradient: linear-gradient(to bottom,  $background-color--hover 0%, $background-color--hover 75%,darken($background-color--hover,8%) 100%); /* W3C */
	$button-active-bg-gradient: linear-gradient(to top,  $background-color--pressed 0%, $background-color--pressed 75%,darken($background-color--pressed,8%) 100%); /* W3C */
	$button-disabled-bg-gradient: linear-gradient($black80 0%, $black80 75%, darken($black80,8%) 100%); /* W3C */
	$button-disabled-dark-bg-gradient: linear-gradient($black20 0%, $black20 75%, darken($black20,8%) 100%); /* W3C */
						
	.#{$button-name} {
		@extend %button;
		background: $background-color;
		border-color: $border-color;
		color: $text-color;
		
		[class^="icon-"], [class*=" icon-"] {
			color: $icon-color;
		}
		&:hover {
			[class^="icon-"], [class*=" icon-"] {
				color: $icon-color--hover;
			}
		}
		&:focus {
			&:not(:hover){
				[class^="icon-"], [class*=" icon-"] {
					color: $icon-color--focus;
				}	
			}
		}
		&:active:not(:disabled) {
			[class^="icon-"], [class*=" icon-"] {
				color: transparentize($icon-color--hover,.4);
			}
		}
		
		.button__gradient{
			background: $button-bg-gradient;
		}
		
		&:hover, &:focus {
			.button__gradient{
				background: $background-color--hover;
				background: $button-hover-bg-gradient;
			}
		}
		&:active:not(:disabled) {
			.button__gradient{
				background: $background-color--pressed;
				background: $button-active-bg-gradient;
				color: transparentize($text-color,.4);
				outline: none;
			}
		}
		
		&:disabled{
			border-color: $black70;
			background: $black80;
			color: transparentize($color: $white, $amount: 0.3);
			cursor:default;
			[class^="icon-"], [class*=" icon-"] {
				color: transparentize($color: $white, $amount: 0.3);
				.dark & {
					color: transparentize($white, .85);
				}
			}
			.dark & {
				background: $black20;
				border-color: transparentize($black,.8);
				color: transparentize($white, .85);
				.button__gradient{
					background: $button-disabled-dark-bg-gradient;
				}
			}		
			.button__gradient{
				background: $button-disabled-bg-gradient;
			}
		}
	}
    a.#{$button-name} {
        outline: none;
        text-decoration: none;
        display: inline-block;
    }
}

.button-square {
		height: 35px;
		width: 35px;
		text-transform: none;
		span[class^="icon-"]{
            margin:0;
        }
}

/*Fullpicker option*/
.button-pagination {
    &.opened {
        &:focus{
            background: $active;
        }
		background: $active;
    }
	&.square{
		@extend .button-square;
	}
}


.button-round {
	height: 35px;
	width: 35px;
	border-radius: 50%;
	text-transform: none;
	padding: 0;
	[class^="icon-"], [class*=" icon-"]{
		margin: 0;
	}
	&:not(:hover):not(:focus):not(:active):not(.button-white):not(.active) [class^="icon-"], [class*=" icon-"]{
		color: white !important;
	}
	&:not(.button-white).active {
		border-color: $black50;
		background: $active;
		.button__gradient {
			background: $active;
		}
	}
	.button__gradient {
		border-radius: 50%;
	}
}

.button--no-back{
	background: none !important;
	border:none;
	width: auto;
	line-height: 1em;
	.button__gradient{
		background: none !important;
		padding:0;
	}
	[class^="icon-"], [class*=" icon-"]{
		margin: 0;
	}
	&:disabled{
		[class^="icon-"], [class*=" icon-"]{
			color: #666666 !important;
		}
	}
}

.button-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@-moz-document url-prefix() {
	.button-list {
		padding: 15px 0 15px 0;
	}
    .button-list  > li {
		display: inline;
	}
}