#home9Elements{
    height: 440px;
    display: inline-block;
    .primaryButton{
        height: 290px;
        width: 290px;
        .spanN{
            font-size:45px;
        }
        .spanPL{
            font-size:37px;
        }
        .icon{
            font-size: 80px;
        }
    }
    .secondaryButton{
        height: 145px;
        width: 145px;
        .spanN{
            font-size:20px;
        }
        .spanPL{
            font-size:17px;
        }
        .icon{
            font-size: 35px;
        }
    }
    .tertiary{
        width: 214px;
        height: 214px;
        span{
            font-size:35px;
        }
        .icon{
            font-size: 80px;
        }
    }
    .column{
        display: inline-block;
        button{
            display: block;
        }
        .twoButtons{
            display: inline-block;
            button{
                display:inline-block;
            }
        }
    } 
    .first{
        width: 291px;
    }
     .second{
         width: 294px;
     }
     
    .button1{  
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
    }
    .button2{ 
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
        border-left: solid 1px white; 
    } 
    .button3{
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
        border-left: solid 1px white; 
    }    
    .button4{
        border-left: solid 1px white; 
        border-right: solid 1px #e8e8e8;
        border-top: solid 1px white;
        width: 294px;
    }
    .button5{
        border-left: solid 1px white; 
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8; 
    } 
    .button6{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
    }   
    .button7{
        border-left: solid 1px white; 
        border-right: solid 1px #e8e8e8;
        border-top: solid 1px white;
    }   
    .button8{
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8;
    }   
    .button9{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        height: 218px;
    }    
    .border1{
         border-right: solid 1px #e8e8e8;
         border-top: solid 1px white;
         width:291px;
         height:145px;
    }
    
}