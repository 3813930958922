// Map search
// Reference URL: http://sassmeister.com/gist/566686c51749d4e833ef
@function map-search($map, $key) {
	$res: false;
	
	// Check if the target is a valid map and that the given key is a string
	@if (type-of($map) != map or type-of($key) != string) {
	$warn: if(type-of($map) != map,
		'The given value "#{$map}" isn\'t a valid map',
		'The given key "#{$key}" isn\'t a valid string');

	@warn $warn;
	@return '';
	}

	// Traverse through the given map, if an map is found run a deep search on that key
	@each $k, $v in $map {
	@if $res == false and type-of($v) == map {
		$result: map-search($v, $key);
		
		@if $result != false {
		$res: $result;
		}
	}
	@else if $k == $key {
		$res: $v;
	}
	}

	// Return the result otherwise nothing
	@return if($res != false, $res, '');
}



// // Use:
// $config: (
// 	background: #000,

// 	links: (
// 		normal    : #fff,
// 		hover     : darken(#fff, 10%),
// 		focus     : darken(#fff, 20%),
// 		active    : darken(#fff, 15%),
// 		visited   : darken(#fff, 30%),

// 		random: (
// 			why: 'Just because I can!',
		
// 			deep: (
// 				hello: 'World!'
// 			)
// 		)
// 	)
// );



// body {
// 	content: map-search($config, why);
// 	content: map-search($config, hello);
// 	color: map-search($config, visited);
// }



// // Result:
// // body {
// // 	content: "Just because I can!";
// // 	content: "World!";
// // 	color: #b3b3b3;
// // }