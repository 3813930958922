.partition-delete-dialog {
    select {
        max-width: 380px;
    }
}

partition {
    .details-list__header {
        height: 52px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;

        label {
            position: relative;
            bottom: 2px;
        }
    }

    .partition-content-wrapper {
        margin-top: 52px;
    }

    .grid {
        margin-bottom: 15px;
    }

    table {
        letter-spacing: normal;
        table-layout: fixed;

        tr.selected td {
            color: $white;
        }

        tr:not(.selected) .item-count {
            color: $black70;
        }

        td {
            height: 24px !important;
            padding-top: 2px !important;
        }
    }

    operator-groups-tab table {
        td {
            height: 28px !important;
        }
    }

    .table-container {
        min-height: auto;
        .tbody-wrapper {
            min-height: auto;
        }
    }

    .detail-box {
        height: 100%;
        min-height: 315px;
        margin-bottom: 15px;

        .detail-box__content {
            height: calc(100% - 66px);
            margin-bottom: 0;
        }
    }

    .table-footer {
        .button-wrapper {
            @include flex(1 1 auto);
            width: 1px;
            padding: 8px 8px 0 0;
            text-align: right;

            button {
                max-width: 100%;
                text-align: left;
            }

            button, .button__gradient {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.ng-dialog--partition-items .ng-dialog-content {
    height: 400px;
}

.partition-tables {
    span[class^="icon-"]:not(.no-margin) {
        width: 20px;
        display: inline-block;
    }
}

.partition-select-left, .partition-select-right {
    field {
        margin-bottom: 4px !important;
    }
}

.partition-select-right {
    padding-left: 35px;
}

.select-something-wrapper {
    text-align: center;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: $black75;

    .icon-info {
        font-size: 24px;
    }

    p {
        margin-top: 6px;
        font-size: 20px;
    }
}

.partitions {
    table {
        table-layout: fixed;
    }
}