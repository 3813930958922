/**
 * Returns a list of shared value from `$list` and `$lists` minus duplicates.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-intersection
 *
 * @requires sl-remove-duplicates
 * @requires sl-to-list
 *
 * @param {List}    $list  - first list
 * @param {ArgList} $lists - other lists
 *
 * @example
 * sl-intersection(a b c, b e d, a c b)
 * // b
 * 
 * @return {List}
 */
 
@function sl-intersection($list, $lists...) {
  @if sl-missing-dependencies(sl-remove-duplicates) == true { @return null; }

  $result: $list;

  @each $list in $lists {
    $temp: ();

    @each $item in $result {
      @if not not index($list, $item) {
        $temp: append($temp, $item, list-separator($list));
      }
    }

    $result: $temp;
  }

  @return sl-remove-duplicates($result);
}
