$add-delete-table-width: 344px;
$add-delete-table-wrapper-max-width: 342px;
$add-delete-table-big-width-difference: 106px;
$add-delete-buttons-width: 67px;

.add-delete {
    height: 100%;
    
    .add-delete-table {
        width: $add-delete-table-width;
        @media only screen and (min-width : 1224px) {
            width: $add-delete-table-width + $add-delete-table-big-width-difference;
        }
        .thead-wrapper, .tbody-wrapper {
            table {
                max-width: $add-delete-table-wrapper-max-width;
                @media only screen and (min-width : 1224px) {
                    max-width: $add-delete-table-wrapper-max-width + $add-delete-table-big-width-difference;
                }
            }
        }
    }
    
    .add-delete-buttons {
        width: $add-delete-buttons-width;
    }
        
    .grid {
        margin-left: 0;
        .grid__item {
            &.add-delete-table {
                height: 100%;
            }
            padding-left: 0;
            .add-delete-list {
                height: 100%;
            }
        }
    }
    .warning-label-container {
        .warning-label-border {
            padding: 8px;
        }
    }
    
    .applied-filters-container{
        @include clearfix2;
        .add-delete-table, .add-delete-buttons {
            float:left;
            padding-bottom: 1px;
        }
    }
    
    .add-delete-list-container{
        .add-delete-table, .add-delete-buttons {
            margin-top: -1px;
        }
        .add-delete-buttons .button-list {
            padding: 0;
        }
    }
    .add-delete-list__locked-icon {
        float: left;
        width: 24px;
    }
    .add-delete-list__locked-name {
        margin-left: 20px;
    }
    .add-delete-list__selected-locked-name {
        //margin-left: 20px;
        margin-left: 0;
    }
    .table-container {
        min-height: auto;
    }
    .tbody-wrapper {
        min-height: auto;
        table {
            table-layout: fixed;
        }
    }
    table {
        th,td {
            overflow: hidden;
            white-space: nowrap;
            word-wrap: normal
        }
    }

    .locked-elements {
        padding-bottom: 12px;

        .warning-label-border {
            height: 37px;
            width: 755px;

            @media only screen and (min-width : 1224px) {
                width: 967px;
            }

            & > * {
                vertical-align: top;
            }

            .locked-items-warning-message {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 8px;
            }
            
            .warning-label__close {
                position: static;
            }
        }
    }
}
.add-delete-list {
    .tree-grid-first-cell {
        table-layout: fixed;
        width: 100%;
    }
}

partition-add-delete {
    .add-delete {
        height: 370px;
    }

    .warning-label-container {
        padding-top: 7px;
    }

    .applied-filters__label {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}