$group-title-background: #f7f7f7;
$group-border-color: #e6e6e6;
.group {
	border: 1px solid $group-border-color;
	border-radius: 3px;
	.group__header {
		background: $group-title-background;
		height: 37px;
		border: 1px solid $group-border-color;
		border-width: 0 0 1px 0;
		h3 {
			font-size: $main-font-size;
			line-height: 37px;
			margin: 0;
			padding: 0 8px;
			color: #aaa;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
	.group__content {
		padding: 16px 8px;
	}
	.group__footer {
		background: $group-title-background;
		min-height: 37px;
		border: 1px solid $group-border-color;
		border-width: 1px 0 0 0;
		margin: 0;
		padding: 8px;
		.button-list {
			margin: 0;
			> li {
				margin-bottom: 0;
			}
		}

	}
}