$psw-right-margin: 16px;

.key-and-confirm-key-container{
    white-space: nowrap;
    .key-inputs{
        display: block;
        margin-left: 10px; 
        input:not(.key-defined){
            display: block;
            margin-right: 0px;
        }
    }
    label{
        text-align: right;
        margin-top: 6px;
    }
     
    input{
        width:100%;
    }
    .defined-key-container{
        .key-defined-input{
            width: 67%;
        }
        button{
            margin-left: 5px !important;
        }

    }
   
    .confirmation-label{
        margin-top: 30px;
        &.label-hidden{
            visibility: hidden;
            margin-top: -23px;
            margin-bottom: 0px;
        }
    }
    .confirmation-input{
        margin-top: 12px;
    }

   
     &.key-and-confirm--inline{
        display: block;
        white-space: normal;
         .key-labels--inline{
             @include inline-flex;
            //  flex: 1 0 auto;
            //  width: 100%;
             label {
                //Para usar variables Sass en calc() hay que interpolarlas con #{}
                width: calc( #{$field-xl-size} + #{$psw-right-margin});
                margin-top: 0px;
                margin-bottom: 6px;
                display: inline-block;
                text-align: left;
             }
         }
         .key-inputs--inline{
             @include inline-flex;
             margin-left: 0;
             .defined-key-container{
                 display: block;
             }
            .password-input{
                margin-right: $psw-right-margin;
            }
            .confirmation-input{
                margin-top: 0px;
            }
            .edition-input{
                display: inline-block;
                width: $field-xl-size;    
            }
            .key-defined-input{
                display: inline-block;
                width: 242px;
            }
         }
    }
}
key-and-confirm-key .numeric-input {
    text-align: left;
}