/**
 * Checks whether `$list` is symmetrical.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-is-symmetrical
 *
 * @requires sl-reverse
 *
 * @param {List} $list - list to check
 *
 * @example
 * sl-is-symmetrical(a b c)
 * // false
 *
 * 
 * @example
 * sl-is-symmetrical(a b a)
 * // true
 *
 * @return {Bool}
 */

@function sl-is-symmetrical($list) {
  @if sl-missing-dependencies(sl-reverse) == true { @return null; }
  
  @return $list == sl-reverse($list);
}

/**
 * @requires sl-is-symmetrical
 * @alias sl-is-symmetrical
 */
 
@function sl-is-mirror($list) {
  @return sl-is-symmetrical($list);
}