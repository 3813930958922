/** Adds `$value` at `$index` in `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-insert-nth
 *
 * @requires sl-is-true
 *
 * @param {List}    $list  - list to update
 * @param {Number}  $index - index to add
 * @param {*}       $value - value to add
 *
 * @throws List index $index is not a number for `sl-insert-nth`.
 * @throws List index $index must be a non-zero integer for `sl-insert-nth`.
 *
 * @example
 * sl-insert-nth(a b c, 2, z)
 * // a z b c
 *
 * @example
 * sl-insert-nth(a b c, 42, z)
 * // a b c z
 *
 * @example 
 * sl-insert-nth(a b c, -42, z)
 * // null
 * 
 * @return {List | Null}
 */
 
@function sl-insert-nth($list, $index, $value) {
  @if sl-missing-dependencies(sl-is-true) == true { @return null; }

  $length: length($list);

  @if type-of($index) != "number" {
    @warn "List index #{inspect($index)} is not a number for `sl-insert-nth`.";
    @return null;
  }

  @if $index < 1 {
    @warn "List index #{$index} must be a non-zero integer for `sl-insert-nth`.";
    @return null;
  }

  @if $index > $length {
    @return append($list, $value, list-separator($list));
  }

  $result: ();

  @for $i from 1 through $length {
    @if $i == $index {
      @if sl-is-true($value) {
        $result: append($result, $value, list-separator($list));
      }
    }

    $result: append($result, nth($list, $i), list-separator($list));
  }

  @return $result;
}
