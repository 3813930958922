/** Returns first element of `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-first
 *
 * @param {List} $list - list to retrieve first item from
 *
 * @throws Cannot find first item of empty list.
 *
 * @requires sl-is-empty
 *
 * @example
 * sl-first(a b c)
 * // a
 *
 * @example
 * sl-first(a)
 * // a
 *
 * @example
 * sl-first(())
 * // null
 * 
 * @return {*}
 */

@function sl-first($list) {
  @if sl-missing-dependencies(sl-is-empty) == true { @return null; }

  @if sl-is-empty($list) {
    @warn "Cannot find first item of empty list.";
    @return null;
  }

  @return nth($list, 1);
}

/**
 * @requires sl-first
 * @alias sl-first
 */
 
@function sl-head($list) {
  @return sl-first($list);
}