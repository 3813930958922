@font-face {
	font-family: 'icomoon';
	src:url('#{$icon-path}/icomoon.eot?icomoon');
	src:url('#{$icon-path}/icomoon.eot?icomoon') format('embedded-opentype'),
		url('#{$icon-path}/icomoon.woff?icomoon') format('woff'),
		url('#{$icon-path}/icomoon.ttf?icomoon') format('truetype'),
		url('#{$icon-path}/icomoon.svg?icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class^="icon-"]:before, [class*=" icon-"]:before, %icon {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//Mejor que herede el cursor del padre
	//cursor: default;
}
[class^="icon-"], [class*=" icon-"] {
	cursor: default;
	text-shadow: none;
}

a [class^="icon-"], a [class*=" icon-"],
button [class^="icon-"], button [class*=" icon-"],
#menu [class^="icon-"], #menu [class*=" icon-"],
%icon {
	cursor: inherit;
}
.icon-rollcall-areas:before {
  content: "\e904";
}
.icon-third-party-readers:before {
  content: "\e902";
}
.icon-node-cu4EB:before {
  content: "\e903";
}
.icon-alarm-events:before {
  content: "\e905";
}
.icon-salto-network:before {
  content: "\e906";
}
.icon-access-levels:before {
  content: "\24";
}
.icon-access-point-online-ip:before {
  content: "\e690";
}
.icon-access-point-online-rf-bas:before {
  content: "\e691";
}
.icon-access-point-online-rf-salto:before {
  content: "\e692";
}
.icon-access-point-online-rf3-salto:before {
  content: "\e900";
}
.icon-access-points:before {
  content: "\e69a";
}
.icon-add:before {
  content: "\2c";
}
.icon-admin:before {
  content: "\61";
}
.icon-alert:before {
  content: "\e645";
}
.icon-arrow-down:before {
  content: "\2193";
}
.icon-arrow-first:before{
  content: "\e908";
}
.icon-arrow-last:before{
  content: "\e909";
}
.icon-arrow-left:before {
  content: "\2190";
}
.icon-arrow-right:before {
  content: "\2192";
}
.icon-arrow-up:before {
  content: "\2191";
}
.icon-associated-devices:before {
  content: "\e64d";
}
.icon-auditrail:before {
  content: "\e684";
}
.icon-auditrail-export:before {
  content: "\e688";
}
.icon-auditrail-purgation:before {
  content: "\e686";
}
.icon-automatic-changes:before {
  content: "\26";
}
.icon-battery-low:before {
  content: "\2680";
}
.icon-battery-medium:before {
  content: "\2681";
}
.icon-battery-runout:before {
  content: "\2610";
}
.icon-blacklist-codes:before {
  content: "\e6a6";
}
.icon-bullet:before {
  content: "\e69f";
}
.icon-bullet:before {
  content: "\2609";
}
.icon-calendar:before {
  content: "\43";
}
.icon-cancel:before {
  content: "\78";
}
.icon-checkin:before {
  content: "\29";
}
.icon-checkin-group:before {
  content: "\e600";
}
.icon-checkmark-circle:before {
  content: "\e601";
}
.icon-checkout:before {
  content: "\28";
}
.icon-checkout-group:before {
  content: "\e60d";
}
.icon-close:before {
  content: "\58";
}
.icon-connection-offline:before {
  content: "\2686";
}
.icon-connection-online:before {
  content: "\2687";
}
.icon-copy:before {
  content: "\25f3";
}
.icon-copy-key:before {
  content: "\e619";
}
.icon-database-sync:before {
  content: "\e6a8";
}
.icon-database:before {
  content: "\e662";
}
.icon-delete:before {
  content: "\2d";
}
.icon-door:before {
  content: "\64";
}
.icon-door-closed:before {
  content: "\e6b0";
}
.icon-door-left-open:before {
  content: "\e603";
}
.icon-door-open:before {
  content: "\e6b3";
}
.icon-door-open-entering:before {
  content: "\e6b1";
}
.icon-door-open-exiting:before {
  content: "\e6b2";
}
.icon-door-warning:before {
  content: "\e6a7";
}
.icon-download:before {
  content: "\22";
}
.icon-duration:before {
  content: "\231b";
}
.icon-edit:before {
  content: "\65";
}
.icon-encoder:before {
  content: "\e68f";
}
.icon-end:before {
  content: "\e6ae";
}
.icon-error:before {
  content: "\e1fd";
}
.icon-event-stream:before {
  content: "\e605";
}
.icon-f2:before {
  content: "\e647";
}
.icon-file:before {
  content: "\e667";
}
.icon-file-sync:before {
  content: "\e680";
}
.icon-filter:before {
  content: "\66";
}
.icon-folder:before {
  content: "\e062";
}
.icon-forbidden:before {
  content: "\e800";
}
.icon-functions:before {
  content: "\e69b";
}
.icon-gateway:before {
  content: "\e696";
}
.icon-gateway-bas:before {
  content: "\e699";
}
.icon-gateway-cu4200:before {
  content: "\e698";
}
.icon-gateway-rf:before {
  content: "\e697";
}
.icon-guest:before {
  content: "\e66b";
}
.icon-guest-access-levels:before {
  content: "\e6a4";
}
.icon-help:before {
  content: "\e607";
}
.icon-info:before {
  content: "\69";
}
.icon-key-delete:before {
  content: "\e6a5";
}
.icon-key-expired:before {
  content: "\e648";
}
.icon-key-expired:before {
  content: "\e6a0";
}
.icon-key-reedition-required:before {
  content: "\e63c";
}
.icon-key-reedition-required:before {
  content: "\e6a1";
}
.icon-key-update:before {
  content: "\e63e";
}
.icon-key-update:before {
  content: "\e6a2";
}
.icon-key-updated:before {
  content: "\e632";
}
.icon-key-updated:before {
  content: "\e6a3";
}
.icon-key:before {
  content: "\6b";
}
.icon-language:before {
  content: "\6c";
}
.icon-license:before {
  content: "\e064";
}
.icon-limited-occupancy-areas:before {
  content: "\e69e";
}
.icon-limited-occupancy-groups:before {
  content: "\e69d";
}
.icon-location:before {
  content: "\4c";
}
.icon-location-function:before {
  content: "\e61a";
}
.icon-locations:before {
  content: "\e69c";
}
.icon-lock:before {
  content: "\2395";
}
.icon-lockdown-areas:before {
  content: "\e66e";
}
.icon-lockers:before {
  content: "\25";
}
.icon-login:before {
  content: "\32";
}
.icon-logout:before {
  content: "\31";
}
.icon-low-zone:before {
  content: "\e60c";
}
.icon-node:before {
  content: "\e693";
}
.icon-node-cu4200:before {
  content: "\e695";
}
.icon-node-rf:before {
  content: "\e694";
}
.icon-node-rf3:before {
  content: "\e901";
}
.icon-ok:before {
  content: "\2713";
}
.icon-online-monitoring:before {
  content: "\e6b4";
}
.icon-operator-access-levels:before {
  content: "\35";
}
.icon-operator-admin:before {
  content: "\e6a9";
}
.icon-operators:before {
  content: "\2e";
}
.icon-outputs:before {
  content: "\2944";
}
.icon-panel-max:before {
  content: "\21a7";
}
.icon-panel-min:before {
  content: "\21a5";
}
.icon-partition:before {
  content: "\e68b";
}
.icon-pause:before {
  content: "\e670";
}
.icon-play:before {
  content: "\e674";
}
.icon-pms:before {
  content: "\e606";
}
.icon-ppd:before {
  content: "\2f";
}
.icon-print:before {
  content: "\70";
}
.icon-push-left:before {
  content: "\21da";
}
.icon-push-right:before {
  content: "\21db";
}
.icon-reader:before {
  content: "\e676";
}
.icon-relocate:before {
  content: "\e68c";
}
.icon-remove:before {
  content: "\2a2f";
}
.icon-restart:before {
  content: "\e6b5";
}
.icon-room:before {
  content: "\e62b";
}
.icon-room-made:before {
  content: "\33";
}
.icon-rooms:before {
  content: "\e622";
}
.icon-sam-issuing-data:before {
  content: "\e602";
}
.icon-scheduled-jobs:before {
  content: "\e624";
}
.icon-screen:before {
  content: "\e649";
}
.icon-search:before {
  content: "\2625";
}
.icon-settings:before {
  content: "\e643";
}
.icon-siren:before {
  content: "\e604";
}
.icon-sort-down:before {
  content: "\21e3";
}
.icon-sort-up:before {
  content: "\21e1";
}
.icon-start:before {
  content: "\e6af";
}
.icon-suites:before {
  content: "\e658";
}
.icon-suites-mini:before {
  content: "\e68e";
}
.icon-systemauditor:before {
  content: "\e67e";
}
.icon-systemauditor-export:before {
  content: "\e677";
}
.icon-systemauditor-purgation:before {
  content: "\e67a";
}

.icon-table-menu-arrow:before{
  content: "\e907";
}

.icon-time:before {
  content: "\231a";
}
.icon-time-period:before {
  content: "\63";
}
.icon-time-table:before {
  content: "\74";
}
.icon-timezones:before {
  content: "\e655";
}
.icon-unfilter:before {
  content: "\e68d";
}
.icon-unlock:before {
  content: "\23cd";
}
.icon-update:before {
  content: "\21ba";
}
.icon-user-ban:before {
  content: "\62";
}
.icon-user:before {
  content: "\75";
}
.icon-users:before {
  content: "\254d";
}
.icon-view:before {
  content: "\e68a";
}
.icon-visitor-access-levels:before {
  content: "\34";
}
.icon-visitors:before {
  content: "\27";
}
.icon-warning:before {
  content: "\e1fb";
}
.icon-wizard:before {
  content: "\e62d";
}
.icon-zones:before {
  content: "\7a";
}

