/**
 * Joins all elements of `$list` with `$glue`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-to-string
 *
 * @param {List}   $list      - list to cast
 * @param {String} $glue ('') - value to use as a join string
 *
 * @requires sl-has-multiple-values
 * @requires sl-last
 * 
 * @example
 * sl-to-string(a b c)
 * // abc
 *
 * @example
 * sl-to-string(a b c, '-')
 * // a-b-c
 * 
 * @return {String}
 */

@function sl-to-string($list, $glue: '') {
  @if sl-missing-dependencies(sl-has-multiple-values) == true { @return null; }

  $result: '';

  @each $item in $list {
    $result: $result + if(sl-has-multiple-values($item), sl-to-string($item, $glue), $item);

    @if $item != sl-last($list) {
      $result: $result + $glue;
    }
  }

  @return quote($result);
}

/**
 * @requires sl-to-string
 * @alias sl-to-string
 */
 
@function sl-stringify($list, $glue: '') {
  @return sl-to-string($list, $glue);
}