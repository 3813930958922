/**
 * Returns last index of `$value` in `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-last-index
 *
 * @param {List} $list  - list to search
 * @param {*}    $value - value to be searched for
 *
 * @example
 * sl-last-index(a b a, a)
 * // 3
 *
 * @example
 * sl-last-index(a b a, z)
 * // null
 *
 * @return {Number | Null}
 */
 
@function sl-last-index($list, $value) {
  @for $i from length($list) through 1 {
    @if nth($list, $i) == $value {
      @return $i;
    }
  }

  @return null;
}
