$kioskHeightBreakpoint: 580;
$kioskWidthBreakpoint: 780;
@mixin vh-attribute($attribute, $size) {
    #{$attribute}: #{$size}vh;
    @media (max-height: #{$kioskHeightBreakpoint}px) {
        #{$attribute}: #{$kioskHeightBreakpoint/100 * $size}px;
    }
}
@mixin vw-attribute($attribute, $size) {
    #{$attribute}: #{$size}vw;
    @media (max-width: #{$kioskWidthBreakpoint}px) {
        #{$attribute}: #{$kioskWidthBreakpoint/100 * $size}px;
    }
}