/**
 * Tests whether `$list` has a single item.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-is-single
 *
 * @param {List} $list - list to run test against
 *
 * @example
 * sl-is-single(())
 * // false
 *
 * @example
 * sl-is-single(a)
 * // true
 *
 * @example
 * sl-is-single(a b)
 * // false
 *
 * @return {Bool}
 */
 
@function sl-is-single($list) {
  @return length($list) == 1;
}
