$tabs-height: 29px;
$tabs-border-width: 2px;
$tabs-row-margin: 2px;
@mixin multi-row-tabs($rows){
	$tabs-container-height: $rows*$tabs-height + 2*$rows*$tabs-border-width + $tabs-row-margin*($rows - 1);
	&.tabs--#{$rows}-rows.tabs__nav--display-rows{
		.tabs__content {
			height: calc(100% - #{$tabs-container-height});
		}
		.tabs__nav {
			height: $tabs-container-height;
			@include flexbox;
			@include flex-direction(column-reverse);
			.tabs__nav--row {
				@include flex-row-container;
				.tab__panel {
					@include flex(1 1 auto);
					&:last-child {
						margin-right: 0;
					}
				}
			}
			@for $i from 2 through $rows {
				.tabs__nav--row-#{$i}-of-#{$rows} {
					margin-bottom: $tabs-row-margin;
				}
			}
		}
	}
}

.tabs {
	.tabs__nav {
		box-sizing: border-box;
		display: block;
		list-style: none;
		letter-spacing: -0.31em;
		margin: 0;
		padding: 0;
		width: 100%;
		z-index: 1;
		position: relative;
		margin-bottom: -$tabs-border-width;
		.tab__panel, li {
			color: $black60;
			display: inline-block;
			letter-spacing: normal;
			margin: 0 8px 0 0;
			border: $tabs-border-width solid $black90;
			border-radius: 3px 3px 0 0;
			text-align: center;
			height: $tabs-height + 2*$tabs-border-width;
			position: relative;
			a {
				color: $black60;
				text-decoration: none;
				font-weight: 400;
				margin: 0;
				padding: 6px 16px;
				display: block;
				background: $black90;
				&:hover {
					color: $black40;
					background: $white;
				}
			}
			&.tabs__nav--active {
				border-bottom-color: $white;
				a {
					background: $white;
				}
			}

			.with-button {
				// Usamos margin en lugar de padding para que el borde que
				// se le pone cuando tiene el foco no quede justo en el borde del tab
				margin: 3px 10px;
				padding: 0;

				button {
					color: $black40;
					padding: 3px 6px;
					background: none;
					margin: 0;
					border: none;
					font-family: $main-font-family;
					font-weight: 400;

					&:focus {
						outline: none;
					}
				}

				&.tabs__nav--active-focused {
					outline: $button-outline;
				}
			}

			.tabs__nav--notification-message {
				position: absolute;
				background: $color-error;
				border: 2px solid #fff;
				border-radius: 100%;
				width: 23px;
				height: 23px;
				text-align: center;
				line-height: 1.2em;
				top: -8px;
				right: -8px;
    			font-weight: 800;
				color: #fff;
				// Necesario para que cuando se reduce la ventana y 
				// los tabs se muestran en varias filas aparezca por encima
				z-index: 1; 
			}
		}
		.tabs__nav--row {
			display: inline-block;
	}
	}
	.tabs__content {
		font-size: 15px;
		box-sizing: border-box;
		position: relative;
		border: $tabs-border-width solid $black90;
		border-radius: 0 4px 4px 4px;
		height: 100%;
		padding: 8px;
		position: relative;
	}
	&.tabs--full {
		position: absolute;
		left: 16px;
		right: 16px;
		top: 16px;
		bottom: 16px;
		.tabs__content {
			height: calc(100% - #{$tabs-height});
			overflow: auto;
	}
		@include multi-row-tabs(2);
	}
}