.time-zone__same-as{
    position: relative;
    height: 404px;
    min-width: 455px;
    max-width: 510px;
}

.time-zone__calendar {
    width: 675px;
    position: relative;
    .calendar-view{
        border-width: 0;
    }
}

fieldset.fieldset--time-zone{
    padding: 20px 20px 20px 8px;
    position: relative;
    margin: 0;
    &.is-opened{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: -1px;
    }
    &:not(:first-child){
        padding-top: 35px;
        padding-left: 20px;
    }
    legend{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
        border-radius: 5px;
        background-color: #e9e9e9;
        padding: 10px;
        box-shadow: 0px 0px 0px 5px $white;
        z-index: 2;
        .ctrl{
            display: inline-block;
        }
        label {
            display: inline;
            font-weight: 200;
        }
    }
    .ctrl{
        &.ctrl--inline{
            display: inline-block;
            width: 45px;
            span.time-info {
                vertical-align: middle;
                display: inline-block;
                margin-top: 8px;
            }
            &.gmt-offset{
                input{
                    width: 60px;
                }
            }
            &.has-time-info{
                width:20px;
                margin-left: 5px;
            }
        }
    }
    .field--time-zone-half {
        width: calc((100% - 270px)/2);
        &.built-in{
            width: calc(100% - ((100% - 270px)/2))
        }
    }
    .field--time-zone-third {
        width: calc((100% - 113px)/3);
    }
}

.fields--dst-rule{
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    &.fields--has-bottom-border {
        border-bottom: 1px solid $black90;
        margin-bottom: 15px;
    }
    .fields--dst-rule__title{
        font-size:18px;
        display: inline-block;
        padding-top:30px;
        text-align: right;
        font-weight: 200;
        padding-right:12px;
    }
    .fields {
        display: inline-block;
    }
}

.inner-detail-box.time-zone--fixed-days {
    width: 520px;
    display: block;
    margin: 15px auto 8px;
    .inner-detail-box__content.has-fixed-menu .time-zone--fixed-days-list {
        position: absolute;
        bottom: -17px;
        left: 50%;
        display: inline-table;
        transform: translateX(-50%);
        white-space: nowrap;
        button:not(:disabled) {
            box-shadow: 0px 0px 0px 5px $white;
        }
    }
}

 /*
  * Year Selector
  */
.time-zone__year-selector{
    width: 510px;
    margin: 10px auto 0;
    display: block;
}
ul.year-selector{
    width: 100%;
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    li{
        display: inline-block;
        margin: 5px 1.25%;
        font-weight: 400;
         button{ 
            color: $keycolor;
            font-family: $main-font-family;
            font-weight: 400;
            font-size:15px;
            box-shadow: none;
            outline: none;
            border: none;
            background: none;
            padding: 0;
            &:hover, &:focus, &:active{
                color:$active;
            }
            &:disabled, &.disabled{
                color: #666666;
                opacity: 1;
            }
        }
        &.year-selector__next{
            margin: 0 0 0 1.25%;
        }
        &.year-selector__previous{
            margin:0 1.25% 0 0;
        }
    }
}

.ngdialog .ngdialog__content .content-footer {
    &.time-zone__same-as-gmtoffset {
        position: absolute;
        height: 106px;
        padding:12px 16px;
        font-size: 15px;
        h2 {
            font-weight: 400 !important;
        }
        .gmtoffset__titles {
            color:$white;
            display: inline-block;
            height: 100%;
            width: auto;
            span {
                display: block;
                margin-top:7px;
                &:first-child {
                    margin-top:8px;
                }
            }
        }
        .gmtoffset__infos {
            display: inline-block;
            vertical-align: top;
            width: auto;
            padding-left: 10px;
            white-space: nowrap;
            .gmtoffset__info {
                margin-top: 6px;
                display: block;
                color: rgba($white, 0.8);
                &:first-child{
                    margin-top: 8px;
                }
            }
        }
        .gmtoffset__info {
            width: 100%;
            color: $white;
            margin-top: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            &.no-data{
                width: 100%;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
}

.time-zone__calendar-input-container {
    padding: 0 12px;
    .field {
        label span.subtitle {
            color: #9A9A9A;
       }
       .ctrl.has-time-info {
            margin-top: 9px;
        }
    }
}
