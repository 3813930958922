#home8Elements{
    height:610px;
    
    .primaryButton{
        height: 300px;
        width: 300px;
    }
    .secondaryButton{
        height: 150px;
        width: 150px;
        span{
            font-size:25px;
        }
        .icon{
            font-size: 35px;
        }
    }
    .column1{
        width:302px;
    }
     .column2{
         width:304px;
        display: inline-block;
        button{
            display: block;
        }
        .twoButtons{
            display: inline-block;
            button{
                display:inline-block;
            }
        }
     } 
     
      .column3{
        width:302px;
    }
    
     .row{
        display: inline-block;
        div{
            display: inline-block;
        } 
     }
     .button1{  
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
        border-left: solid 1px white; 
    }
    .button2{ 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
        width:302px;
    } 
    .button3{
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8;
        border-right: solid 1px #e8e8e8;
        
    }    
    .button4{
        border-left: solid 1px white; 
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
        border-top: solid 1px white;
    }
    .button5{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
        
    } 
    .button6{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
    }   
    .button7{
        border-left: solid 1px white; 
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8;
    }   
    .button8{
        border-left: solid 1px white; 
        border-top: solid 1px white;
    }    
    
    //las clases border y sus divs corresponientes se usan únicamente
    //para pintar los bordes.
    .border1{    
        border-right: solid 1px #e8e8e8;
        width: 152px;
        
    }
    .border2{
        border-right: solid 1px #e8e8e8; 
        border-bottom: solid 1px #e8e8e8;
        width: 150px;
        height: 151px;
    }
    .border3{
        width:152px;
        height:150px;
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8; 
    }
    .border4{
        border-left: solid 1px white; 
        width: 150px;
        height: 150px;
    }
    .border5{
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8;
        width: 150px;
        height: 151px;
    }
}