#tooltip {
	position: absolute;
	background: $black20;
	z-index: $tooltip-z-index;
	color: white;
	font-size: 11px;
	line-height: 11px; // Vertical centering.
	font-family: Verdana, Geneva, sans-serif;
	border-radius: 4px;
	span {
		word-wrap: break-word;
		white-space: pre-wrap;
		max-width: 500px;
		width: auto;
		display: inline-block;
		padding: 10px 17px;
	}
	.tooltip-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-color: transparent;
		&.bottom {
			border-width: 0 6.5px 6px 6.5px;
			border-bottom-color: $black20;
			top: -5px;
			left: 9px;
		}
		&.right {
			border-width: 6.5px 6px 6.5px 0;
			border-right-color: $black20;
			top: 7px;
			left: -5px;
		}
		&.left {
			border-width: 6.5px 0 6.5px 6px;
			border-left-color: $black20;
			top: 8px;
			right: -5px;
		}
		&.top {
			border-width: 6px 6.5px 0 6.5px;
			border-top-color: $black20;
			bottom: -5px;
			left: 9px;
		}
	}
}
.tooltip-container {
	display: inline;
	position: relative;
	text-overflow: inherit; // so that the ellipsis (when overflow) is applied
	overflow: inherit; // so that the ellipsis (when overflow) is applied
}
