$primary-background-color: #3dc6ff;
$primary-background-color--hover: #37dee6;
$primary-border-color: #319ecc;
$primary-text-color: $white;

$secondary-background-color: $black40;
$secondary-background-color--hover: #5c5c5c;
$secondary-background-color--pressed: #444444;
$secondary-border-color: #525252;
$secondary-icon-color: $keycolor;
$secondary-icon-color--hover: $active;
$secondary-icon-color--focus: $keycolor;
$secondary-text-color: $white;

$pagination-background-color: #a6a6a6;
$pagination-background-color--hover: #969696;
$pagination-background-color--pressed: #7b7b7b;
$pagination-border-color: #858585;
$pagination-icon-color: $white;
$pagination-icon-color--hover: $white;
$pagination-icon-color--focus: $white;
$pagination-text-color: $white;

$errorbutton-background-color: $color-error;
$errorbutton-background-color--hover: darken($color-error, 5%);
$errorbutton-background-color--pressed: darken($color-error, 10%);
$errorbutton-border-color: #a30000;
$errorbutton-icon-color: $white;
$errorbutton-icon-color--hover: $white;
$errorbutton-icon-color--focus: $white;
$errorbutton-text-color: $white;

$warningbutton-background-color: $color-warning;
$warningbutton-background-color--hover: darken($color-warning, 5%);
$warningbutton-background-color--pressed: darken($color-warning, 10%);
$warningbutton-border-color: #a35201;
$warningbutton-icon-color: $white;
$warningbutton-icon-color--hover: $white;
$warningbutton-icon-color--focus: $white;
$warningbutton-text-color: $white;

$settingsbutton-background-color: $white;
$settingsbutton-background-color--hover: $black90;
$settingsbutton-background-color--pressed: $black80;
$settingsbutton-border-color: $black70;
$settingsbutton-icon-color: $black60;
$settingsbutton-icon-color--hover: $black60;
$settingsbutton-icon-color--focus: $black60;
$settingsbutton-text-color: $black60;