.room-block-associated-devices table {
    table-layout: fixed;
    th {
        overflow: hidden;
    }
}

.rooms-statu-list__form{
    display: block;
    height: 100%;
}