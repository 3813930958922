@font-face { 
	font-family: 'Helvetica Neue';
	src: url("#{$fonts-path}/helvetica/HelveticaNeueLTStd-LtCn2.woff") format("woff");
	font-weight: 200;
}
@font-face { 
	font-family: 'Helvetica Neue';
	src: url("#{$fonts-path}/helvetica/HelveticaNeueLTStd-Cn2.woff") format("woff");
	font-weight: 400;
}

@font-face { 
	font-family: 'Helvetica Neue';
	src: url("#{$fonts-path}/helvetica/HelveticaNeueLTStd-MdCn2.woff") format("woff");
	font-weight: 600;
}

@font-face { 
	font-family: 'Helvetica Neue';
	src: url("#{$fonts-path}/helvetica/HelveticaNeueLTStd-BdCn2.woff") format("woff");
	font-weight: 800;
}

@font-face { 
	font-family: 'Helvetica Neue';
	src: url("#{$fonts-path}/helvetica/HelveticaNeueLTStd-BlkCn2.woff") format("woff");
	font-weight: 900;
}

@font-face { 
	font-family: 'Helvetica Ce';
	src: url("#{$fonts-path}/helvetica/HelveticaNeueCe-Thin2.woff") format("woff");
}

.light-condensed{
	font-weight: 200;
}

.condensed{
	font-weight: 400;
}

.medium-condensed{
	font-weight: 600;
}

.bold-condensed{
	font-weight: 800;
}

.black-condensed{
	font-weight: 900;
}
