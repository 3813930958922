.user-events {
	background-color: #333333;
	padding: 1px 1px 13px 1px;
	height: 121px;
	.photos {
		position: relative;
		overflow: hidden;
		height: 60px;
		ul {
			position: absolute;
			right: 0;
			margin: 0;
			padding: 0;
			text-align: right;
			white-space: nowrap;
			li {
				list-style: none;
				display: inline-block;
				border: 4px solid #5c5c5c;
				margin: 0 4px;
				cursor: pointer;
				.picture {
					height: 48px;
					width: 48px;
					background-position: center center;
					background-size: cover;
					background-color: #ffffff;
				}
			}
		}
		.gradient {
			width: 40px;
			height: 100%;
			background: linear-gradient(to right, rgba(51,51,51,1) 0%,rgba(0,0,0,0) 100%);
			position: absolute;
			left: 0;
			pointer-events: none;
		}
	}

	.header {
		position: relative;
		h2 {
			color: #ffffff;
			text-align: center;
			font-weight: 200;
			font-size: 21px;
			margin: 12px 0;
		}
		%legend {
			text-transform: uppercase;
			font-weight: $bold-weight;
			font-size: 14px;
			margin: 5px 33px;
			position: absolute;
		}
		.left-legend {
			@extend %legend;
			left: 0;
		}
		.right-legend {
			@extend %legend;
			right: 0;
		}
	}

	.photo-roll {
		display: table;
		width: 100%;
		.navigation-controls {
			vertical-align: middle;
			display: table-cell;
			width: 1px;
			button {
				background-color: transparent;
				border: none;
				padding: 0;
				color: #ffffff;
				&:disabled {
					color: #666666;
				}
			}
			.icon-arrow-left {
				margin-left: 12px;
				margin-right: 6px;
			}
			.icon-arrow-right {
				margin-left: 6px;
				margin-right: 12px;
			}
		}
		.no-photos {
			border: 1px solid $black40;
			border-width: 1px 0 1px 0;
			padding: 12px;
			margin: 0 34px;
			text-align: center;
			color: white;
			font-size: 18px;
			font-weight: 200;
			vertical-align: middle;
			.icon-info {
				color: $color-info;
				font-size: 1.2em;
				vertical-align: middle;
				margin-right: 4px;
			}
		}
	}
}

.user-event-details {
	color: white;
	position: absolute;
	width: 371px;
	background-color: #000000;
	border: 1px solid #4f4f4f;
	border-radius: 7px;
	color: white;
	top: 10px;
	left: 10px;
	font-size: 95%;
	visibility: hidden;
	.close {
		position: absolute;
		right: 8px;
		top: 8px;
		cursor: pointer;
	}
	.box {
		display: inline-block;
		width: 120px;
		height: 150px;
		padding: 4px;
		margin: 12px 10px 8px 12px;
		background: #ffffff;
		border: 1px solid #e6e6e6;
		.photo {
			width: 100%;
			height: 100%;
			background-color: #ffffff;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;;
		}
	}
	.data {
		width: 211px;
    	display: inline-block;
    	vertical-align: top;
    	color: #ffffff;
    	margin-bottom: 3px;
		p:first-of-type {
			margin-top: 12px;
			margin-bottom: 1em;
		}
		p {
			margin: .5em 0;
			word-wrap: break-word;
		}
		.form-label {
			color: #999999;
		}
	}
	.triangle-outer {
		position: absolute;
		left: 178.5px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 12px 7px 0 7px;
		border-color: #4f4f4f transparent transparent transparent;
		.triangle-inner {
			position: absolute;
			left: -6px;
			top: -12px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 6px 0 6px;
			border-color: #000000 transparent transparent transparent;
		}
	}
}

table tbody tr td .clickable {
	font-weight: 600;
	color: #3dc6ff;
	cursor: pointer;
}

table tbody tr:hover td .clickable {
	color: #666666;
}

.online-monitoring {
	.button-list {
		padding: 16px 0 8px 0;
		li {
			margin-bottom: 0;
		}
	}
}
.online-monitoring, .event-stream {
	.table-container {
		//height: calc(100% - 52px); /** 52 es el height que tiene el pie de la tabla */
		[class^="icon-"]:before, [class*=" icon-"]:before, %icon {
			vertical-align: middle;
		}
	}
}
.event-stream {
	.button-list {
		padding: 0;
	}
    .table-container {
        .table-cell-icon {
            width: 30px;
            display: inline-block;
            text-align: center;
        }
        .is-exit {
            font-size: 0.8em;
            vertical-align: text-top;
        }
		.event-stream-name {
			max-width: 400px;
			display: inline-block;
		}
    }
    &.event-stream-with-user-events .table-container {
        min-height: 165px;
        .tbody-wrapper {
            min-height: 128px;
        }
        .table-empty {
            height: 128px;
        }
    }
	
	&.event-stream-with-user-events {
		.table {
			top: 129px;
		}
	}
}

#onlineMonitoringAccessPointsTable {
	span[class*="icon-"] {
		position: relative;
		top: -2px;
	}
}