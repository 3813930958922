.key-operation {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include flex-direction(column);
    @include align-items(center);
    width: 400px;
    .key-operation--cancel-insert-key {
        @extend .keys--dialog-message;
        font-size: 18px;
    }
    .key-operation--insert-key {
        @extend .keys--dialog-message;
        .key-operation--device-name {
            color: $keycolor;
            font-size: 25px;
            font-weight: $bold-weight;
            text-align: center;
            padding-top: 5px;
            max-width: 400px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .key-operation--wait-message {
        padding: 10px;
        border-radius: 50px;
        display: inline-block;
        background-color: $black95;
        .icon-time {
            color: $black60;
            font-size: 20px;
        }
        >span {
            line-height: 20px;
            vertical-align: middle;
            font-weight: 400;
            padding-right: 5px;
        }
    }
}

.key-operation--separator {
    width: 42px;
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid $black85;
    margin: 10px 0;
    padding: 0;
}

%framed-dialog {
    margin: -16px;
    padding: 16px;
    border: 16px solid $black90;
    width: 650px;
    max-height: 400px;
}

%framed-inner-content {
    border: 1px solid $black80;
    border-radius: 3px;
}

#reset-locker-data {
    @extend %framed-dialog;
    overflow-y: auto;
    .framed-details-list {
        @extend .details-list;
        @extend %framed-inner-content;
    }
}

#read-key {
    .read-key--dates {
        margin-top: 16px;
        font-size: 15px;
        & > div {
            display: inline-block;
            margin: 0 4px;
            vertical-align: top;
            label {
                margin-bottom: 0;
            }
            & > div {
                text-align: left;
            }
        }
    }
}

#read-key-content {
    @extend %framed-dialog;
    height: 400px;
    padding: 0;
    .content__header {
        height: auto;
        position: static;
        padding-left: 16px;
        background: white;
        .h1-container {
            overflow: inherit;
            width: 100%;
            h1 {
                font-size: 28px;
                white-space: normal;
                margin: 11px 0px;
                line-height: 1.2;
                width: 100%;
            }
        }
        .content__warning-bar {
            padding: 0 4px;
        }
    }
    .content__status-bar {
        position: static;
        .status-bar__block:last-child {
            padding-right: 8px;
        }
        span {
            font-weight: 200;
        }
    }
    .content__read-key {
        overflow-y: auto;
        padding: 16px 16px 0 16px;
        .key-access-permission-set {
            @extend %framed-inner-content;
            margin-bottom: 16px;
            .details-list {
                margin: 8px 0;
            }
            .key-access-permission-set--periods {
                background: #c5c5c5;
                padding: 8px 8px 0 8px;
                .key-access-permission-set--periods--period {
                    font-size: 14px;
                    text-transform: uppercase;
                    background: #797979;
                    color: white;
                    padding: 8px;
                    margin-bottom: 8px;
                    display: inline-block;
                }
            }
            .detail-box__title {
                border-bottom: none;
                border-top: 1px solid #e6e6e6;
                color: $default-text-color;
                .key-access-permission-set--timetable {
                    text-transform: none;
                    margin-bottom: -8px;
                    .dayset-selector {
                        margin: 0 0 8px 0;
                        display: inline-block;
                    }
                    span {
                        line-height: 36px;
                        vertical-align: top;
                    }
                }
            }
        }
        .empty-key-accesses {
            margin: 24px 0px;
            text-align: center;
            color: #7f7f7f;
        }
    }
}
