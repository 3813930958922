.system-resources__container--graph{
    display: inline-block;
    vertical-align: top;
    width: 275px;
    margin-right: 20px;
    overflow: visible;
    white-space: nowrap;
    .system-resources__title{
        text-transform: uppercase;
        text-align: left;
        font-weight: 200;
        font-size:20px;
        margin:4px 0 25px;
        white-space: normal;
    }
    .system-resources__legends{
        list-style: none;
        display: inline-block;
        padding: 0;
        margin: 0 0 3px;
        vertical-align: bottom;
        white-space: normal;
        width: 193px;
        li{
            margin-top:10px;
            text-transform: uppercase;
            padding-left: 25px;
            &:before{
                content: '';
                width: 16px;
                height: 16px;
                display: inline-block;
                background: transparent;
                margin-right:5px;
                margin-bottom:-2px;
                margin-left: -25px;
            }
            &.system-resources__legend--free{
                &:before{
                    background: $color-success;
                }
            }
            &.system-resources__legend--blacklisted{
                &:before{
                    background: $color-error;
                }
            }
            &.system-resources__legend--occupied{
                &:before{
                    background: $black40;
                }
            }
            &.system-resources__legend--being-recovered{
                &:before{
                    background: #baa63f;
                }
            }
            .legend__container{
                display: inline-block;
            }
        }
    }
    .system-resources__graph{
        display: inline-block;
        width: 80px;
        height: 265px;
        margin-right:12px;
        vertical-align: bottom;
        overflow: hidden;
        .graph__portion{
            width: 100%;
            display: block;
            box-sizing: border-box;
            &.free {
                background: $color-success;
            }
            &.blacklisted{
                background: $color-error;
            }
            &.ocuppied{
                background: $black40;
            }
            &.being-recovered{
                background: #baa63f;
            }
        }
    }
    .system-resources__total{
        display: block;
        margin: 14px auto 0;
        border-top: 1px solid #e9e9e9;
        text-transform: uppercase;
        text-align: center;
        padding-top:7px;
        font-size:22px;
        padding-left: 28px;
    }
    .legend__data {
        font-weight: 900;
    }
}
.system-resources__container--status{
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 300px);
    min-height: 366px;
    detail-box{
        height: 100%;
        .detail-box__title{
            text-align: center;
        }
        .detail-box{
            height: 100%;
            margin-bottom:0;
            .detail-box__content{
                min-height: 313px;
            }
        }
    }
    .system-resources__status-label{
        display: inline-block;
        text-align: left;
        font-size: 21px;
        padding: 5px 18px;
        background: $black80;
        border-radius: 3px;
        color: white;
        font-weight: 800;
        &.status--1{
            background: $color-success;
        }
        &.status--0{
            background: black;
        }
    }
    .system-resources__status-number{
        font-size: 46px;
        font-weight: 900;
        vertical-align: top;
        margin: 0 5px;
        padding: 0;
        line-height: 1em;
        color:$black60;
        display: table-cell;
    }
    .system-resources__status-text{
        font-size: 18px;
        color: $black60;
        vertical-align: middle;
        padding-left:6px;
        max-width: calc(100% - 60px);
        display: inline-block;
        text-align: left;
        display: table-cell;
    }
    .system-resources__steps{
        position: relative;
        @include flexbox;
        border-top: 1px solid $black90;
        width: 97%;
        margin-top: 20px;
        margin-left: calc(8px + 1.5%);
        overflow: hidden;
        padding:40px 10px 5px;
        &.margin-top-13{
            margin-top:13px;
        }
        .system-resources__steps__title{
            position: absolute;
            width: 150px;
            height: 75px;
            border-radius: 50%;
            top: -43px;
            left: 50%;
            transform: translateX(-50%);
            background: $black95;
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            border-bottom-right-radius: 50%;
            padding-top: 50px;
        }
        .system-resources__step{
            vertical-align: top;
            width: 50%;
            display: inline-block;
            padding: 0 10px 15px 20px;
            &:not(:last-of-type){
                border-right: 1px solid $black90;
            }
            &.step-active {
                .system-resources__step__icon {
                    color: $color-success;
                }
            }
            &.step-disabled{
                .system-resources__step__icon{ color: $black90;}
                .system-resources__step__list{ color: $black90;}
            }
            .system-resources__step__icon{
                display: inline-block;
                font-size: 33px;
                vertical-align: top;
                color: $black80;
            }
            .system-resources__step__list--container{
                @include inline-flex;
                @include flex-direction(column);
                @include justify-content(space-between);
                @include align-content(space-between);
                height: 100%;
                width: calc(100% - 38px);
            }
            .system-resources__step__list{
                list-style: none;
                font-size: 16px;
                color: $black60;
                vertical-align: top;
                padding: 0 0 0 5px;
                margin: 0;
                li{
                    &.step-title{
                        font-size:20px;
                        .step-title--text{
                            font-weight: 600;
                        }
                        div {
                            display: inline;
                            vertical-align: top;
                            &.step-title--phase{
                                max-width: 30%;
                            }
                            &.step-title--text {
                                max-width: 70%;
                            }
                        }
                    }
                    &.step-number{
                        font-size: 35px;
                        font-weight: 900;
                    }
                    .inline-data{
                        width: 47%;
                        display: inline-block;
                        &.has-left-padding{
                            padding-left: 7px;
                        }
                    }
                }
            }
        }
        &.system-resources__status-info{
            padding: 15px 15px 0;
            text-align:center;
            margin-top:19px;
            color:$black60;
            font-size: 16px;
            display: block;
            .status-info__data{
                white-space: nowrap;
                display: inline-block;
                margin-right: 5px;
                .status-info__title{
                    text-transform: uppercase;
                    font-weight: 800;
                }
                .status-info__number{
                    text-transform: uppercase;
                    margin-right: 10px;
                }
            }
        }
    }
    .system-resources__summary{
        display: block;
        //width: 97%;
        //margin-left: calc(8px + 1.5%);
        margin-bottom: 16px;
        .system-resources__summary__content{
            width: 100%;
            display: block;
            background: #f2f2f2;
            padding: 12px;
            border: 1px solid #e9e9e9;
            border-top-width: 0;
            border-radius: 0 0 3px 3px;
            margin-bottom: 12px;
            margin-top:0;
        }
    }
}

.ngdialog--warning--system-resources{
    .ngdialog-content{
        width: 460px;
        .ngdialog__content{
            padding: 16px 55px;
            .half-margin-bottom{
                margin-bottom: 0.5em;
            }
        }
    }
    .system-resources__custom-fields{
        border: 2px solid rgba(white, 0.5);
        display: inline-block;
        border-right-width: 0;
        border-left-width: 0;
        padding: 7px 0px;
        margin-bottom:18px;
        width: 100%;
        .custom-fields__field{
            display: inline-block;
            width: 49%;
            .custom-fields__field__label{
                font-size: 20px;
                font-weight: 700;
                opacity: 0.5;
            }
            .custom-fields__field__value{
                font-weight: 900;
                span{
                    text-transform: uppercase;
                    font-weight: 200;
                    font-size: 15px;
                }
            }
        }
    }
}