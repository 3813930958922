img-crop {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    canvas {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        // Disable Outline
        outline: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        /* mobile webkit */
    }
    .loading {
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: $bold-weight;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        color: white;
        background-color: rgba(0, 0, 0, 0.75);
        position: absolute;
    }
}
.crop-image-loading {
    @extend .loading-spinner;
    background-image: url('/assets/img/Loading.png'); /* @meta {"sprite": {"skip": true}} */
    background-position: center;
    background-repeat: no-repeat;
}
