/**
 * Sums up all numeric values in `$list`, stripping unit if `$force` set to `true`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-sum
 *
 * @param {List} $list          - list
 * @param {Bool} $force (false) - enable/disable parseInt
 *
 * @requires sl-every
 * @requires sl-is-number
 *
 * @throws All items from list are not numbers for `sl-sum`.
 *
 * @example
 * sl-sum(1 2 3)
 * // 6
 *
 * @example
 * sl-sum(a b 1)
 * null
 *
 * @example
 * sl-sum(1 2 3px, true)
 * // 6
 * 
 * @return {Number}
 */
 
@function sl-sum($list, $force: false) {
  $result: 0;

  @if not sl-every($list, sl-is-number) {
    @warn "All items from list are not numbers for `sl-sum`.";
    @return null;
  }

  @each $item in $list {
    @if not unitless($item) and $force {
      $item: $item / ($item * 0 + 1);
    }

    @if unitless($item) {
      $result: $result + $item;
    }
  }

  @return $result;
}