.event-type-wrapper {
    min-width: 49%;
    display: inline-block;
    padding-bottom: 20px;
}

.audit-trail-trigger-filter-summary {
    word-wrap: break-word;

    .row {
        position: relative;
        margin-bottom: 10px;

        &.last {
            margin-bottom: 15px;
        }

        .icon-bullet {
            position: absolute;
            top: 3px;
            left: 9px;
            font-size: 80%;
        }

        .text {
            padding-left: 36px;

            span {
                color: $black60;
            }
        }
        .text-flex{
            @include flexbox;
            .text-flex__block {
                @include flex(1 0 auto);
                &.min{
                    @include flex(0 0 auto);
                }
                &[class^='icon-']{
                    @include flex(0 0 auto);
                    position: relative;
                    padding-right: 24px;
                }
                span {
                    display: block;
                    color: $black60;
                    &:not(:last-child){
                        margin-bottom: 5px;
                    }
                    span {
                        display: inline;                        
                    }
                }
            }
        }
    }
}

.alarm-table-wrapper {
    .detail-box__content {
        height: 351px;
        position: relative;
    }

    .white {
        height: 300px;
    }
}

.actions-wrapper {
    position: relative;
    height: 100%;
    padding: 15px 16px 15px 16px;

    .configuration-column {
        max-width: 400px;
    }
}

detail-box.absolute-bottom-row {
    .detail-box {
        position: relative;
    }

    .add-delete-row {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .flex-add-delete-row {
        @include flexbox;
        @include flex-wrap(nowrap);
        @include flex-direction(row);
        @include justify-content(flex-end);

        button {
            margin-left: 7px;
            &:first-of-type {
                margin-left: 0;
            }
            @include flex(0 1 auto);

            .button__gradient {
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                white-space: nowrap;
            }
        }
    }

    &:not(.trigger-detail-box)
    {
        .actions-wrapper {
            padding-bottom: 66px;
        }
    }

    &.trigger-detail-box {
        .detail-box__content {
            padding-bottom: 51px;
        }
    }
} 

.ngdialog--editTrigger {
    .ng-dialog-content {
        display: block;
    }

    .ngdialog-content, .ngdialog__content {
        min-width: 950px;
        max-width: 950px;
    }
    
    .ngdialog__content {
        padding-right: 0;
        padding-bottom: 0;
        height: 510px;
        max-height: 510px;
    }
}

.ngdialog--editAction {
    .ngdialog__content {
        height: 461px;
        width: 503px;
    }

    hr {
        position: relative;
        top: -5px;
    }

    textarea {
        height: 100px;
        width: 355px;

        &.full-width {
            width: 100%;
        }

        &.full-width-with-button {
            width: calc(100% - 45px);
        }
    }

    .email-macros-button {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.edit-trigger-dialog {
    .detail-box .detail-box__content {
        padding-bottom: 0;
    }

    .when-detail-box {
        .fields {
            padding: 16px 15px 0 15px;
        }

        .detail-box .detail-box__content {
            margin: 0;
        }
    }
}

.flex-inside .detail-box .detail-box__content {
    min-height: calc(100% - 36px);
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(space-between);
}

edit-action-dialog hr {
    border: none;
    border-bottom: 1px solid $black85;
}