#attendance-registration {
    .content__status-bar {
        .kiosk-url-box {
            padding: 0px 7px;
            border-radius: 0 4px 4px 0;
            line-height: 34px;
            font-family: "Courier New", Courier, monospace;
            font-size: 12px;
            float: left;
            background-color: #ffffff;
            font-weight: normal;
            color: $default-text-color;
            &:not(.enabled) {
                outline: none;
                cursor: default;
                text-decoration: none;
            }
            &.enabled {
                &:hover {
                    background-color: #e6e6e6;
                }
            }
        }
        .button-white {
            height: 34px;
            border: none;
        }
    }
    .content__body {
        top: 114px;
        .left-side {
            width: calc(100% - 48px);
            height: 100%;
            .table-with-arrows {
                position: relative;
                ul {
                    margin-top: 0;
                    position: absolute;
                    top: 0;
                    right: -51px;
                    width: auto;
                    height: 100%;
                    @include flexbox;
                    @include align-items(center);
                }
            }
            .table-footer {
                height: auto;
                min-height: 52px;
                overflow: auto;
                .table-footer__total {
                    margin-right: 0;
                }
                ul.button-list {
                    text-align: center;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                }
            }
        }
        .content__body__full-height {
            &.no-rollcall-areas {
                font-size: 0;
                .attendance--centered-content {
                    @include flexbox;
                    @include align-items(center);
                    @include justify-content(center);
                    height: 100%;
                    .no-areas {
                        color: #b2b2b2;
                        font-size: 20px;
                        text-align: center;
                        width: 75%;
                        .icon-info {
                            font-size: 24px;
                        }
                    }
                }
            }
            .details-list__container {
                height: calc(100% - 16px);
                .details-list__header {
                    height: auto;
                    .fields .field .grid__item {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}
