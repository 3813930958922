.just-in-disabled-dialog {
    width: 600px;
    padding: 0 16px;

    p, ul, .fields {
        text-align: left;
    }

    .details-list li {
        font-size: inherit;
    }

    label {
        color: $white;
        max-width: 480px;
    }
}