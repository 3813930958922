/**
 * Shuffle `$list` using Fisher-Yates method.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-shuffle
 *
 * @param {List} $list - list to shuffle
 *
 * @requires sl-to-list
 * 
 * @example
 * sl-shuffle(a b c)
 * // b a c
 * 
 * @return {List}
 */

@function sl-shuffle($list) {
  @if sl-missing-dependencies(sl-to-list) == true { @return null; }

  $length: length($list);

  @if $length < 2 {
    @return $list;
  }

  @for $i from $length through 1 {
    $j: random($length - 1) + 1;
    $tmp: nth($list, $i);
    $list: set-nth($list, $i, nth($list, $j));
    $list: set-nth($list, $j, $tmp);
  }
  
  @return sl-to-list($list);
}

/**
 * @requires sl-shuffle
 * @alias sl-shuffle
 */
 
@function sl-randomize($list) {
  @return sl-shuffle($list);
}
