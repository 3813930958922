/**
 * Replaces value at `$index` from `$list` by `$value`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-replace-nth
 *
 * @requires sl-purge
 * @requires sl-is-true
 * @requires sl-to-list
 *
 * @param {List}   $list  - list to update
 * @param {Number} $index - index to update
 * @param {*}      $value - new value for index
 *
 * @throws Invalid index $index for `sl-replace-nth`.
 *
 * @example
 * sl-replace-nth(a b c, 2, z)
 * // a z c
 *
 * @example
 * sl-replace-nth(a b c, 100, z)
 * // null
 *
 * @return {List | Null}
 */
 
@function sl-replace-nth($list, $index, $value) {
  @if sl-missing-dependencies(sl-purge, sl-is-true, sl-to-list) == true { @return null; }

  @if type-of($index) != "number" or $index == 0 or abs($index) > length($list) {
    @warn "Invalid index (#{inspect($index)}) for `sl-replace-nth`.";
    @return null;
  }

  $list: set-nth($list, $index, $value);
  $list: if(sl-is-true($value), $list, sl-purge($list));
  
  @return sl-to-list($list);
}
