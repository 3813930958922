.table-grid {
    display: table;
    margin: 0 auto;
    .row
    {
        display: table-row;
        .cell
        {
            display: table-cell;
            font-weight: 200;
            &:first-child {
                text-align: right;
                &.cell__align-left {
                    text-align: left;
                }
            }
            label
            {
                overflow: visible;
                margin-right:10px;
                text-align: right;                 
                margin-bottom: 3px;
                display: inline-block;
            }
        }
    }
}