$technologies-width: 250px;
$sam-and-issuing-left-padding: 4px;
$selected-memory-border: #008469;

sam-and-issuing {
    form {
        height: 100%;
    }

    .field--xs {
        width: $field-xs-size !important;
    }

    .field--s {
        width: $field-s-size !important;
    }
    
    .content__body {
        // The standard footer height is 44px, but if it changes (for instance,
        // because it splits over two lines), we'll need to change our approach.
        height: calc(100% - #{$content-header-height} - 44px);
        padding: 0 1px 2px $sam-and-issuing-left-padding !important;
        overflow: hidden !important;
    }

    .field__label--radiocheck {
        white-space: normal;
    }

    .field--inline {
        width: 100%;
    }
    .fields.no-read-key {
        label {
            text-align: right;
            min-width: 80px;
            padding-right: 8px;
        }
        .warning-label-border {
            min-width: 130px;
            text-align: left;
        }
    }
}

.technologies, .key-properties {
    height: 100%;
    overflow: auto;
    display: inline-block;
    vertical-align: top;
}

.technologies {
    background-color: $black90;
    border: 4px solid $black90;
    border-width: 2px 3px 4px 2px;
    width: $technologies-width;

    &.has-scrollbar {
        padding-right: 4px;
        border-right-width: 4px;
    }

    .after-key-separator {
        height: 20px;
        visibility: hidden;
    }

    .technologies-inner {
        background-color: $black95;
        padding: 14px;
        box-shadow: inset 0 0 5px 0 $black85;
        min-height: 100%;
        overflow: visible;
    }

    h3 {
        font-size: 105%;
        font-weight: 600;
    }

    %sam-and-issuing--side-box {
        border: 1px solid $black80;
        border-radius: 3px;
        background-color: $white;
        padding: 8px 12px 10px 12px;
    }

    .sam-and-issuing--description {
        @extend %sam-and-issuing--side-box;
        margin-bottom: 16px;

        h3 {
            margin: 0 0 4px 0;
        }
    }

    .active-technologies, .inactive-technologies {
        @extend %sam-and-issuing--side-box;
        box-shadow: 0 3px 4px 0 $black85;

        h3 {
            text-transform: uppercase;
            margin: 0 0 10px 0;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding: 10px 8px;
                margin-bottom: 2px;
                background-color: $black90;
                text-decoration: none;
                list-style-type: none;
                font-size: 14px;
                cursor: default;

                &.selectable {
                    cursor: pointer;
                }

                &.selected {
                    background-color: $active;
                    color: $white;

                    button {
                        color: $white;

                        &:focus {
                            color: lighten($active, 15%);
                        }
                    }
                }

                &:not(.selected):hover {
                    background-color: $keycolor;
                }

                input {
                    margin-right: 5px;
                }

                button {
                    float: right;
                    margin: 0;
                    padding: 0;
                    background: none;
                    color: $black40;
                    border: none;

                    &:focus {
                        outline: none;
                        color: $black60;
                    }
                }

                .icon-edit {
                    float: right;
                    font-size: 16px;
                    margin-right: 1px;
                    cursor: pointer;
                }

                .icon-error {
                    position: absolute;
                    top: 10px;
                    right: 34px;
                    color: $color-error;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }

    .active-technologies {
        margin-bottom: 16px;
    }
}

.sam-keys-container{
    margin: 0 32px !important;
    text-align: center;
    .sam-key{
        background-color: $black90;
        margin: 1px !important;
        padding: 8px !important;
    }
}

.key-properties {
    width: calc(100% - #{$technologies-width} - #{$sam-and-issuing-left-padding});
    padding: 20px 20px 0 20px;

    h1 {
        font-weight: $bold-weight;
        margin: 0;
    }

    h2 {
        font-weight: 200;
        margin: 16px 0 10px 0;
    }

    hr {
        border: none;
        border-bottom: 1px solid $black85;
        margin-bottom: 20px;
    }

    .no-side-margin .detail-box .detail-box__content {
        margin: 15px 0 0 0;
    }

    .extra-padding-top {
        padding-top: 8px;
    }

    .key-and-confirm {
        display: inline-block;
        vertical-align: top;
        margin-top: 5px;

        &.side-by-side {
            width: 49.5%;
            max-width: 400px;
            padding: 0 5px 0 5px;

            .grid {
                margin: 0;
                padding: 0;
            }

            /*label {
                width: calc(100% - #{$field-l-size} - 20px);
            }*/

            /*input {
                width: $field-l-size !important;
            }

            input[type=password]:not(.no-button) {
                width: calc(#{$field-l-size} - 43px) !important;
            }*/
        }

        label {
            font-size: 16px;
        }
    }

    .space-below {
        .key-inputs {
            margin-bottom: 16px;
        }
    }

    .separate-labels-inputs {
        @include flex-row-container;
        min-width: 0;

        .key-inputs {
            min-width: 220px;
        }
    }

    .separate-labels {
        @include flex(0 0 auto);
        min-width: 0;
        padding-left: 10px;

        &::first-child {
            padding-left: 0;
        }

        label {
            padding-top: 8px;
            text-align: right;
        }

        .field {
            display: block;
            margin-bottom: 24px;
            padding-left: 5px;
        }

        &::first-child field {
            padding-left: 0;
        }
    }

    .separate-inputs {
        &.same-flex-basis {
            // flex-basis: 1px prevents changes in the select2 value from changing
            // the space given to each column
            flex-basis: 1px;
        }
        @include flex(1 1 auto);
        .field {
            padding-left: 5px;
            display: block;
            width: auto;
        }

        .key-inputs {
            margin-left: 5px !important;
            
            .confirmation-input {
                margin-top: 16px !important;
            }
        }
    }

    .sam-data .key-and-confirm {
        margin-bottom: 10px;
    }

    .sam-data key-and-confirm-key {
        &:first-of-type .fields {
            margin-bottom: 25px;
        }
    }

    .absolute-tab:not(.visibility-hidden) {
        position: static;
    }

    .space-right {
        margin-right: 9px;
    }

    mifare {
        key-and-confirm-key {
            label {
                font-size: 16px;
                text-align: right;

                &.no-break {
                    max-width: calc(100% - 20px);
                    white-space: normal;
                }
            }

            .fields {
                text-align: left;
                margin-bottom: 12px;

                .field {
                    height: 34px;
                    max-width: none;
                }

                .field:nth-child(2) {
                    margin-bottom: 23px;
                }
            }

            .grid__item {
                width: 67%;
            }

            button {
                margin: 0;
                position: relative;
                z-index: 1;
                /*top: 1px;*/
            }

            input {
                margin-right: 4px;
            }

            .key-defined-input {
                width: calc(100% - 43px) !important;
            }
        }

        .inside-tab {
            padding: 6px;

            field.top {
                vertical-align: top;
            }

            .memory-footer {
                padding: 12px 12px 8px 12px;
                background-color: $black97;
                border-top: 1px solid $black90;
                text-transform: uppercase;

                p {
                    font-size: 15px;
                    margin: 0;
                    font-weight: 400;
                }

                h2 {
                    font-size: 30px;
                    margin: 0;
                }
            }

            .fake-padding {
                padding: 15px;
            }

            .big-memory-cells, .memory-cells {
                table {
                    border-collapse: separate;
                    border-spacing: 0;
                    margin-bottom: 6px;

                    &:focus {
                        outline: none;
                    }
                }

                tr td:first-child .cell.selected,
                tr td:first-child:hover .cell:not(.unselectable),
                tr td:last-child .cell.selected,
                tr td:last-child:hover .cell:not(.unselectable) {
                    border-radius: 0;
                }

                tr td:last-child .cell {
                    border-right: 1px solid $black90;
                }

                td {
                    padding: 0;

                    &:hover {
                        .cell:not(.selected):not(.unselectable) {
                            background-color: $keycolor;
                            border-color: $side-menu-option-dark;
                            border-right: 1px solid $side-menu-option-dark !important;
                            color: $white;
                        }

                        .cell:not(.selected):not(.unselectable) + .border {
                            background-color: $side-menu-option-dark;
                        }
                    }
                }

                .cell {
                    border: 1px solid $black90;
                    border-right: none;
                    font-weight: 600;
                    color: $black80;
                    text-align: center;
                    cursor: pointer;

                    &.focused {
                        background-color: mix($white, $keycolor, 50%);
                        color: $white !important;
                    }

                    &.selected {
                        background-color: $active !important;
                        border-color: $selected-memory-border !important;
                        border-right: 1px solid $selected-memory-border !important;
                        color: $white !important;

                        &.focused {
                            background-color: mix($active, $keycolor, 50%) !important;
                        }
                    }

                    &.selected + .border {
                        background-color: $selected-memory-border;
                    }

                    &.unselectable {
                        background-color: $black90;
                        color: $black80;
                        cursor: default;
                        // These two last lines shouldn't be necessary, but they
                        // prevent a rendering issue in IE in which a white line
                        // appears on the right side
                        border-right: 1px $black90 solid;
                        padding-left: 1px;
                    }
                }
            }

            .memory-cells {
                &.no-border-radius .cell {
                    border-radius: 0 !important;
                }

                td:first-child .cell {
                    border-radius: 4px 0 0 4px;
                }
                
                td:last-child .cell {
                    border-radius: 0 4px 4px 0;
                }

                table:first-child {
                    margin-top: 12px;
                }

                td {
                    width: 25px;
                    height: 43px;
                }

                .border {
                    height: 3px;
                    position: relative;
                    top: -2px;
                }

                .cell {
                    padding-top: 10px;
                    font-size: 11px;
                    height: 34px;
                }
            }

            .big-memory-cells {
                padding-left: 16px;
                
                table {
                    margin: 6px 0 0 0;
                }

                td {
                    width: 81px;
                    height: 81px;
                    position: relative;
                }

                tr:nth-child(1) td:not(:hover) .cell:not(.selected)
                {
                    border-bottom: none;
                }

                .border {
                    position: absolute;
                    height: 5px;
                    bottom: 0;
                    width: 81px;
                    visibility: hidden;
                }

                .cell.selected + .border, td:hover > .border {
                    visibility: inherit;
                }

                .cell {
                    font-size: 24px;
                    padding-top: 24px;
                    height: 81px;
                    border-radius: 0 !important;
                }
            }
        }
    }
}

.small-label-with-padding {
    height: 34px;
    padding-top: 7px;
}

.key-length{
    width: 49.5%;
}

.separate-labels-inputs {
    @include flex (0 1 auto);
}

.desfire-custom {
    @include flexbox;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include justify-content(space-around);
    @include align-content(flex-start);
    @include align-items(flex-start);
}

.desfire-issuing {
    @include flex-row-container;
}

.desfire-custom, .desfire-issuing, .desfire-aid {
    label {
        text-align: right;
    }
}

desfire .ctrl, hid-iclass .ctrl {
    margin-left: 5px;

    &.extra-margin-left {
        margin-left: 8px;
    }
}

.desfire-issuing key-and-confirm-key .fields {
    margin: 0 0 23px 0;
}

.desfire-issuing, hid-iclass {
    .fixed-labels label:not(.field__label--radiocheck) {
        width: 135px !important;
        text-align: right;
    }

    .fields-fake-table {
        display: table;

        .field {
            display: table-row;
        }

        label {
            display: table-cell !important;
            padding-bottom: 16px;
        }

        .ctrl {
            padding-bottom: 16px;
        }
    }
}

.legic-sam-wrapper {
    display: table-row;

    .stamp, .initial-segment {
        display: table-cell;

        .fields {
            display: table;

            .field {
                display: table-row;
            }
            
            .ctrl {
                padding: 8px 0;
            }

            .spinner-wrapper {
                padding: 0 0 0 15px;
            }

            label {
                padding: 0 14px;
            }

            .small-label-with-padding {
                white-space: nowrap;

                .ctrl {
                    margin-top: 10px;
                    padding-left: 35px;
                    width: 50px;
                }

                & label {
                    margin-top: 10px;
                    padding: 0 0 0 3px;
                    white-space: nowrap;
                }
            }
        }
    }

    .stamp .ctrl {
        width: calc(100% - 39px);

        input[type=text] {
            width: 100%;
            min-width: 215px;
        }
    }

    .initial-segment > label {
        padding-left: 15px;
    }
}

.hid-iclass-sam {
    & > * {
        display: inline-block;
        vertical-align: top;
    }

    .field {
        display: block !important;

        &.hide {
            height: 0;
            margin: 0;
            visibility: hidden;
            overflow: hidden;
        }
    }

    label {
        text-align: right;
    }


    .labels label {
        padding-top: 16px;
    }

    .labels .field:first-child label {
        padding-top: 8px;
    }

    .key-and-confirm-key-container {
        width: 190px;
    }

    .key-inputs {
        margin-left: 0 !important;

        input:nth-child(3) {
            margin-top: 16px !important;
        }
    }
}

.mifare-detail-box-not-init {
    .detail-box {
        height: 157px !important;
        overflow: hidden;
    }

    .detail-box__content {
        height: 104px !important;
        overflow: hidden;
    }
}

.memory-wrapper {
    .detail-box {
        height: auto !important;
    }
}