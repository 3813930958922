#home5Elements{
    height: 485px;
    display: inline-block;
   
    .primaryButton{
        height: 320px;
        width: 320px;
    }
    .secondaryButton{
        height: 160px;
        width: 160px;
    }
     .threeButtons{
        display: inline-block;
        button{
            display: block;
        }
        
     }   
     
     .button1{  
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8; 
    }
    .button2{
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8; 
        border-right: solid 1px #e8e8e8;
    } 
    .button3{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
        border-bottom: solid 1px #e8e8e8; 
    }    
    .button4{
        border-left: solid 1px white; 
        border-top: solid 1px white;
        border-right: solid 1px #e8e8e8;
    }
    .button5{
        border-left: solid 1px white; 
        border-top: solid 1px white;
    }    
    
    //las clases border y sus divs corresponientes se usan únicamente
    //para pintar los bordes.
    .border1{
        height:80px;
        border-right: solid 1px #e8e8e8;
    }
    .border2{
        height:80px;
        border-right: solid 1px #e8e8e8;
        border-top: solid 1px white;
    }
    .border3{
        height:161px;
        border-left: solid 1px white; 
        border-bottom: solid 1px #e8e8e8; 
    }
}