#settings {
    @mixin operator-icon($icon){
        &:before{
            content: $icon;
            font-family: icomoon;
            font-size: 70px;
            background-color: $black95;
            border: solid 4px white;
            outline: solid 1px $black80;              
        }
        margin-top: 8px;
    }


    .admin-operator-icon{
        @include operator-icon("\e6a9");
    }

    .normal-operator-icon{
        @include operator-icon("\2e");
    }

    .operator-data-width{
        width: calc(100% - 94px);
    }

    .list-value-wrapper{
        overflow: hidden;
        width: 65%;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;// Required for IE8
        -o-text-overflow: ellipsis;// Required for Opera
    }

    .example-text{
        margin-top: 8px;
        margin-left: 8px;
        display: block;
        font-size: 13px;
        color: $black60;
        .title{
            font-weight: 800;  
        }
    }

    .language-combo{
        width: 100%;
        margin-left: 1px;
        padding: 0px;
        .select2-container{
            width: 150px !important;
        }
        .ctrl{
            padding:0px !important;
        }
    }

    .button-settings{
        margin-bottom: 5px;
    }

    .enable-beeper{
        margin-top: 5px;
        width: 100%;
    }

    // .encoder-data-container {
    //   display: -ms-flexbox; /* IE 10 */
    //   display: flex;
    //   -ms-flex-direction: row; /* IE 10 */
    //   flex-direction: row;
    //   .encoder-combo{
    //       width: auto; //270px;
    //     //   display: table-row;
    //       margin: 0px 0px 8px 8px;
    //   }
    //   .encoder-buttons{
    //     margin-left: 20px;
    //   }
    // }

    .encoder-combo{
        width: auto; //270px;
        //   display: table-row;
        margin: 0px 0px 8px 0px;
        overflow: visible !important; //Default value
    }
    .encoder-buttons{
        margin-left: 20px;
    }

    .list-label{
        margin-left: -6px;
    }
    .change-password-container{
        margin-left: 2px;
        .change-password-button{
            color: $color-info;
            font-weight: 400 !important;
            background: none !important;
            border: none !important;
            font: inherit;
            padding: 0px;
            outline: none;
            margin-left: -3px;
            &:focus, &:hover{
                color: $active;
            }
        }
    }
    

    .settings--datetime field label {
        vertical-align: top;
    }
    input[type=radio] {
        vertical-align: middle;
    }
}

.show-firmware-device-data-box{
    margin: -16px -16px 16px -16px;
    padding: 16px;
    background-color: $black90;
}

.firmware-table{
    width:400px;
}