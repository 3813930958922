// Extra Map Functions in Sass: http://www.sitepoint.com/extra-map-functions-sass/

/// Compute the maximum depth of a map
/// @param {Map} $map
/// @return {Number} max depth of `$map`
@function map-depth($map) {
	$level: 1;

	@each $key, $value in $map {
		@if type-of($value) == "map" {
			$level: max(map-depth($value) + 1, $level);
		}
	}

	@return $level;
}

// // Example:
// $grid-configuration: (
//   'columns': 12,
//   'layouts': (
//     'small': 800px,
//     'medium': 1000px,
//     'large': 1200px,
//   ),
// );
 
// // Maximum depth
// $depth: map-depth($grid-configuration);
// // -> 2