/**
 * Removes value(s) `$value` from `$list`.
 *
 * @ignore Documentation: http://sassylists.com/documentation.html#sl-remove
 *
 * @requires sl-replace
 *
 * @param {List}    $list      - list to update
 * @param {*}       $value     - value to remove
 *
 * @example
 * sl-remove(a b c, a)
 * // b c
 *
 * @return {List}
 */

@function sl-remove($list, $value) {
  @if sl-missing-dependencies(sl-replace) == true { @return null; }

  @return sl-replace($list, $value, null);
}

/**
 * @requires sl-remove
 * @alias sl-remove
 */

@function sl-without($list, $value) {
  @return sl-remove($list, $value);
}